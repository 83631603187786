export function Tree80(props: any) {
  return (
    <svg
      width={672}
      height={71}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.5 15.092s-7 7.925-7 14 3 8 7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.5 37.092c4 0 7-1.925 7-8s-7-14-7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.95 31.314s.654 10.553 4.95 14.85c4.295 4.295 7.778 3.535 10.606.706L80.95 31.314Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.506 46.87c2.829-2.829 3.589-6.311-.707-10.607-4.296-4.295-14.85-4.95-14.85-4.95L96.507 46.87Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.45 32.041s-10.554.655-14.849 4.95c-4.296 4.296-3.536 7.779-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.893 47.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.295 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m101.5 30.092.927 18.54 8.487-6.126-8.288 10.106.874 17.48h-4l.879-17.569-8.216-10.017 8.413 6.072.924-18.486Z"
        fill="url(#a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M611.5 15.092s-7 7.925-7 14 3 8 7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M611.5 37.092c4 0 7-1.925 7-8s-7-14-7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M590.95 31.314s.654 10.553 4.949 14.85c4.296 4.295 7.779 3.535 10.607.706L590.95 31.314Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M606.506 46.87c2.828-2.829 3.589-6.311-.707-10.607-4.296-4.295-14.849-4.95-14.849-4.95l15.556 15.557Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M631.45 32.041s-10.554.655-14.849 4.95c-4.296 4.296-3.536 7.779-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.893 47.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.295 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m611.5 30.092.927 18.54 8.487-6.126-8.288 10.106.874 17.48h-4l.879-17.569-8.216-10.017 8.413 6.072.924-18.486Z"
        fill="url(#b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.5 11.092s-7 7.925-7 14 3 8 7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M173.5 33.092c4 0 7-1.925 7-8s-7-14-7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.95 30.314s.654 10.553 4.949 14.85c4.296 4.295 7.779 3.535 10.607.706L152.95 30.314Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.506 45.87c2.828-2.829 3.589-6.311-.707-10.607-4.296-4.295-14.849-4.95-14.849-4.95l15.556 15.557Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.95 48.041s.654 10.554 4.949 14.85c4.296 4.295 7.779 3.535 10.607.707L152.95 48.042Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.506 63.597c2.828-2.828 3.589-6.31-.707-10.606s-14.849-4.95-14.849-4.95l15.556 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.45 29.041s-10.554.654-14.849 4.95c-4.296 4.296-3.536 7.779-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.893 44.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.295 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.45 46.041s-10.554.655-14.849 4.95c-4.296 4.296-3.536 7.779-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.893 61.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.295 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m173.5 26.092 1.103 19.413 8.312-5.999-8.092 9.867.708 12.462 7.383-5.329-7.188 8.764.274 4.822h-5l.134-2.359-6.971-8.499 7.16 5.168.753-13.247-7.913-9.649 8.128 5.867 1.209-21.281Z"
        fill="url(#c)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.5 5.092s-7 7.925-7 14 3 8 7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M418.5 27.092c4 0 7-1.925 7-8s-7-14-7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M397.95 24.314s.654 10.553 4.949 14.85c4.296 4.295 7.779 3.535 10.607.706L397.95 24.314Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M413.506 39.87c2.828-2.829 3.589-6.311-.707-10.607-4.296-4.296-14.849-4.95-14.849-4.95l15.556 15.557Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M397.95 42.041s.654 10.554 4.949 14.85c4.296 4.296 7.779 3.535 10.607.707L397.95 42.042Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M413.506 57.597c2.828-2.828 3.589-6.31-.707-10.606s-14.849-4.95-14.849-4.95l15.556 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M438.45 23.041s-10.554.654-14.849 4.95c-4.296 4.296-3.536 7.779-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M422.893 38.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.295 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M438.45 40.041s-10.554.655-14.849 4.95c-4.296 4.296-3.536 7.779-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M422.893 55.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.295 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m418.5 20.092.975 19.505 8.44-6.091-8.242 10.05.623 12.448 7.618-5.498-7.44 9.071.526 10.515h-5l.402-8.032-7.239-8.826 7.412 5.35.662-13.233-8.074-9.845 8.268 5.968 1.069-21.382Z"
        fill="url(#d)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m354.654 41.907.053-.05.053-.05-.106.1Zm-4.589 8.477c.056-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M350.064 50.384s1.246-5.359 4.642-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m363.129 49.203-13.064 12.182s5.268-.842 8.562-3.914c3.294-3.071 4.502-8.268 4.502-8.268Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M350.065 61.384h-.001s1.208-5.197 4.502-8.268c3.294-3.072 8.562-3.914 8.562-3.914l-13.063 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.841 43.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.841 43.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M336.1 34.782c.014.058 1.268 5.393 4.657 8.554 3.389 3.16 8.799 4.039 8.858 4.049L336.1 34.782Zm8.914 4.1-.056-.052a2.351 2.351 0 0 0-.056-.051l.112.104Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.616 47.385s-1.249-5.376-4.657-8.554-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m345.399 49.606-.037-.035-.038-.035.075.07Zm4.217 7.78c-.04-.007-4.992-.809-8.092-3.7-3.1-2.89-4.245-7.774-4.254-7.814l12.346 11.513ZM337.27 45.871h-.001.001Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M349.616 57.385s-1.141-4.911-4.254-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m349.845 30.092-2 40h4l-2-40Z" fill="url(#e)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m248.654 41.907.053-.05.053-.05-.106.1Zm-4.589 8.477c.056-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.064 50.384s1.246-5.359 4.642-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m257.129 49.203-13.064 12.182s5.268-.842 8.562-3.914c3.294-3.071 4.502-8.268 4.502-8.268Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.065 61.384h-.001s1.208-5.197 4.502-8.268c3.294-3.072 8.562-3.914 8.562-3.914l-13.063 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.841 43.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.841 43.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.1 34.782c.014.058 1.268 5.393 4.657 8.554 3.389 3.16 8.799 4.039 8.858 4.049L230.1 34.782Zm8.914 4.1-.056-.052a2.351 2.351 0 0 0-.056-.051l.112.104Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.616 47.385s-1.249-5.376-4.657-8.554-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m239.399 49.606-.037-.035-.038-.035.075.07Zm4.217 7.78c-.04-.007-4.992-.809-8.092-3.7-3.1-2.89-4.245-7.774-4.254-7.814l12.346 11.513ZM231.27 45.871h-.001.001Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.616 57.385s-1.141-4.911-4.254-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m243.845 30.092-2 40h4l-2-40Z" fill="url(#f)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m385.654 41.907.053-.05.053-.05-.106.1Zm-4.589 8.477c.056-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M381.064 50.384s1.246-5.359 4.642-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m394.129 49.203-13.064 12.182s5.268-.842 8.562-3.914c3.294-3.071 4.502-8.268 4.502-8.268Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M381.065 61.384h-.001s1.208-5.197 4.502-8.268c3.294-3.072 8.562-3.914 8.562-3.914l-13.063 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.841 43.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.841 43.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M367.1 34.782c.014.058 1.268 5.393 4.657 8.554 3.389 3.16 8.799 4.039 8.858 4.049L367.1 34.782Zm8.914 4.1-.056-.052a2.351 2.351 0 0 0-.056-.051l.112.104Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.616 47.385s-1.249-5.376-4.657-8.554-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m376.399 49.606-.037-.035-.038-.035.075.07Zm4.217 7.78c-.04-.007-4.992-.809-8.092-3.7-3.1-2.89-4.245-7.774-4.254-7.814l12.346 11.513ZM368.27 45.871h-.001.001Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M380.616 57.385s-1.141-4.911-4.254-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m380.845 30.092-2 40h4l-2-40Z" fill="url(#g)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m468.654 41.907.053-.05.053-.05-.106.1Zm-4.589 8.477c.056-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M464.064 50.384s1.246-5.359 4.642-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m477.129 49.203-13.064 12.182s5.268-.842 8.562-3.914c3.294-3.071 4.502-8.268 4.502-8.268Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M464.065 61.384h-.001s1.208-5.197 4.502-8.268c3.294-3.072 8.562-3.914 8.562-3.914l-13.063 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M463.841 43.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M463.841 43.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M450.1 34.782c.014.058 1.268 5.393 4.657 8.554 3.389 3.16 8.799 4.039 8.858 4.049L450.1 34.782Zm8.914 4.1-.056-.052a2.351 2.351 0 0 0-.056-.051l.112.104Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M463.616 47.385s-1.249-5.376-4.657-8.554-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m459.399 49.606-.037-.035-.038-.035.075.07Zm4.217 7.78c-.04-.007-4.992-.809-8.092-3.7-3.1-2.89-4.245-7.774-4.254-7.814l12.346 11.513ZM451.27 45.871h-.001.001Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M463.616 57.385s-1.141-4.911-4.254-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m463.845 30.092-2 40h4l-2-40Z" fill="url(#h)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m515.654 41.907.053-.05.053-.05-.106.1Zm-4.589 8.477c.056-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M511.064 50.384s1.246-5.359 4.642-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m524.129 49.203-13.064 12.182s5.268-.842 8.562-3.914c3.294-3.071 4.502-8.268 4.502-8.268Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M511.065 61.384h-.001s1.208-5.197 4.502-8.268c3.294-3.072 8.562-3.914 8.562-3.914l-13.063 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M510.841 43.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M510.841 43.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M497.1 34.782c.014.058 1.268 5.393 4.657 8.554 3.389 3.16 8.799 4.039 8.858 4.049L497.1 34.782Zm8.914 4.1-.056-.052a2.351 2.351 0 0 0-.056-.051l.112.104Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M510.616 47.385s-1.249-5.376-4.657-8.554-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m506.399 49.606-.037-.035-.038-.035.075.07Zm4.217 7.78c-.04-.007-4.992-.809-8.092-3.7-3.1-2.89-4.245-7.774-4.254-7.814l12.346 11.513ZM498.27 45.871h-.001.001Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M510.616 57.385s-1.141-4.911-4.254-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m510.845 30.092-2 40h4l-2-40Z" fill="url(#i)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.209 18.095a7 7 0 1 0-13.418 0 7 7 0 0 0-4.403 12.263 5.001 5.001 0 0 0-2.142 8.036 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 7.607 4.704 8.5 8.5 0 0 0 7.607-4.704 8.5 8.5 0 1 0 5.647-15.994 5.001 5.001 0 0 0-2.142-8.036 7 7 0 0 0-4.403-12.263Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M134 9.11a7 7 0 0 0-7.209 8.986 7 7 0 0 0-4.403 12.261 5.001 5.001 0 0 0-2.142 8.037 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 8.107 4.69V9.108Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m133.752 14.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.941 8.671-6.258-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L123 41.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#j)"
      />
      <g filter="url(#k)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.65 30.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={126} cy={27.091} r={2.4} fill="#E6E2CF" />
      <circle cx={126} cy={27.092} r={0.8} fill="#A6966D" />
      <g filter="url(#l)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.65 36.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={140} cy={33.091} r={2.4} fill="#E6E2CF" />
      <circle cx={140} cy={33.092} r={0.8} fill="#A6966D" />
      <g filter="url(#m)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.65 20.35a1.598 1.598 0 0 1-1.909-1.909 1.599 1.599 0 0 1-.001-2.7 1.6 1.6 0 0 1 1.91-1.909 1.6 1.6 0 0 1 2.7 0 1.6 1.6 0 0 1 1.909 1.91 1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.909 1.598 1.598 0 0 1-2.7 0Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={135} cy={17.091} r={2.4} fill="#E6E2CF" />
      <circle cx={135} cy={17.092} r={0.8} fill="#A6966D" />
      <g filter="url(#n)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121.65 48.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={123} cy={45.091} r={2.4} fill="#E6E2CF" />
      <circle cx={123} cy={45.092} r={0.8} fill="#A6966D" />
      <g filter="url(#o)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.65 49.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={145} cy={46.091} r={2.4} fill="#E6E2CF" />
      <circle cx={145} cy={46.092} r={0.8} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M557.209 18.095a7 7 0 1 0-13.418 0 7 7 0 0 0-4.403 12.263 5.001 5.001 0 0 0-2.142 8.036 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 7.607 4.704 8.5 8.5 0 0 0 7.607-4.704 8.5 8.5 0 1 0 5.647-15.994 5.001 5.001 0 0 0-2.142-8.036 7 7 0 0 0-4.403-12.263Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M551 9.11a7 7 0 0 0-7.209 8.986 7 7 0 0 0-4.403 12.261 5.001 5.001 0 0 0-2.142 8.037 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 8.107 4.69V9.108Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m550.752 14.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.941 8.671-6.258-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L540 41.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#p)"
      />
      <g filter="url(#q)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M541.65 30.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={543} cy={27.091} r={2.4} fill="#E6E2CF" />
      <circle cx={543} cy={27.092} r={0.8} fill="#A6966D" />
      <g filter="url(#r)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M555.65 36.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={557} cy={33.091} r={2.4} fill="#E6E2CF" />
      <circle cx={557} cy={33.092} r={0.8} fill="#A6966D" />
      <g filter="url(#s)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M550.65 20.35a1.598 1.598 0 0 1-1.909-1.909 1.599 1.599 0 0 1-.001-2.7 1.6 1.6 0 0 1 1.91-1.909 1.6 1.6 0 0 1 2.7 0 1.6 1.6 0 0 1 1.909 1.91 1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.909 1.598 1.598 0 0 1-2.7 0Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={552} cy={17.091} r={2.4} fill="#E6E2CF" />
      <circle cx={552} cy={17.092} r={0.8} fill="#A6966D" />
      <g filter="url(#t)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M538.65 48.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={540} cy={45.091} r={2.4} fill="#E6E2CF" />
      <circle cx={540} cy={45.092} r={0.8} fill="#A6966D" />
      <g filter="url(#u)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M560.65 49.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={562} cy={46.091} r={2.4} fill="#E6E2CF" />
      <circle cx={562} cy={46.092} r={0.8} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M329.209 18.095a7 7 0 1 0-13.418 0 7 7 0 0 0-4.403 12.263 5.001 5.001 0 0 0-2.142 8.036 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 7.607 4.704 8.5 8.5 0 0 0 7.607-4.704 8.5 8.5 0 1 0 5.647-15.994 5.001 5.001 0 0 0-2.142-8.036 7 7 0 0 0-4.403-12.263Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M323 9.11a7 7 0 0 0-7.209 8.986 7 7 0 0 0-4.403 12.261 5.001 5.001 0 0 0-2.142 8.037 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 8.107 4.69V9.108Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m322.752 14.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.941 8.671-6.258-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L312 41.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#v)"
      />
      <g filter="url(#w)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M313.65 30.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={315} cy={27.091} r={2.4} fill="#E6E2CF" />
      <circle cx={315} cy={27.092} r={0.8} fill="#A6966D" />
      <g filter="url(#x)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M327.65 36.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={329} cy={33.091} r={2.4} fill="#E6E2CF" />
      <circle cx={329} cy={33.092} r={0.8} fill="#A6966D" />
      <g filter="url(#y)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M322.65 20.35a1.598 1.598 0 0 1-1.909-1.909 1.599 1.599 0 0 1-.001-2.7 1.6 1.6 0 0 1 1.91-1.909 1.6 1.6 0 0 1 2.7 0 1.6 1.6 0 0 1 1.909 1.91 1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.909 1.598 1.598 0 0 1-2.7 0Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={324} cy={17.091} r={2.4} fill="#E6E2CF" />
      <circle cx={324} cy={17.092} r={0.8} fill="#A6966D" />
      <g filter="url(#z)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M310.65 48.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={312} cy={45.091} r={2.4} fill="#E6E2CF" />
      <circle cx={312} cy={45.092} r={0.8} fill="#A6966D" />
      <g filter="url(#A)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M332.65 49.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={334} cy={46.091} r={2.4} fill="#E6E2CF" />
      <circle cx={334} cy={46.092} r={0.8} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m489.752 14.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.941 8.671-6.258-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L479 41.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#B)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M497.499 18.253a1.354 1.354 0 0 1-1.036-1.433 7 7 0 1 0-13.926 0 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 475 35.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88 8.494 8.494 0 0 0 7.137-3.88c.289-.446.851-.65 1.36-.494a8.5 8.5 0 0 0 6.252-15.756c-.663-.326-.934-1.235-.645-1.916.255-.6.396-1.26.396-1.953a4.99 4.99 0 0 0-1.878-3.906c-.601-.48-.799-1.484-.361-2.117a7.002 7.002 0 0 0-4.262-10.816Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M490 9.61a.508.508 0 0 0-.5-.518 7 7 0 0 0-6.963 7.728 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 475 35.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88c.281 0 .5-.233.5-.514V9.61Z"
        fill="#314C42"
      />
      <g filter="url(#C)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M487.001 17.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={487} cy={21.092} r={2} fill="#E6E2CF" />
      <circle cx={487} cy={21.092} r={1} fill="#A6966D" />
      <g filter="url(#D)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M499.001 25.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={499} cy={29.092} r={2} fill="#E6E2CF" />
      <circle cx={499} cy={29.092} r={1} fill="#A6966D" />
      <g filter="url(#E)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M480.001 30.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={480} cy={34.092} r={2} fill="#E6E2CF" />
      <circle cx={480} cy={34.092} r={1} fill="#A6966D" />
      <g filter="url(#F)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M492.001 38.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={492} cy={42.092} r={2} fill="#E6E2CF" />
      <circle cx={492} cy={42.092} r={1} fill="#A6966D" />
      <g filter="url(#G)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M502.001 46.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={502} cy={50.092} r={2} fill="#E6E2CF" />
      <circle cx={502} cy={50.092} r={1} fill="#A6966D" />
      <g filter="url(#H)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M480.001 45.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={480} cy={49.092} r={2} fill="#E6E2CF" />
      <circle cx={480} cy={49.092} r={1} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m399.752 14.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.941 8.671-6.258-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L389 41.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#I)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M407.499 18.253a1.354 1.354 0 0 1-1.036-1.433 7 7 0 1 0-13.926 0 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 385 35.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88 8.494 8.494 0 0 0 7.137-3.88c.289-.446.851-.65 1.36-.494a8.5 8.5 0 0 0 6.252-15.756c-.663-.326-.934-1.235-.645-1.916.255-.6.396-1.26.396-1.953a4.99 4.99 0 0 0-1.878-3.906c-.601-.48-.799-1.484-.361-2.117a7.002 7.002 0 0 0-4.262-10.816Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M400 9.61a.508.508 0 0 0-.5-.518 7 7 0 0 0-6.963 7.728 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 385 35.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88c.281 0 .5-.233.5-.514V9.61Z"
        fill="#314C42"
      />
      <g filter="url(#J)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M397.001 17.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={397} cy={21.092} r={2} fill="#E6E2CF" />
      <circle cx={397} cy={21.092} r={1} fill="#A6966D" />
      <g filter="url(#K)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M409.001 25.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={409} cy={29.092} r={2} fill="#E6E2CF" />
      <circle cx={409} cy={29.092} r={1} fill="#A6966D" />
      <g filter="url(#L)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M390.001 30.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={390} cy={34.092} r={2} fill="#E6E2CF" />
      <circle cx={390} cy={34.092} r={1} fill="#A6966D" />
      <g filter="url(#M)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M402.001 38.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={402} cy={42.092} r={2} fill="#E6E2CF" />
      <circle cx={402} cy={42.092} r={1} fill="#A6966D" />
      <g filter="url(#N)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M412.001 46.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={412} cy={50.092} r={2} fill="#E6E2CF" />
      <circle cx={412} cy={50.092} r={1} fill="#A6966D" />
      <g filter="url(#O)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M390.001 45.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={390} cy={49.092} r={2} fill="#E6E2CF" />
      <circle cx={390} cy={49.092} r={1} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m55.752 14.092 1.437 20.116 7.9-5.702-7.642 9.317.71 9.941 8.671-6.258-8.336 10.165-.063-.097 1.187 16.619 3.798 1.899h-15.5l3.98-1.99 1.195-16.733L45 41.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#P)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.5 18.253a1.355 1.355 0 0 1-1.038-1.433 7 7 0 1 0-13.925 0 1.355 1.355 0 0 1-1.036 1.433 7.003 7.003 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 41 35.092c0 .693.141 1.353.396 1.953.29.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.253 15.756 1.208 1.208 0 0 1 1.36.494 8.493 8.493 0 0 0 7.136 3.88 8.493 8.493 0 0 0 7.137-3.88c.289-.446.851-.65 1.36-.494a8.5 8.5 0 0 0 6.252-15.756c-.663-.326-.934-1.235-.645-1.916.255-.6.396-1.26.396-1.953a4.99 4.99 0 0 0-1.878-3.906c-.6-.48-.799-1.484-.36-2.117A7.003 7.003 0 0 0 63.5 18.253Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56 9.61a.508.508 0 0 0-.5-.518 7 7 0 0 0-6.962 7.728 1.355 1.355 0 0 1-1.037 1.433 7.003 7.003 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 41 35.092c0 .693.141 1.353.396 1.953.29.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.253 15.756 1.208 1.208 0 0 1 1.36.494 8.493 8.493 0 0 0 7.136 3.88c.28 0 .5-.233.5-.514V9.61Z"
        fill="#314C42"
      />
      <g filter="url(#Q)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.001 17.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={53} cy={21.092} r={2} fill="#E6E2CF" />
      <circle cx={53} cy={21.092} r={1} fill="#A6966D" />
      <g filter="url(#R)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.001 25.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={65} cy={29.092} r={2} fill="#E6E2CF" />
      <circle cx={65} cy={29.092} r={1} fill="#A6966D" />
      <g filter="url(#S)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.001 30.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={46} cy={34.092} r={2} fill="#E6E2CF" />
      <circle cx={46} cy={34.092} r={1} fill="#A6966D" />
      <g filter="url(#T)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.001 38.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={58} cy={42.092} r={2} fill="#E6E2CF" />
      <circle cx={58} cy={42.092} r={1} fill="#A6966D" />
      <g filter="url(#U)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.001 46.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={68} cy={50.092} r={2} fill="#E6E2CF" />
      <circle cx={68} cy={50.092} r={1} fill="#A6966D" />
      <g filter="url(#V)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.001 45.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={46} cy={49.092} r={2} fill="#E6E2CF" />
      <circle cx={46} cy={49.092} r={1} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m55.752 14.092 1.437 20.116 7.9-5.702-7.642 9.317.71 9.941 8.671-6.258-8.336 10.165-.063-.097 1.187 16.619 3.798 1.899h-15.5l3.98-1.99 1.195-16.733L45 41.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#W)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.5 18.253a1.355 1.355 0 0 1-1.038-1.433 7 7 0 1 0-13.925 0 1.355 1.355 0 0 1-1.036 1.433 7.003 7.003 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 41 35.092c0 .693.141 1.353.396 1.953.29.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.253 15.756 1.208 1.208 0 0 1 1.36.494 8.493 8.493 0 0 0 7.136 3.88 8.493 8.493 0 0 0 7.137-3.88c.289-.446.851-.65 1.36-.494a8.5 8.5 0 0 0 6.252-15.756c-.663-.326-.934-1.235-.645-1.916.255-.6.396-1.26.396-1.953a4.99 4.99 0 0 0-1.878-3.906c-.6-.48-.799-1.484-.36-2.117A7.003 7.003 0 0 0 63.5 18.253Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56 9.61a.508.508 0 0 0-.5-.518 7 7 0 0 0-6.962 7.728 1.355 1.355 0 0 1-1.037 1.433 7.003 7.003 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 41 35.092c0 .693.141 1.353.396 1.953.29.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.253 15.756 1.208 1.208 0 0 1 1.36.494 8.493 8.493 0 0 0 7.136 3.88c.28 0 .5-.233.5-.514V9.61Z"
        fill="#314C42"
      />
      <g filter="url(#X)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.001 17.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={53} cy={21.092} r={2} fill="#E6E2CF" />
      <circle cx={53} cy={21.092} r={1} fill="#A6966D" />
      <g filter="url(#Y)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.001 25.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={65} cy={29.092} r={2} fill="#E6E2CF" />
      <circle cx={65} cy={29.092} r={1} fill="#A6966D" />
      <g filter="url(#Z)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.001 30.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={46} cy={34.092} r={2} fill="#E6E2CF" />
      <circle cx={46} cy={34.092} r={1} fill="#A6966D" />
      <g filter="url(#aa)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58.001 38.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={58} cy={42.092} r={2} fill="#E6E2CF" />
      <circle cx={58} cy={42.092} r={1} fill="#A6966D" />
      <g filter="url(#ab)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.001 46.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={68} cy={50.092} r={2} fill="#E6E2CF" />
      <circle cx={68} cy={50.092} r={1} fill="#A6966D" />
      <g filter="url(#ac)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.001 45.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 0 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 1 0 0 2c.216 0 .325.261.172.414a1 1 0 0 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 1 0 2 0c0-.216.261-.325.414-.172a1 1 0 0 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 1 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={46} cy={49.092} r={2} fill="#E6E2CF" />
      <circle cx={46} cy={49.092} r={1} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m207.752 14.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.941 8.671-6.258-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L197 41.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#ad)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M215.499 18.253a1.354 1.354 0 0 1-1.036-1.433 7 7 0 1 0-13.926 0 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 193 35.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88 8.494 8.494 0 0 0 7.137-3.88c.289-.446.851-.65 1.36-.494a8.5 8.5 0 0 0 6.252-15.756c-.663-.326-.934-1.235-.645-1.916.255-.6.396-1.26.396-1.953a4.99 4.99 0 0 0-1.878-3.906c-.601-.48-.799-1.484-.361-2.117a7.002 7.002 0 0 0-4.262-10.816Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M208 9.61a.508.508 0 0 0-.5-.518 7 7 0 0 0-6.963 7.728 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 193 35.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88c.281 0 .5-.233.5-.514V9.61Z"
        fill="#314C42"
      />
      <g filter="url(#ae)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M205.001 17.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={205} cy={21.092} r={2} fill="#E6E2CF" />
      <circle cx={205} cy={21.092} r={1} fill="#A6966D" />
      <g filter="url(#af)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M217.001 25.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={217} cy={29.092} r={2} fill="#E6E2CF" />
      <circle cx={217} cy={29.092} r={1} fill="#A6966D" />
      <g filter="url(#ag)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M198.001 30.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={198} cy={34.092} r={2} fill="#E6E2CF" />
      <circle cx={198} cy={34.092} r={1} fill="#A6966D" />
      <g filter="url(#ah)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M210.001 38.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={210} cy={42.092} r={2} fill="#E6E2CF" />
      <circle cx={210} cy={42.092} r={1} fill="#A6966D" />
      <g filter="url(#ai)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220.001 46.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={220} cy={50.092} r={2} fill="#E6E2CF" />
      <circle cx={220} cy={50.092} r={1} fill="#A6966D" />
      <g filter="url(#aj)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M198.001 45.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={198} cy={49.092} r={2} fill="#E6E2CF" />
      <circle cx={198} cy={49.092} r={1} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m588.752 14.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.941 8.671-6.258-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L578 41.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#ak)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M596.499 18.253a1.354 1.354 0 0 1-1.036-1.433 7 7 0 1 0-13.926 0 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 574 35.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88 8.494 8.494 0 0 0 7.137-3.88c.289-.446.851-.65 1.36-.494a8.5 8.5 0 0 0 6.252-15.756c-.663-.326-.934-1.235-.645-1.916.255-.6.396-1.26.396-1.953a4.99 4.99 0 0 0-1.878-3.906c-.601-.48-.799-1.484-.361-2.117a7.002 7.002 0 0 0-4.262-10.816Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M589 9.61a.508.508 0 0 0-.5-.518 7 7 0 0 0-6.963 7.728 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 574 35.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88c.281 0 .5-.233.5-.514V9.61Z"
        fill="#314C42"
      />
      <g filter="url(#al)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M586.001 17.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={586} cy={21.092} r={2} fill="#E6E2CF" />
      <circle cx={586} cy={21.092} r={1} fill="#A6966D" />
      <g filter="url(#am)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M598.001 25.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={598} cy={29.092} r={2} fill="#E6E2CF" />
      <circle cx={598} cy={29.092} r={1} fill="#A6966D" />
      <g filter="url(#an)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M579.001 30.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.415.242.242 0 0 1-.413-.17 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={579} cy={34.092} r={2} fill="#E6E2CF" />
      <circle cx={579} cy={34.092} r={1} fill="#A6966D" />
      <g filter="url(#ao)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M591.001 38.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={591} cy={42.092} r={2} fill="#E6E2CF" />
      <circle cx={591} cy={42.092} r={1} fill="#A6966D" />
      <g filter="url(#ap)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M601.001 46.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={601} cy={50.092} r={2} fill="#E6E2CF" />
      <circle cx={601} cy={50.092} r={1} fill="#A6966D" />
      <g filter="url(#aq)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M579.001 45.092a1 1 0 0 0-1 1 .243.243 0 0 1-.415.171 1 1 0 1 0-1.414 1.414.243.243 0 0 1-.172.414 1 1 0 0 0 0 2c.216 0 .325.261.172.414a1 1 0 1 0 1.414 1.414.243.243 0 0 1 .415.173 1 1 0 0 0 2 0c0-.216.261-.325.414-.172a1 1 0 1 0 1.414-1.414.243.243 0 0 1 .171-.415 1 1 0 0 0 0-2 .243.243 0 0 1-.171-.414 1 1 0 0 0-1.415-1.414.242.242 0 0 1-.413-.171 1 1 0 0 0-1-1Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={579} cy={49.092} r={2} fill="#E6E2CF" />
      <circle cx={579} cy={49.092} r={1} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.427 8.105a7 7 0 1 0-13.855 0 7 7 0 0 0-5.401 10.864A4.995 4.995 0 0 0 8 23.092c0 1.043.32 2.012.866 2.814A8.5 8.5 0 0 0 4 33.591c0 .89.137 1.75.391 2.557a8.5 8.5 0 1 0 10.8 12.686 8.003 8.003 0 0 0 15.618 0 8.5 8.5 0 1 0 10.8-12.686 8.503 8.503 0 0 0-5.27-10.571c.42-.733.661-1.581.661-2.486 0-1.711-.86-3.222-2.17-4.123a7 7 0 0 0-5.402-10.864Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 55.076a8.003 8.003 0 0 0 8.31-6.241 8.5 8.5 0 1 0 10.8-12.686 8.503 8.503 0 0 0-5.27-10.571c.42-.733.66-1.581.66-2.486 0-1.711-.86-3.222-2.17-4.123a7 7 0 0 0-5.402-10.864A7 7 0 0 0 22.5.092v54.984Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m24.355 27.326.674 9.17 8.299-5.99-8.02 9.779.56 7.606 7.46-5.385-7.21 8.792 1.26 17.145 4.122 1.649h-18l4.121-1.649 1.382-18.788-7.504-9.149 7.764 5.603.699-9.5-.04.062-8.337-10.165 8.66 6.25.628-8.545-7.959-9.705 8.236 5.944L22.5 2.092l1.604 21.816 7.485-5.402-7.234 8.82Z"
        fill="url(#ar)"
      />
      <g filter="url(#as)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.65 20.35a1.599 1.599 0 0 1-1.91-1.908 1.599 1.599 0 0 1 0-2.7 1.599 1.599 0 0 1 1.91-1.91 1.599 1.599 0 0 1 2.7 0 1.599 1.599 0 0 1 1.91 1.91 1.599 1.599 0 0 1-.001 2.7 1.599 1.599 0 0 1-1.909 1.91 1.599 1.599 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={14} cy={17.091} r={2.4} fill="#E6E2CF" />
      <circle cx={14} cy={17.092} r={0.8} fill="#A6966D" />
      <g filter="url(#at)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.65 22.35a1.599 1.599 0 0 1-1.91-1.908 1.599 1.599 0 0 1 0-2.7 1.599 1.599 0 0 1 1.91-1.91 1.599 1.599 0 0 1 2.7 0 1.599 1.599 0 0 1 1.91 1.91 1.599 1.599 0 0 1-.001 2.7 1.599 1.599 0 0 1-1.909 1.91 1.599 1.599 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={30} cy={19.091} r={2.4} fill="#E6E2CF" />
      <circle cx={30} cy={19.092} r={0.8} fill="#A6966D" />
      <g filter="url(#au)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.65 11.35a1.598 1.598 0 0 1-1.909-1.909 1.599 1.599 0 0 1 0-2.7 1.599 1.599 0 0 1 1.91-1.908 1.599 1.599 0 0 1 2.699 0 1.599 1.599 0 0 1 1.91 1.909 1.599 1.599 0 0 1-.001 2.7 1.599 1.599 0 0 1-1.909 1.909 1.599 1.599 0 0 1-2.7 0Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={22} cy={8.091} r={2.4} fill="#E6E2CF" />
      <circle cx={22} cy={8.092} r={0.8} fill="#A6966D" />
      <g filter="url(#av)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.65 34.35a1.599 1.599 0 0 1-1.91-1.908 1.599 1.599 0 0 1 0-2.7 1.599 1.599 0 0 1 1.91-1.91 1.599 1.599 0 0 1 2.7 0 1.599 1.599 0 0 1 1.91 1.91 1.599 1.599 0 0 1-.001 2.7 1.599 1.599 0 0 1-1.909 1.91 1.599 1.599 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={14} cy={31.091} r={2.4} fill="#E6E2CF" />
      <circle cx={14} cy={31.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aw)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.65 45.35a1.599 1.599 0 0 1-1.91-1.908 1.599 1.599 0 0 1 0-2.7 1.599 1.599 0 0 1 1.91-1.91 1.599 1.599 0 0 1 2.7 0 1.599 1.599 0 0 1 1.91 1.91 1.599 1.599 0 0 1-.001 2.7 1.599 1.599 0 0 1-1.909 1.91 1.599 1.599 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={9} cy={42.091} r={2.4} fill="#E6E2CF" />
      <circle cx={9} cy={42.092} r={0.8} fill="#A6966D" />
      <g filter="url(#ax)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.65 48.35a1.599 1.599 0 0 1-1.91-1.908 1.599 1.599 0 0 1 0-2.7 1.599 1.599 0 0 1 1.91-1.91 1.599 1.599 0 0 1 2.7 0 1.599 1.599 0 0 1 1.91 1.91 1.599 1.599 0 0 1-.001 2.7 1.599 1.599 0 0 1-1.909 1.91 1.599 1.599 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={36} cy={45.091} r={2.4} fill="#E6E2CF" />
      <circle cx={36} cy={45.092} r={0.8} fill="#A6966D" />
      <g filter="url(#ay)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.65 35.35a1.599 1.599 0 0 1-1.91-1.908 1.599 1.599 0 0 1 0-2.7 1.599 1.599 0 0 1 1.91-1.91 1.599 1.599 0 0 1 2.7 0 1.599 1.599 0 0 1 1.91 1.91 1.599 1.599 0 0 1-.001 2.7 1.599 1.599 0 0 1-1.909 1.91 1.599 1.599 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={32} cy={32.091} r={2.4} fill="#E6E2CF" />
      <circle cx={32} cy={32.092} r={0.8} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M278.427 8.105a7 7 0 1 0-13.854 0 7 7 0 0 0-5.402 10.864A4.995 4.995 0 0 0 257 23.092c0 1.043.32 2.012.866 2.814A8.502 8.502 0 0 0 253 33.591c0 .89.137 1.75.391 2.557a8.5 8.5 0 1 0 10.8 12.686 8.002 8.002 0 0 0 15.618 0 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.711-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M271.5 55.076a8.002 8.002 0 0 0 8.309-6.241 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.711-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864A7 7 0 0 0 271.5.092v54.984Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m273.355 27.326.674 9.17 8.299-5.99-8.02 9.779.559 7.606 7.461-5.385-7.21 8.792 1.261 17.145 4.121 1.649h-18l4.121-1.649 1.382-18.788-7.503-9.149 7.763 5.603.699-9.5-.04.062-8.337-10.165 8.66 6.25.628-8.545-7.959-9.705 8.236 5.944 1.35-18.358 1.604 21.816 7.485-5.402-7.234 8.82Z"
        fill="url(#az)"
      />
      <g filter="url(#aA)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.65 20.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={263} cy={17.091} r={2.4} fill="#E6E2CF" />
      <circle cx={263} cy={17.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aB)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M277.65 22.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={279} cy={19.091} r={2.4} fill="#E6E2CF" />
      <circle cx={279} cy={19.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aC)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M269.65 11.35a1.598 1.598 0 0 1-1.909-1.909 1.599 1.599 0 0 1-.001-2.7 1.6 1.6 0 0 1 1.91-1.908 1.6 1.6 0 0 1 2.7 0 1.6 1.6 0 0 1 1.909 1.909 1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.909 1.598 1.598 0 0 1-2.7 0Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={271} cy={8.091} r={2.4} fill="#E6E2CF" />
      <circle cx={271} cy={8.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aD)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M261.65 34.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={263} cy={31.091} r={2.4} fill="#E6E2CF" />
      <circle cx={263} cy={31.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aE)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M256.65 45.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={258} cy={42.091} r={2.4} fill="#E6E2CF" />
      <circle cx={258} cy={42.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aF)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M283.65 48.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={285} cy={45.091} r={2.4} fill="#E6E2CF" />
      <circle cx={285} cy={45.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aG)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M279.65 35.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={281} cy={32.091} r={2.4} fill="#E6E2CF" />
      <circle cx={281} cy={32.092} r={0.8} fill="#A6966D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M655.427 8.105a7 7 0 1 0-13.854 0 7 7 0 0 0-5.402 10.864A4.995 4.995 0 0 0 634 23.092c0 1.043.32 2.012.866 2.814A8.502 8.502 0 0 0 630 33.591c0 .89.137 1.75.391 2.557a8.5 8.5 0 1 0 10.8 12.686 8.002 8.002 0 0 0 15.618 0 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.711-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M648.5 55.076a8.002 8.002 0 0 0 8.309-6.241 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.711-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864A7 7 0 0 0 648.5.092v54.984Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m650.355 27.326.674 9.17 8.299-5.99-8.02 9.779.559 7.606 7.461-5.385-7.21 8.792 1.261 17.145 4.121 1.649h-18l4.121-1.649 1.382-18.788-7.503-9.149 7.763 5.603.699-9.5-.04.062-8.337-10.165 8.66 6.25.628-8.545-7.959-9.705 8.236 5.944 1.35-18.358 1.604 21.816 7.485-5.402-7.234 8.82Z"
        fill="url(#aH)"
      />
      <g filter="url(#aI)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M638.65 20.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={640} cy={17.091} r={2.4} fill="#E6E2CF" />
      <circle cx={640} cy={17.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aJ)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M654.65 22.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={656} cy={19.091} r={2.4} fill="#E6E2CF" />
      <circle cx={656} cy={19.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aK)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M646.65 11.35a1.598 1.598 0 0 1-1.909-1.909 1.599 1.599 0 0 1-.001-2.7 1.6 1.6 0 0 1 1.91-1.908 1.6 1.6 0 0 1 2.7 0 1.6 1.6 0 0 1 1.909 1.909 1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.909 1.598 1.598 0 0 1-2.7 0Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={648} cy={8.091} r={2.4} fill="#E6E2CF" />
      <circle cx={648} cy={8.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aL)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M638.65 34.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={640} cy={31.091} r={2.4} fill="#E6E2CF" />
      <circle cx={640} cy={31.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aM)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M633.65 45.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={635} cy={42.091} r={2.4} fill="#E6E2CF" />
      <circle cx={635} cy={42.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aN)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M660.65 48.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={662} cy={45.091} r={2.4} fill="#E6E2CF" />
      <circle cx={662} cy={45.092} r={0.8} fill="#A6966D" />
      <g filter="url(#aO)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M656.65 35.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={658} cy={32.091} r={2.4} fill="#E6E2CF" />
      <circle cx={658} cy={32.092} r={0.8} fill="#A6966D" />
      <defs>
        <filter
          id="k"
          x={122}
          y={23.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="l"
          x={136}
          y={29.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="m"
          x={131}
          y={13.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="n"
          x={119}
          y={41.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="o"
          x={141}
          y={42.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="q"
          x={539}
          y={23.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="r"
          x={553}
          y={29.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="s"
          x={548}
          y={13.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="t"
          x={536}
          y={41.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="u"
          x={558}
          y={42.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="w"
          x={311}
          y={23.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="x"
          x={325}
          y={29.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="y"
          x={320}
          y={13.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="z"
          x={308}
          y={41.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="A"
          x={330}
          y={42.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="C"
          x={483}
          y={17.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="D"
          x={495}
          y={25.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="E"
          x={476}
          y={30.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="F"
          x={488}
          y={38.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="G"
          x={498}
          y={46.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="H"
          x={476}
          y={45.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="J"
          x={393}
          y={17.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="K"
          x={405}
          y={25.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="L"
          x={386}
          y={30.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="M"
          x={398}
          y={38.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="N"
          x={408}
          y={46.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="O"
          x={386}
          y={45.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="Q"
          x={49}
          y={17.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="R"
          x={61}
          y={25.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="S"
          x={42}
          y={30.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="T"
          x={54}
          y={38.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="U"
          x={64}
          y={46.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="V"
          x={42}
          y={45.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="X"
          x={49}
          y={17.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="Y"
          x={61}
          y={25.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="Z"
          x={42}
          y={30.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aa"
          x={54}
          y={38.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ab"
          x={64}
          y={46.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ac"
          x={42}
          y={45.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ae"
          x={201}
          y={17.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="af"
          x={213}
          y={25.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ag"
          x={194}
          y={30.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ah"
          x={206}
          y={38.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ai"
          x={216}
          y={46.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aj"
          x={194}
          y={45.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="al"
          x={582}
          y={17.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="am"
          x={594}
          y={25.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="an"
          x={575}
          y={30.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ao"
          x={587}
          y={38.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ap"
          x={597}
          y={46.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aq"
          x={575}
          y={45.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="as"
          x={10}
          y={13.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="at"
          x={26}
          y={15.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="au"
          x={18}
          y={4.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="av"
          x={10}
          y={27.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aw"
          x={5}
          y={38.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ax"
          x={32}
          y={41.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="ay"
          x={28}
          y={28.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aA"
          x={259}
          y={13.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aB"
          x={275}
          y={15.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aC"
          x={267}
          y={4.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aD"
          x={259}
          y={27.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aE"
          x={254}
          y={38.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aF"
          x={281}
          y={41.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aG"
          x={277}
          y={28.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aI"
          x={636}
          y={13.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aJ"
          x={652}
          y={15.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aK"
          x={644}
          y={4.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aL"
          x={636}
          y={27.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aM"
          x={631}
          y={38.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aN"
          x={658}
          y={41.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <filter
          id="aO"
          x={654}
          y={28.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_80910"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_80910"
            result="shape"
          />
        </filter>
        <linearGradient
          id="a"
          x1={101.539}
          y1={30.092}
          x2={101.539}
          y2={70.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={611.539}
          y1={30.092}
          x2={611.539}
          y2={70.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={173.539}
          y1={26.092}
          x2={173.539}
          y2={66.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={418.539}
          y1={20.092}
          x2={418.539}
          y2={60.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={349.845}
          y1={30.092}
          x2={349.845}
          y2={70.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={243.845}
          y1={30.092}
          x2={243.845}
          y2={70.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={380.845}
          y1={30.092}
          x2={380.845}
          y2={70.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={463.845}
          y1={30.092}
          x2={463.845}
          y2={70.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={510.845}
          y1={30.092}
          x2={510.845}
          y2={70.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={122.415}
          y1={14.092}
          x2={143.657}
          y2={62.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={539.415}
          y1={14.092}
          x2={560.657}
          y2={62.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="v"
          x1={311.415}
          y1={14.092}
          x2={332.657}
          y2={62.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="B"
          x1={478.415}
          y1={14.092}
          x2={499.657}
          y2={62.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="I"
          x1={388.415}
          y1={14.092}
          x2={409.657}
          y2={62.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="P"
          x1={44.415}
          y1={14.092}
          x2={65.657}
          y2={62.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="W"
          x1={44.415}
          y1={14.092}
          x2={65.657}
          y2={62.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="ad"
          x1={196.415}
          y1={14.092}
          x2={217.657}
          y2={62.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="ak"
          x1={577.415}
          y1={14.092}
          x2={598.657}
          y2={62.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="ar"
          x1={10.915}
          y1={2.092}
          x2={32.157}
          y2={50.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="az"
          x1={259.915}
          y1={2.092}
          x2={281.157}
          y2={50.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="aH"
          x1={636.915}
          y1={2.092}
          x2={658.157}
          y2={50.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
      </defs>
    </svg>
  );
}
