export function Tree100(props: any) {
  return (
    <svg
      width={685}
      height={101}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m274.752 44.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.942 8.671-6.259-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L264 71.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M282.499 48.253a1.354 1.354 0 0 1-1.036-1.433 7 7 0 1 0-13.926 0 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 260 65.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88 8.494 8.494 0 0 0 7.137-3.88c.289-.446.851-.65 1.36-.494a8.5 8.5 0 0 0 6.252-15.756c-.663-.326-.934-1.235-.645-1.916.255-.6.396-1.26.396-1.953a4.99 4.99 0 0 0-1.878-3.906c-.601-.48-.799-1.484-.361-2.117a7.002 7.002 0 0 0-4.262-10.816Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275 39.61a.508.508 0 0 0-.5-.518 7 7 0 0 0-6.963 7.728 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 260 65.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88c.281 0 .5-.233.5-.514V39.61Z"
        fill="#314C42"
      />
      <path d="M279 48.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M275 45.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M272 61.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M268 58.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M286 64.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M282 61.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M268 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M264 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M290 79.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M286 76.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M525.5 41.092s-7 7.925-7 14 3 8 7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M525.5 63.092c4 0 7-1.925 7-8s-7-14-7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M504.95 60.314s.654 10.553 4.949 14.85c4.296 4.295 7.779 3.535 10.607.706L504.95 60.314Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M520.506 75.87c2.828-2.829 3.589-6.311-.707-10.607-4.296-4.296-14.849-4.95-14.849-4.95l15.556 15.557Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M504.95 78.041s.654 10.554 4.949 14.85c4.296 4.295 7.779 3.535 10.607.707L504.95 78.042Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M520.506 93.597c2.828-2.828 3.589-6.31-.707-10.606s-14.849-4.95-14.849-4.95l15.556 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M545.45 59.041s-10.554.655-14.849 4.95c-4.296 4.296-3.536 7.778-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M529.893 74.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.296 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M545.45 76.041s-10.554.654-14.849 4.95c-4.296 4.296-3.536 7.778-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M529.893 91.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.296 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m525.5 56.092 1.103 19.413 8.312-5.999-8.092 9.867.708 12.462 7.383-5.329-7.188 8.764.274 4.822h-5l.134-2.359-6.971-8.499 7.16 5.168.753-13.247-7.913-9.649 8.128 5.867 1.209-21.281Z"
        fill="url(#b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m567.054 71.907.053-.05.054-.05-.107.1Zm-4.589 8.477c.057-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M562.465 80.384h-.001s1.246-5.359 4.643-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m575.529 79.203-13.064 12.182s5.269-.842 8.563-3.914c3.293-3.072 4.501-8.269 4.501-8.269Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M562.465 91.384s1.207-5.197 4.501-8.268c3.294-3.072 8.563-3.914 8.563-3.914l-13.064 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M562.241 73.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M562.241 73.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M548.501 64.782c.013.058 1.268 5.393 4.657 8.553 3.389 3.16 8.798 4.04 8.857 4.05l-13.514-12.603Zm8.914 4.1-.056-.052-.056-.052.112.105Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M562.016 77.385s-1.249-5.376-4.657-8.554c-3.407-3.178-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m557.8 79.606-.038-.035-.038-.035.076.07Zm4.216 7.78c-.04-.007-4.991-.808-8.092-3.7-3.1-2.89-4.245-7.774-4.254-7.814l12.346 11.513ZM549.67 75.871Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M562.016 87.385h.001s-1.142-4.911-4.255-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m562.245 60.092-2 40h4l-2-40Z" fill="url(#c)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.898 45.092s7 7.925 7 14-3 8-7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.898 67.092c-4 0-7-1.925-7-8s7-14 7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.449 61.314s-.654 10.553-4.95 14.85c-4.296 4.295-7.778 3.535-10.607.706L88.45 61.314Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.892 76.87c-2.828-2.829-3.588-6.311.708-10.607 4.295-4.296 14.849-4.95 14.849-4.95L72.892 76.87Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.949 62.041s10.553.655 14.849 4.95c4.296 4.296 3.535 7.778.707 10.607L47.949 62.042Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.505 77.598c-2.828 2.829-6.31 3.589-10.607-.707-4.295-4.296-4.95-14.849-4.95-14.849l15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m67.898 60.092-.927 18.54-8.487-6.126 8.288 10.106-.874 17.48h4l-.878-17.569 8.215-10.017-8.412 6.072-.925-18.486Z"
        fill="url(#d)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.898 45.092s7 7.925 7 14-3 8-7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M431.898 67.092c-4 0-7-1.925-7-8s7-14 7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M452.449 61.314s-.654 10.553-4.95 14.85c-4.296 4.295-7.778 3.535-10.607.706l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M436.892 76.87c-2.828-2.829-3.588-6.311.707-10.607 4.296-4.296 14.85-4.95 14.85-4.95L436.892 76.87Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M411.949 62.041s10.553.655 14.849 4.95c4.296 4.296 3.535 7.778.707 10.607l-15.556-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M427.505 77.598c-2.828 2.829-6.311 3.589-10.607-.707-4.295-4.296-4.949-14.849-4.949-14.849l15.556 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m431.898 60.092-.927 18.54-8.487-6.126 8.288 10.106-.874 17.48h4l-.878-17.569 8.215-10.017-8.412 6.072-.925-18.486Z"
        fill="url(#e)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.5 45.092s-7 7.925-7 14 3 8 7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.5 67.092c4 0 7-1.925 7-8s-7-14-7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.95 61.314s.654 10.553 4.949 14.85c4.296 4.295 7.779 3.535 10.607.706L160.95 61.314Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M176.506 76.87c2.828-2.829 3.589-6.311-.707-10.607-4.296-4.296-14.849-4.95-14.849-4.95l15.556 15.557Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.45 62.041s-10.554.655-14.849 4.95c-4.296 4.296-3.536 7.778-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.893 77.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.296 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m181.5 60.092.927 18.54 8.487-6.126-8.288 10.106.874 17.48h-4l.879-17.569-8.216-10.017 8.413 6.072.924-18.486Z"
        fill="url(#f)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M375.5 41.092s-7 7.925-7 14 3 8 7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M375.5 63.092c4 0 7-1.925 7-8s-7-14-7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M354.95 60.314s.654 10.553 4.949 14.85c4.296 4.295 7.779 3.535 10.607.706L354.95 60.314Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M370.506 75.87c2.828-2.829 3.589-6.311-.707-10.607-4.296-4.296-14.849-4.95-14.849-4.95l15.556 15.557Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M354.95 78.041s.654 10.554 4.949 14.85c4.296 4.295 7.779 3.535 10.607.707L354.95 78.042Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M370.506 93.597c2.828-2.828 3.589-6.31-.707-10.606s-14.849-4.95-14.849-4.95l15.556 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M395.45 59.041s-10.554.655-14.849 4.95c-4.296 4.296-3.536 7.778-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.893 74.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.296 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M395.45 76.041s-10.554.654-14.849 4.95c-4.296 4.296-3.536 7.778-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M379.893 91.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.296 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m375.5 56.092 1.103 19.413 8.312-5.999-8.092 9.867.708 12.462 7.383-5.329-7.188 8.764.274 4.822h-5l.134-2.359-6.971-8.499 7.16 5.168.753-13.247-7.913-9.649 8.128 5.867 1.209-21.281Z"
        fill="url(#g)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.5 41.092s-7 7.925-7 14 3 8 7 8v-22Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.5 63.092c4 0 7-1.925 7-8s-7-14-7-14v22Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M594.95 60.314s.654 10.553 4.949 14.85c4.296 4.295 7.779 3.535 10.607.706L594.95 60.314Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M610.506 75.87c2.828-2.829 3.589-6.311-.707-10.607-4.296-4.296-14.849-4.95-14.849-4.95l15.556 15.557Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M594.95 78.041s.654 10.554 4.949 14.85c4.296 4.295 7.779 3.535 10.607.707L594.95 78.042Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M610.506 93.597c2.828-2.828 3.589-6.31-.707-10.606s-14.849-4.95-14.849-4.95l15.556 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M635.45 59.041s-10.554.655-14.849 4.95c-4.296 4.296-3.536 7.778-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M619.893 74.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.296 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M635.45 76.041s-10.554.654-14.849 4.95c-4.296 4.296-3.536 7.778-.708 10.607l15.557-15.556Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M619.893 91.598c2.829 2.829 6.311 3.589 10.607-.707 4.296-4.296 4.95-14.849 4.95-14.849l-15.557 15.556Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m615.5 56.092 1.103 19.413 8.312-5.999-8.092 9.867.708 12.462 7.383-5.329-7.188 8.764.274 4.822h-5l.134-2.359-6.971-8.499 7.16 5.168.753-13.247-7.913-9.649 8.128 5.867 1.209-21.281Z"
        fill="url(#h)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m243.054 71.907.053-.05.054-.05-.107.1Zm-4.589 8.477c.057-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.465 80.384h-.001s1.246-5.359 4.643-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m251.529 79.203-13.064 12.182s5.269-.842 8.563-3.914c3.293-3.072 4.501-8.269 4.501-8.269Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.465 91.384s1.207-5.197 4.501-8.268c3.294-3.072 8.563-3.914 8.563-3.914l-13.064 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.241 73.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.241 73.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.501 64.782c.013.058 1.268 5.393 4.657 8.553 3.389 3.16 8.798 4.04 8.857 4.05l-13.514-12.603Zm8.914 4.1-.056-.052-.056-.052.112.105Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.016 77.385s-1.249-5.376-4.657-8.554c-3.407-3.178-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m233.8 79.606-.038-.035-.038-.035.076.07Zm4.216 7.78c-.04-.007-4.991-.808-8.092-3.7-3.1-2.89-4.245-7.774-4.254-7.814l12.346 11.513ZM225.67 75.871Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.016 87.385h.001s-1.142-4.911-4.255-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m238.245 60.092-2 40h4l-2-40Z" fill="url(#i)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m106.654 71.907.053-.05.053-.05-.106.1Zm-4.589 8.477c.056-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.064 80.384s1.246-5.359 4.642-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m115.129 79.203-13.064 12.182s5.268-.842 8.562-3.914c3.294-3.072 4.502-8.269 4.502-8.269Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.065 91.384h-.001s1.208-5.197 4.502-8.268c3.294-3.072 8.562-3.914 8.562-3.914l-13.063 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.841 73.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.841 73.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.1 64.782c.014.058 1.268 5.393 4.657 8.553 3.39 3.16 8.799 4.04 8.858 4.05L88.1 64.782Zm8.914 4.1a9.275 9.275 0 0 0-.112-.104l.112.105Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.616 77.385s-1.249-5.376-4.657-8.554-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.4 79.606a12.2 12.2 0 0 0-.076-.07l.076.07Zm4.216 7.78c-.04-.007-4.992-.808-8.092-3.7-3.1-2.89-4.245-7.774-4.255-7.814l12.347 11.513ZM89.269 75.871Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.616 87.385s-1.141-4.911-4.254-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m101.845 60.092-2 40h4l-2-40Z" fill="url(#j)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m343.654 71.907.053-.05.053-.05-.106.1Zm-4.589 8.477c.056-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M339.064 80.384s1.246-5.359 4.642-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m352.129 79.203-13.064 12.182s5.268-.842 8.562-3.914c3.294-3.072 4.502-8.269 4.502-8.269Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M339.065 91.384h-.001s1.208-5.197 4.502-8.268c3.294-3.072 8.562-3.914 8.562-3.914l-13.063 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M338.841 73.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M338.841 73.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M325.1 64.782c.014.058 1.268 5.393 4.657 8.553 3.389 3.16 8.799 4.04 8.858 4.05L325.1 64.782Zm8.914 4.1-.056-.052a2.351 2.351 0 0 0-.056-.052l.112.105Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M338.616 77.385s-1.249-5.376-4.657-8.554-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m334.399 79.606-.037-.035-.038-.035.075.07Zm4.217 7.78c-.04-.007-4.992-.808-8.092-3.7-3.1-2.89-4.245-7.774-4.254-7.814l12.346 11.513ZM326.27 75.871h-.001.001Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M338.616 87.385s-1.141-4.911-4.254-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m338.845 60.092-2 40h4l-2-40Z" fill="url(#k)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m473.654 71.907.053-.05.053-.05-.106.1Zm-4.589 8.477c.056-.009 5.451-.885 8.83-4.036 3.379-3.15 4.629-8.47 4.642-8.527l-13.472 12.563Zm13.472-12.563Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M469.064 80.384s1.246-5.359 4.642-8.527c3.397-3.167 8.83-4.036 8.83-4.036l-13.472 12.563Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m482.129 79.203-13.064 12.182s5.268-.842 8.562-3.914c3.294-3.072 4.502-8.269 4.502-8.269Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M469.065 91.384h-.001s1.208-5.197 4.502-8.268c3.294-3.072 8.562-3.914 8.562-3.914l-13.063 12.182Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M468.841 73.201v-24s4 5.95 4 12c0 6.051-4 12-4 12Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M468.841 73.201s-4-5.949-4-12c0-6.05 4-12 4-12v24Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M455.1 64.782c.014.058 1.268 5.393 4.657 8.553 3.389 3.16 8.799 4.04 8.858 4.05L455.1 64.782Zm8.914 4.1-.056-.052a2.351 2.351 0 0 0-.056-.052l.112.105Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M468.616 77.385s-1.249-5.376-4.657-8.554-8.858-4.049-8.858-4.049l13.515 12.603Z"
        fill="#183128"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m464.399 79.606-.037-.035-.038-.035.075.07Zm4.217 7.78c-.04-.007-4.992-.808-8.092-3.7-3.1-2.89-4.245-7.774-4.254-7.814l12.346 11.513ZM456.27 75.871h-.001.001Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M468.616 87.385s-1.141-4.911-4.254-7.814c-3.113-2.903-8.092-3.7-8.092-3.7v.001l12.346 11.513Z"
        fill="#183128"
      />
      <path d="m468.845 60.092-2 40h4l-2-40Z" fill="url(#l)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.427 38.105a7 7 0 1 0-13.855 0 7 7 0 0 0-5.401 10.864A4.995 4.995 0 0 0 16 53.092c0 1.043.32 2.012.866 2.814A8.5 8.5 0 0 0 12 63.592c0 .89.137 1.75.391 2.557a8.5 8.5 0 1 0 10.8 12.686 8.003 8.003 0 0 0 15.618 0 8.5 8.5 0 1 0 10.8-12.686 8.503 8.503 0 0 0-5.27-10.571c.42-.733.661-1.581.661-2.486 0-1.712-.86-3.222-2.17-4.123a7 7 0 0 0-5.402-10.864Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 85.076a8.003 8.003 0 0 0 8.31-6.241 8.5 8.5 0 1 0 10.8-12.686 8.503 8.503 0 0 0-5.27-10.571c.42-.733.66-1.581.66-2.486 0-1.712-.86-3.222-2.17-4.123a7 7 0 0 0-5.402-10.864 7 7 0 0 0-6.927-8.013v54.984Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m32.356 57.326.674 9.17 8.298-5.99-8.02 9.779.484 6.574L45 70.092 34.131 81.477l1.248 16.966 4.121 1.649h-18l4.121-1.649 1.368-18.602L15 68.09l12.313 7.348.65-8.83-.04.062-8.337-10.165 8.66 6.25.628-8.545-7.96-9.705 8.236 5.944 1.35-18.358 1.604 21.816 7.485-5.402-7.233 8.82Z"
        fill="url(#m)"
      />
      <g filter="url(#n)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.65 67.35a1.599 1.599 0 0 1-1.91-1.908 1.599 1.599 0 0 1 0-2.7 1.599 1.599 0 0 1 1.91-1.91 1.599 1.599 0 0 1 2.7 0 1.599 1.599 0 0 1 1.91 1.91 1.599 1.599 0 0 1-.001 2.7 1.599 1.599 0 0 1-1.909 1.91 1.599 1.599 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={42} cy={64.091} r={2.4} fill="#E6E2CF" />
      <circle cx={42} cy={64.092} r={0.8} fill="#A6966D" />
      <g filter="url(#o)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.65 64.35a1.599 1.599 0 0 1-1.91-1.908 1.599 1.599 0 0 1 0-2.7 1.599 1.599 0 0 1 1.91-1.91 1.599 1.599 0 0 1 2.7 0 1.599 1.599 0 0 1 1.91 1.91 1.599 1.599 0 0 1-.001 2.7 1.599 1.599 0 0 1-1.909 1.91 1.599 1.599 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={19} cy={61.091} r={2.4} fill="#E6E2CF" />
      <circle cx={19} cy={61.092} r={0.8} fill="#A6966D" />
      <path d="M34 40.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M30 37.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M25 50.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M21 47.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M43 54.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M39 51.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M20 73.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M16 70.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M48 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M44 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.427 38.105a7 7 0 1 0-13.854 0 7 7 0 0 0-5.402 10.864A4.995 4.995 0 0 0 144 53.092c0 1.043.32 2.012.866 2.814A8.502 8.502 0 0 0 140 63.592c0 .89.137 1.75.391 2.557a8.5 8.5 0 1 0 10.8 12.686 8.002 8.002 0 0 0 15.618 0 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.712-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.5 85.076a8.002 8.002 0 0 0 8.309-6.241 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.712-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864 7 7 0 0 0-6.927-8.013v54.984Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m160.356 57.326.674 9.17 8.298-5.99-8.02 9.779.484 6.574L173 70.092l-10.869 11.385 1.248 16.966 4.121 1.649h-18l4.121-1.649 1.368-18.602L143 68.09l12.313 7.348.649-8.83-.04.062-8.336-10.165 8.659 6.25.629-8.545-7.959-9.705 8.235 5.944 1.35-18.358 1.604 21.816 7.485-5.402-7.233 8.82Z"
        fill="url(#p)"
      />
      <g filter="url(#q)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M168.65 67.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={170} cy={64.091} r={2.4} fill="#E6E2CF" />
      <circle cx={170} cy={64.092} r={0.8} fill="#A6966D" />
      <g filter="url(#r)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145.65 64.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={147} cy={61.091} r={2.4} fill="#E6E2CF" />
      <circle cx={147} cy={61.092} r={0.8} fill="#A6966D" />
      <path d="M162 40.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M158 37.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M153 50.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M149 47.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M171 54.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M167 51.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M148 73.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M144 70.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M176 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M172 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M660.427 38.105a7 7 0 1 0-13.854 0 7 7 0 0 0-5.402 10.864A4.995 4.995 0 0 0 639 53.092c0 1.043.32 2.012.866 2.814A8.502 8.502 0 0 0 635 63.592c0 .89.137 1.75.391 2.557a8.5 8.5 0 1 0 10.8 12.686 8.002 8.002 0 0 0 15.618 0 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.712-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M653.5 85.076a8.002 8.002 0 0 0 8.309-6.241 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.712-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864 7 7 0 0 0-6.927-8.013v54.984Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m655.356 57.326.674 9.17 8.298-5.99-8.02 9.779.484 6.574L668 70.092l-10.869 11.385 1.248 16.966 4.121 1.649h-18l4.121-1.649 1.368-18.602L638 68.09l12.313 7.348.649-8.83-.04.062-8.336-10.165 8.659 6.25.629-8.545-7.959-9.705 8.235 5.944 1.35-18.358 1.604 21.816 7.485-5.402-7.233 8.82Z"
        fill="url(#s)"
      />
      <g filter="url(#t)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M663.65 67.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={665} cy={64.091} r={2.4} fill="#E6E2CF" />
      <circle cx={665} cy={64.092} r={0.8} fill="#A6966D" />
      <g filter="url(#u)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M640.65 64.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={642} cy={61.091} r={2.4} fill="#E6E2CF" />
      <circle cx={642} cy={61.092} r={0.8} fill="#A6966D" />
      <path d="M657 40.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M653 37.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M648 50.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M644 47.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M666 54.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M662 51.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M643 73.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M639 70.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M671 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M667 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M571.209 48.095a7 7 0 1 0-13.418 0 7 7 0 0 0-4.403 12.263 5.001 5.001 0 0 0-2.142 8.036 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 7.607 4.704 8.5 8.5 0 0 0 7.607-4.704 8.5 8.5 0 1 0 5.647-15.994 5.001 5.001 0 0 0-2.142-8.037 7 7 0 0 0-4.403-12.263Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M565 39.11a7 7 0 0 0-7.209 8.986 7 7 0 0 0-4.403 12.261 5.001 5.001 0 0 0-2.142 8.037 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 8.107 4.69V39.108Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m564.752 44.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.942 8.671-6.259-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L554 71.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#v)"
      />
      <path d="M569 48.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M565 45.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M562 61.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M558 58.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M576 64.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M572 61.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M558 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M554 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M580 79.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M576 76.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M488.427 38.105a7 7 0 1 0-13.854 0 7 7 0 0 0-5.402 10.864A4.995 4.995 0 0 0 467 53.092c0 1.043.32 2.012.866 2.814A8.502 8.502 0 0 0 463 63.592c0 .89.137 1.75.391 2.557a8.5 8.5 0 1 0 10.8 12.686 8.002 8.002 0 0 0 15.618 0 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.712-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M481.5 85.076a8.002 8.002 0 0 0 8.309-6.241 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.712-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864 7 7 0 0 0-6.927-8.013v54.984Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m483.356 57.326.674 9.17 8.298-5.99-8.02 9.779.484 6.574L496 70.092l-10.869 11.385 1.248 16.966 4.121 1.649h-18l4.121-1.649 1.368-18.602L466 68.09l12.313 7.348.649-8.83-.04.062-8.336-10.165 8.659 6.25.629-8.545-7.959-9.705 8.235 5.944 1.35-18.358 1.604 21.816 7.485-5.402-7.233 8.82Z"
        fill="url(#w)"
      />
      <g filter="url(#x)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M491.65 67.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={493} cy={64.091} r={2.4} fill="#E6E2CF" />
      <circle cx={493} cy={64.092} r={0.8} fill="#A6966D" />
      <g filter="url(#y)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M468.65 64.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={470} cy={61.091} r={2.4} fill="#E6E2CF" />
      <circle cx={470} cy={61.092} r={0.8} fill="#A6966D" />
      <path d="M485 40.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M481 37.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M476 50.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M472 47.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M494 54.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M490 51.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M471 73.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M467 70.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M499 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M495 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m398.752 44.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.942 8.671-6.259-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L388 71.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#z)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M406.499 48.253a1.354 1.354 0 0 1-1.036-1.433 7 7 0 1 0-13.926 0 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 384 65.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88 8.494 8.494 0 0 0 7.137-3.88c.289-.446.851-.65 1.36-.494a8.5 8.5 0 0 0 6.252-15.756c-.663-.326-.934-1.235-.645-1.916.255-.6.396-1.26.396-1.953a4.99 4.99 0 0 0-1.878-3.906c-.601-.48-.799-1.484-.361-2.117a7.002 7.002 0 0 0-4.262-10.816Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M399 39.61a.508.508 0 0 0-.5-.518 7 7 0 0 0-6.963 7.728 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 384 65.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88c.281 0 .5-.233.5-.514V39.61Z"
        fill="#314C42"
      />
      <path d="M403 48.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M399 45.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M396 61.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M392 58.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M410 64.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M406 61.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M392 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M388 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M414 79.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M410 76.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M316.427 38.105a7 7 0 1 0-13.854 0 7 7 0 0 0-5.402 10.864A4.995 4.995 0 0 0 295 53.092c0 1.043.32 2.012.866 2.814A8.502 8.502 0 0 0 291 63.592c0 .89.137 1.75.391 2.557a8.5 8.5 0 1 0 10.8 12.686 8.002 8.002 0 0 0 15.618 0 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.712-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M309.5 85.076a8.002 8.002 0 0 0 8.309-6.241 8.5 8.5 0 1 0 10.8-12.686 8.505 8.505 0 0 0 .391-2.557 8.503 8.503 0 0 0-5.661-8.014 4.97 4.97 0 0 0 .661-2.486c0-1.712-.86-3.222-2.171-4.123a7 7 0 0 0-5.402-10.864 7 7 0 0 0-6.927-8.013v54.984Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m311.356 57.326.674 9.17 8.298-5.99-8.02 9.779.484 6.574L324 70.092l-10.869 11.385 1.248 16.966 4.121 1.649h-18l4.121-1.649 1.368-18.602L294 68.09l12.313 7.348.649-8.83-.04.062-8.336-10.165 8.659 6.25.629-8.545-7.959-9.705 8.235 5.944 1.35-18.358 1.604 21.816 7.485-5.402-7.233 8.82Z"
        fill="url(#A)"
      />
      <g filter="url(#B)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M319.65 67.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={321} cy={64.091} r={2.4} fill="#E6E2CF" />
      <circle cx={321} cy={64.092} r={0.8} fill="#A6966D" />
      <g filter="url(#C)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M296.65 64.35a1.598 1.598 0 0 1-1.909-1.908 1.598 1.598 0 0 1 0-2.7 1.6 1.6 0 0 1 1.909-1.91 1.6 1.6 0 0 1 2.7 0 1.597 1.597 0 0 1 1.478.43c.402.402.545.964.431 1.48a1.6 1.6 0 0 1 0 2.7 1.6 1.6 0 0 1-1.909 1.91 1.598 1.598 0 0 1-2.7-.002Z"
          fill="#F7F6F0"
        />
      </g>
      <circle cx={298} cy={61.091} r={2.4} fill="#E6E2CF" />
      <circle cx={298} cy={61.092} r={0.8} fill="#A6966D" />
      <path d="M313 40.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M309 37.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M304 50.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M300 47.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M322 54.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M318 51.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M299 73.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M295 70.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M327 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M323 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m220.752 44.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.942 8.671-6.259-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L210 71.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#D)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.499 48.253a1.354 1.354 0 0 1-1.036-1.433 7 7 0 1 0-13.926 0 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 206 65.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88 8.494 8.494 0 0 0 7.137-3.88c.289-.446.851-.65 1.36-.494a8.5 8.5 0 0 0 6.252-15.756c-.663-.326-.934-1.235-.645-1.916.255-.6.396-1.26.396-1.953a4.99 4.99 0 0 0-1.878-3.906c-.601-.48-.799-1.484-.361-2.117a7.002 7.002 0 0 0-4.262-10.816Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M221 39.61a.508.508 0 0 0-.5-.518 7 7 0 0 0-6.963 7.728 1.354 1.354 0 0 1-1.036 1.433 7.002 7.002 0 0 0-4.262 10.816c.438.633.24 1.636-.361 2.117A4.99 4.99 0 0 0 206 65.092c0 .693.141 1.353.396 1.953.289.68.018 1.59-.645 1.916a8.5 8.5 0 0 0 6.252 15.756 1.208 1.208 0 0 1 1.36.494 8.494 8.494 0 0 0 7.137 3.88c.281 0 .5-.233.5-.514V39.61Z"
        fill="#314C42"
      />
      <path d="M225 48.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M221 45.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M218 61.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M214 58.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M232 64.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M228 61.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M214 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M210 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M236 79.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M232 76.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.209 48.095a7 7 0 1 0-13.418 0 7 7 0 0 0-4.403 12.263 5.001 5.001 0 0 0-2.142 8.036 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 7.607 4.704 8.5 8.5 0 0 0 7.607-4.704 8.5 8.5 0 1 0 5.647-15.994 5.001 5.001 0 0 0-2.142-8.037 7 7 0 0 0-4.403-12.263Z"
        fill="#142E24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125 39.11a7 7 0 0 0-7.209 8.986 7 7 0 0 0-4.403 12.261 5.001 5.001 0 0 0-2.142 8.037 8.5 8.5 0 1 0 5.647 15.994 8.5 8.5 0 0 0 8.107 4.69V39.108Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m124.752 44.092 1.437 20.116 7.899-5.702-7.641 9.317.71 9.942 8.671-6.259-8.336 10.165-.063-.097 1.187 16.619 3.799 1.899h-15.5l3.979-1.99 1.195-16.733L114 71.507l8.363 6.036.694-9.717-7.643-9.319 7.901 5.702 1.437-20.116Z"
        fill="url(#E)"
      />
      <path d="M129 48.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M125 45.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M122 61.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M118 58.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M136 64.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M132 61.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M118 76.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M114 73.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path d="M140 79.092a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" fill="#E1392C" />
      <path d="M136 76.092v-2" stroke="#312B2B" strokeLinecap="round" />
      <path
        d="M12.04 29.138 20 28.092l-7.96-1.045 3.28-4.277-4.274 3.282L10 18.092l-1.047 7.961-4.274-3.283 3.282 4.277L0 28.093l7.961 1.045-3.282 4.275 4.275-3.282L10 38.091l1.046-7.96 4.275 3.282-3.282-4.275ZM91.816 41.51 95 41.092l-3.184-.418 1.312-1.711-1.71 1.313L91 37.092l-.419 3.184-1.71-1.313 1.314 1.71-3.185.42 3.184.417-1.312 1.71 1.71-1.312.418 3.184.418-3.184 1.71 1.312-1.312-1.71ZM176.816 35.51l3.184-.418-3.184-.418 1.312-1.711-1.71 1.313-.418-3.184-.419 3.184-1.709-1.313 1.313 1.71-3.185.42 3.185.417-1.313 1.71 1.71-1.312.418 3.184.418-3.184 1.71 1.312-1.312-1.71ZM242.631 16.929l6.369-.837-6.369-.836 2.626-3.422-3.42 2.626L241 8.092l-.838 6.369-3.419-2.627 2.626 3.422-6.369.836 6.369.837-2.626 3.42 3.42-2.625.837 6.368.837-6.368 3.42 2.625-2.626-3.42ZM497.631 32.929l6.369-.837-6.369-.836 2.626-3.422-3.42 2.626-.837-6.368-.838 6.369-3.419-2.627 2.626 3.422-6.369.836 6.369.837-2.626 3.42 3.42-2.625.837 6.368.837-6.368 3.42 2.625-2.626-3.42ZM418.631 39.929l6.369-.837-6.369-.836 2.626-3.422-3.42 2.626-.837-6.368-.838 6.369-3.419-2.627 2.626 3.422-6.369.836 6.369.837-2.626 3.42 3.42-2.625.837 6.368.837-6.368 3.42 2.625-2.626-3.42ZM348.816 49.51l3.184-.418-3.184-.418 1.312-1.711-1.71 1.313-.418-3.184-.419 3.184-1.709-1.313 1.313 1.71-3.185.42 3.185.417-1.313 1.71 1.71-1.312.418 3.184.418-3.184 1.71 1.312-1.312-1.71ZM578.816 26.51l3.184-.418-3.184-.418 1.312-1.711-1.71 1.313-.418-3.184-.419 3.184-1.709-1.313 1.313 1.71-3.185.42 3.185.417-1.313 1.71 1.71-1.312.418 3.184.418-3.184 1.71 1.312-1.312-1.71ZM678.631 23.929l6.369-.837-6.369-.836 2.626-3.422-3.42 2.626-.837-6.368-.838 6.369-3.419-2.627 2.626 3.422-6.369.836 6.369.837-2.626 3.42 3.42-2.625.837 6.368.837-6.368 3.42 2.625-2.626-3.42Z"
        fill="#BDA35E"
      />
      <path
        d="M82.6 75.493c2.992-2.993 8.108-.873 8.108 3.358v8.325a7.917 7.917 0 0 1-7.916 7.916H63l19.6-19.6Z"
        fill="#E57C00"
      />
      <path
        d="M80.416 77.676v9.5a7.917 7.917 0 0 1-7.916 7.916h-5.678c-1.41 0-2.117-1.705-1.12-2.703l14.715-14.713Z"
        fill="#FA9500"
      />
      <circle cx={85.167} cy={78.467} fill="#000" r={0.792} />
      <path
        d="M90.709 80.05h.791a.792.792 0 1 1 0 1.584h-.791v-1.583Z"
        fill="#BDA35E"
      />
      <path
        d="M81.209 95.092a9.5 9.5 0 0 1 9.5-9.5v1.583a7.917 7.917 0 0 1-7.917 7.917h-1.584Z"
        fill="#B44F00"
      />
      <path
        d="M80.417 95.092h-1.584v3.166h-.791a.792.792 0 1 0 0 1.584h4.75a.792.792 0 0 0 0-1.584h-2.375v-3.166Z"
        fill="#8B712C"
      />
      <path
        d="M76.458 95.092h-1.583v3.166h-.792a.792.792 0 1 0 0 1.584h4.75a.792.792 0 0 0 0-1.584h-2.375v-3.166Z"
        fill="#BDA35E"
      />
      <path
        d="M449.441 75.493c-2.992-2.993-8.108-.873-8.108 3.358v8.325a7.916 7.916 0 0 0 7.916 7.916h19.792l-19.6-19.6Z"
        fill="#367D39"
      />
      <path
        d="M451.625 77.676v9.5a7.916 7.916 0 0 0 7.916 7.916h5.678c1.41 0 2.117-1.705 1.119-2.703l-14.713-14.713Z"
        fill="#4CAF50"
      />
      <circle
        transform="matrix(-1 0 0 1 446.874 78.467)"
        fill="#000"
        r={0.792}
      />
      <path
        d="M441.333 80.05h-.792a.792.792 0 0 0 0 1.584h.792v-1.583Z"
        fill="#BDA35E"
      />
      <path
        d="M450.833 95.092c0-5.247-4.254-9.5-9.5-9.5v1.583a7.916 7.916 0 0 0 7.916 7.917h1.584Z"
        fill="#005F00"
      />
      <path
        d="M451.624 95.092h1.584v3.166h.791a.791.791 0 1 1 0 1.584h-4.75a.791.791 0 0 1 0-1.584h2.375v-3.166Z"
        fill="#8B712C"
      />
      <path
        d="M455.583 95.092h1.583v3.166h.792a.792.792 0 0 1 0 1.584h-4.75a.792.792 0 0 1 0-1.584h2.375v-3.166Z"
        fill="#BDA35E"
      />
      <g clipPath="url(#F)">
        <path
          d="M648.641 82.124c-2.205-2.205-5.975-.643-5.975 2.475v6.133a5.834 5.834 0 0 0 5.834 5.834h14.583l-14.442-14.442Z"
          fill="#367D39"
        />
        <path
          d="M650.25 83.732v7a5.833 5.833 0 0 0 5.833 5.834h4.184c1.039 0 1.559-1.257.824-1.992L650.25 83.732Z"
          fill="#4CAF50"
        />
        <circle
          transform="matrix(-1 0 0 1 646.75 84.316)"
          fill="#000"
          r={0.583}
        />
        <path
          d="M642.666 85.482h-.583a.584.584 0 0 0 0 1.167h.583v-1.167Z"
          fill="#BDA35E"
        />
        <path
          d="M649.666 96.565a7 7 0 0 0-7-7v1.167a5.833 5.833 0 0 0 5.833 5.833h1.167Z"
          fill="#005F00"
        />
        <path
          d="M650.25 96.565h1.166V98.9H652a.583.583 0 0 1 0 1.166h-3.5a.584.584 0 1 1 0-1.166h1.75v-2.334Z"
          fill="#8B712C"
        />
        <path
          d="M653.167 96.565h1.166V98.9h.584a.583.583 0 0 1 0 1.166h-3.5a.584.584 0 1 1 0-1.166h1.75v-2.334Z"
          fill="#BDA35E"
        />
      </g>
      <g clipPath="url(#G)">
        <path
          d="m46.996 32.696-1.01.584a.583.583 0 0 0 .583 1.01l1.01-.583-.583-1.01Z"
          fill="#BDA35E"
        />
        <path
          d="M52.858 36.716c-.263 2.1 2.179 3.437 3.806 2.086l1.5-1.247c3.907-3.244 3.937-9.23.063-12.512l-2.707-2.294a.583.583 0 0 0-.956.372l-1.706 13.595Z"
          fill="#B44F00"
        />
        <path
          d="M52.047 29.346c-.313-.255-.636-.509-1.016-.648-2.68-.986-5.437 1.743-3.804 4.633 1.745 3.09 3.722 1.697 5.284 4.285 1.563 2.587 5.234 2.44 6.878 1.842 1.645-.599 2.22-1.61 4.61-3.54 1.713-1.384 4.005-2.527 5.165-3.062.145-.067.09-.3-.07-.293-1.511.068-4.263.168-6.627.25-2.82.099-5.658-.434-8.108-1.835-.884-.506-1.785-1.202-2.312-1.632Z"
          fill="#E67C00"
        />
        <path
          d="M54.54 31.378c-2.318 2.662-.014 6.761 3.466 6.166a7.51 7.51 0 0 0 6.2-8.209l-.728-6.736a.583.583 0 0 0-1.02-.32l-7.918 9.099Z"
          fill="#FA9500"
        />
        <circle
          transform="scale(-1 1) rotate(20 -113.655 -123.287)"
          fill="#000"
          r={0.583}
        />
      </g>
      <g clipPath="url(#H)">
        <path
          d="m371.893 32.696 1.01.584a.582.582 0 1 1-.583 1.01l-1.011-.583.584-1.01Z"
          fill="#BDA35E"
        />
        <path
          d="M366.03 36.716c.264 2.1-2.178 3.437-3.806 2.086l-1.5-1.247c-3.906-3.244-3.936-9.23-.062-12.512l2.707-2.294a.584.584 0 0 1 .956.372l1.705 13.595Z"
          fill="#B44F00"
        />
        <path
          d="M366.841 29.346c.314-.255.637-.509 1.017-.648 2.68-.986 5.436 1.743 3.803 4.633-1.745 3.09-3.721 1.697-5.284 4.285-1.562 2.587-5.233 2.44-6.878 1.842-1.644-.599-2.219-1.61-4.609-3.54-1.714-1.384-4.006-2.527-5.166-3.062-.144-.067-.09-.3.07-.293 1.512.068 4.264.168 6.627.25 2.821.099 5.659-.434 8.108-1.835.885-.506 1.785-1.202 2.312-1.632Z"
          fill="#E67C00"
        />
        <path
          d="M364.349 31.378c2.317 2.662.013 6.761-3.466 6.166a7.51 7.51 0 0 1-6.2-8.209l.728-6.736a.583.583 0 0 1 1.02-.32l7.918 9.099Z"
          fill="#FA9500"
        />
        <circle
          cx={369.868}
          cy={31.437}
          transform="rotate(20 369.868 31.437)"
          fill="#000"
          r={0.583}
        />
      </g>
      <g clipPath="url(#I)">
        <path
          d="m209.854 26.698 1.371.792a.792.792 0 0 1-.792 1.371l-1.371-.792.792-1.37Z"
          fill="#BDA35E"
        />
        <path
          d="M201.898 32.153c.358 2.848-2.956 4.664-5.165 2.83l-2.037-1.691c-5.301-4.404-5.342-12.526-.084-16.982l3.674-3.113a.791.791 0 0 1 1.297.505l2.315 18.45Z"
          fill="#005F00"
        />
        <path
          d="M203.167 22.014c.312-.258.637-.507 1.01-.663 3.698-1.554 7.62 2.214 5.363 6.209-2.369 4.193-5.051 2.303-7.171 5.814-2.121 3.512-7.103 3.312-9.335 2.5-2.232-.812-3.011-2.184-6.255-4.804-2.326-1.878-5.436-3.429-7.011-4.156-.196-.09-.122-.406.094-.397 2.052.092 5.788.227 8.995.34 3.828.133 7.679-.59 11.004-2.491 1.3-.744 2.626-1.79 3.306-2.352Z"
          fill="#367D39"
        />
        <path
          d="M199.616 24.908c3.145 3.613.018 9.176-4.704 8.368a10.192 10.192 0 0 1-8.414-11.14l.987-9.142a.792.792 0 0 1 1.385-.435l10.746 12.349Z"
          fill="#4CAF50"
        />
        <circle
          cx={207.106}
          cy={24.989}
          transform="rotate(20 207.106 24.99)"
          fill="#000"
          r={0.792}
        />
      </g>
      <g clipPath="url(#J)">
        <path
          d="m591.85 28.698-1.372.792a.792.792 0 0 0 .792 1.371l1.371-.792-.791-1.37Z"
          fill="#BDA35E"
        />
        <path
          d="M599.805 34.153c-.357 2.848 2.957 4.664 5.165 2.83l2.037-1.691c5.301-4.404 5.342-12.526.084-16.982l-3.674-3.113a.791.791 0 0 0-1.297.505l-2.315 18.45Z"
          fill="#005F00"
        />
        <path
          d="M598.537 24.014c-.312-.258-.638-.507-1.011-.663-3.698-1.554-7.62 2.214-5.363 6.209 2.369 4.192 5.051 2.303 7.171 5.814 2.121 3.512 7.103 3.312 9.335 2.5 2.232-.812 3.011-2.184 6.255-4.804 2.326-1.878 5.437-3.429 7.011-4.156.196-.09.122-.406-.094-.397-2.052.092-5.787.227-8.995.34-3.827.133-7.679-.59-11.004-2.491-1.3-.744-2.626-1.79-3.305-2.352Z"
          fill="#367D39"
        />
        <path
          d="M602.087 26.908c-3.145 3.613-.018 9.176 4.704 8.368a10.192 10.192 0 0 0 8.414-11.14l-.987-9.142a.792.792 0 0 0-1.384-.435l-10.747 12.349Z"
          fill="#4CAF50"
        />
        <circle
          transform="scale(-1 1) rotate(20 -373.83 -1672.57)"
          fill="#000"
          r={0.792}
        />
      </g>
      <defs>
        <linearGradient
          id="a"
          x1={263.415}
          y1={44.092}
          x2={284.657}
          y2={92.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="b"
          x1={525.539}
          y1={56.092}
          x2={525.539}
          y2={96.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="c"
          x1={562.245}
          y1={60.092}
          x2={562.245}
          y2={100.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="d"
          x1={67.86}
          y1={60.092}
          x2={67.86}
          y2={100.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="e"
          x1={431.86}
          y1={60.092}
          x2={431.86}
          y2={100.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="f"
          x1={181.539}
          y1={60.092}
          x2={181.539}
          y2={100.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="g"
          x1={375.539}
          y1={56.092}
          x2={375.539}
          y2={96.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="h"
          x1={615.539}
          y1={56.092}
          x2={615.539}
          y2={96.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#88784F" />
        </linearGradient>
        <linearGradient
          id="i"
          x1={238.245}
          y1={60.092}
          x2={238.245}
          y2={100.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="j"
          x1={101.845}
          y1={60.092}
          x2={101.845}
          y2={100.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="k"
          x1={338.845}
          y1={60.092}
          x2={338.845}
          y2={100.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="l"
          x1={468.845}
          y1={60.092}
          x2={468.845}
          y2={100.092}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="m"
          x1={14.196}
          y1={32.092}
          x2={30.906}
          y2={84.818}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="p"
          x1={142.196}
          y1={32.092}
          x2={158.906}
          y2={84.818}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="s"
          x1={637.196}
          y1={32.092}
          x2={653.906}
          y2={84.818}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="v"
          x1={553.415}
          y1={44.092}
          x2={574.657}
          y2={92.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="w"
          x1={465.196}
          y1={32.092}
          x2={481.906}
          y2={84.818}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="z"
          x1={387.415}
          y1={44.092}
          x2={408.657}
          y2={92.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="A"
          x1={293.196}
          y1={32.092}
          x2={309.906}
          y2={84.818}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="D"
          x1={209.415}
          y1={44.092}
          x2={230.657}
          y2={92.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <linearGradient
          id="E"
          x1={113.415}
          y1={44.092}
          x2={134.657}
          y2={92.862}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6966D" />
          <stop offset={1} stopColor="#827249" />
        </linearGradient>
        <filter
          id="n"
          x={38}
          y={60.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <filter
          id="o"
          x={15}
          y={57.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <filter
          id="q"
          x={166}
          y={60.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <filter
          id="r"
          x={143}
          y={57.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <filter
          id="t"
          x={661}
          y={60.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <filter
          id="u"
          x={638}
          y={57.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <filter
          id="x"
          x={489}
          y={60.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <filter
          id="y"
          x={466}
          y={57.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <filter
          id="B"
          x={317}
          y={60.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <filter
          id="C"
          x={294}
          y={57.092}
          width={8}
          height={9}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3877_82245"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3877_82245"
            result="shape"
          />
        </filter>
        <clipPath id="F">
          <path
            fill="#fff"
            transform="matrix(-1 0 0 1 666 72.092)"
            d="M0 0h28v28H0z"
          />
        </clipPath>
        <clipPath id="G">
          <path
            fill="#fff"
            transform="scale(-1 1) rotate(20 -69.78 -178.657)"
            d="M0 0h28v28H0z"
          />
        </clipPath>
        <clipPath id="H">
          <path
            fill="#fff"
            transform="rotate(20 139.664 1009.16)"
            d="M0 0h28v28H0z"
          />
        </clipPath>
        <clipPath id="I">
          <path
            fill="#fff"
            transform="rotate(20 92.238 524.628)"
            d="M0 0h38v38H0z"
          />
        </clipPath>
        <clipPath id="J">
          <path
            fill="#fff"
            transform="scale(-1 1) rotate(20 -314.285 -1747.715)"
            d="M0 0h38v38H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
