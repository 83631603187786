import { Card, Col, Popconfirm, Row, Space } from 'antd';

import { CreditCardType } from '../../services/apiService';
import { DeleteIcon } from '../Icons/DeleteIcon/DeleteIcon';

import styles from './index.module.scss';

export interface CreditCardProps {
  cardDetail: CreditCardType;
  handleDelete: (id: string) => void;
}

export function CreditCard({ cardDetail, handleDelete }: CreditCardProps) {
  return (
    <Row className={styles.card}>
      <Card className={styles.cardMain} key={cardDetail.id}>
        <Row justify="space-between" className={styles.cardDetailContent}>
          <Col>
            <p>{cardDetail.card.brand + ' ' + cardDetail.card.last4} </p>
            <p>{cardDetail.billing_details.name}</p>
          </Col>
          <Col>
            <p>
              Expires
              {cardDetail.card.exp_month + '/' + cardDetail.card.exp_year}
            </p>
          </Col>
        </Row>
      </Card>
      <Space align="center">
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => {
            handleDelete(cardDetail.id);
          }}
          okText="Yes"
          cancelText="No"
        >
          <div className={styles.deleteIcon}>
            <DeleteIcon />
          </div>
        </Popconfirm>
      </Space>
    </Row>
  );
}
