export function Tree60(props: any) {
  return (
    <svg
      width={717}
      height={67}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M202.5 10C202.5 10 195.5 17.9249 195.5 24C195.5 30.0751 198.5 32 202.5 32V10Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M202.5 32C206.5 32 209.5 30.0751 209.5 24C209.5 17.9249 202.5 10 202.5 10V32Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M181.95 26.2221C181.95 26.2221 182.604 36.7756 186.899 41.0714C191.195 45.3671 194.678 44.6069 197.506 41.7785L181.95 26.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M197.506 41.778C200.334 38.9496 201.095 35.4672 196.799 31.1714C192.503 26.8757 181.95 26.2217 181.95 26.2217L197.506 41.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M222.45 26.9497C222.45 26.9497 211.896 27.6037 207.601 31.8995C203.305 36.1953 204.065 39.6777 206.893 42.5061L222.45 26.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M206.893 42.5065C209.722 45.335 213.204 46.0952 217.5 41.7994C221.796 37.5037 222.45 26.9502 222.45 26.9502L206.893 42.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M202.5 25L203.427 43.5398L211.914 37.4142L203.626 47.5202L204.5 65H200.5L201.379 47.4316L193.163 37.4142L201.576 43.4861L202.5 25Z"
        fill="#BDA35E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M416.5 10C416.5 10 409.5 17.9249 409.5 24C409.5 30.0751 412.5 32 416.5 32V10Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M416.5 32C420.5 32 423.5 30.0751 423.5 24C423.5 17.9249 416.5 10 416.5 10V32Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M395.95 26.2221C395.95 26.2221 396.604 36.7756 400.899 41.0714C405.195 45.3671 408.678 44.6069 411.506 41.7785L395.95 26.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M411.506 41.778C414.334 38.9496 415.095 35.4672 410.799 31.1714C406.503 26.8757 395.95 26.2217 395.95 26.2217L411.506 41.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M436.45 26.9497C436.45 26.9497 425.896 27.6037 421.601 31.8995C417.305 36.1953 418.065 39.6777 420.893 42.5061L436.45 26.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M420.893 42.5065C423.722 45.335 427.204 46.0952 431.5 41.7994C435.796 37.5037 436.45 26.9502 436.45 26.9502L420.893 42.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M416.5 25L417.427 43.5398L425.914 37.4142L417.626 47.5202L418.5 65H414.5L415.379 47.4316L407.163 37.4142L415.576 43.4861L416.5 25Z"
        fill="#BDA35E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M505.5 10C505.5 10 498.5 17.9249 498.5 24C498.5 30.0751 501.5 32 505.5 32V10Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M505.5 32C509.5 32 512.5 30.0751 512.5 24C512.5 17.9249 505.5 10 505.5 10V32Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M484.95 26.2221C484.95 26.2221 485.604 36.7756 489.899 41.0714C494.195 45.3671 497.678 44.6069 500.506 41.7785L484.95 26.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M500.506 41.778C503.334 38.9496 504.095 35.4672 499.799 31.1714C495.503 26.8757 484.95 26.2217 484.95 26.2217L500.506 41.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M525.45 26.9497C525.45 26.9497 514.896 27.6037 510.601 31.8995C506.305 36.1953 507.065 39.6777 509.893 42.5061L525.45 26.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M509.893 42.5065C512.722 45.335 516.204 46.0952 520.5 41.7994C524.796 37.5037 525.45 26.9502 525.45 26.9502L509.893 42.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M505.5 25L506.427 43.5398L514.914 37.4142L506.626 47.5202L507.5 65H503.5L504.379 47.4316L496.163 37.4142L504.576 43.4861L505.5 25Z"
        fill="#BDA35E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M277.5 10C277.5 10 270.5 17.9249 270.5 24C270.5 30.0751 273.5 32 277.5 32V10Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M277.5 32C281.5 32 284.5 30.0751 284.5 24C284.5 17.9249 277.5 10 277.5 10V32Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M256.95 26.2221C256.95 26.2221 257.604 36.7756 261.899 41.0714C266.195 45.3671 269.678 44.6069 272.506 41.7785L256.95 26.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M272.506 41.778C275.334 38.9496 276.095 35.4672 271.799 31.1714C267.503 26.8757 256.95 26.2217 256.95 26.2217L272.506 41.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M297.45 26.9497C297.45 26.9497 286.896 27.6037 282.601 31.8995C278.305 36.1953 279.065 39.6777 281.893 42.5061L297.45 26.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M281.893 42.5065C284.722 45.335 288.204 46.0952 292.5 41.7994C296.796 37.5037 297.45 26.9502 297.45 26.9502L281.893 42.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M277.5 25L278.427 43.5398L286.914 37.4142L278.626 47.5202L279.5 65H275.5L276.379 47.4316L268.163 37.4142L276.576 43.4861L277.5 25Z"
        fill="#BDA35E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5 10C25.5 10 18.5 17.9249 18.5 24C18.5 30.0751 21.5 32 25.5 32V10Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5 32C29.5 32 32.5 30.0751 32.5 24C32.5 17.9249 25.5 10 25.5 10V32Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.94975 26.2221C4.94975 26.2221 5.60373 36.7756 9.89949 41.0714C14.1953 45.3671 17.6777 44.6069 20.5061 41.7785L4.94975 26.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5061 41.778C23.3345 38.9496 24.0947 35.4672 19.7989 31.1714C15.5032 26.8757 4.94971 26.2217 4.94971 26.2217L20.5061 41.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M45.4497 26.9497C45.4497 26.9497 34.8963 27.6037 30.6005 31.8995C26.3047 36.1953 27.065 39.6777 29.8934 42.5061L45.4497 26.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.8934 42.5065C32.7218 45.335 36.2042 46.0952 40.5 41.7994C44.7957 37.5037 45.4497 26.9502 45.4497 26.9502L29.8934 42.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5002 25L26.4271 43.5398L34.9144 37.4142L26.6262 47.5202L27.5002 65H23.5002L24.3786 47.4316L16.1631 37.4142L24.5758 43.4861L25.5002 25Z"
        fill="#BDA35E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123.5 0C123.5 0 116.5 7.92487 116.5 14C116.5 20.0751 119.5 22 123.5 22V0Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123.5 22C127.5 22 130.5 20.0751 130.5 14C130.5 7.92487 123.5 0 123.5 0V22Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M102.95 19.2221C102.95 19.2221 103.604 29.7756 107.899 34.0714C112.195 38.3671 115.678 37.6069 118.506 34.7785L102.95 19.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M118.506 34.778C121.334 31.9496 122.095 28.4672 117.799 24.1714C113.503 19.8757 102.95 19.2217 102.95 19.2217L118.506 34.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M102.95 36.9497C102.95 36.9497 103.604 47.5031 107.899 51.7989C112.195 56.0947 115.678 55.3344 118.506 52.506L102.95 36.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M118.506 52.5056C121.334 49.6771 122.095 46.1947 117.799 41.899C113.503 37.6032 102.95 36.9492 102.95 36.9492L118.506 52.5056Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M143.45 17.9497C143.45 17.9497 132.896 18.6037 128.601 22.8995C124.305 27.1953 125.065 30.6777 127.893 33.5061L143.45 17.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M127.893 33.5065C130.722 36.335 134.204 37.0952 138.5 32.7994C142.796 28.5037 143.45 17.9502 143.45 17.9502L127.893 33.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M143.45 34.9497C143.45 34.9497 132.896 35.6037 128.601 39.8995C124.305 44.1953 125.065 47.6777 127.893 50.5061L143.45 34.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M127.893 50.5065C130.722 53.335 134.204 54.0952 138.5 49.7994C142.796 45.5037 143.45 34.9502 143.45 34.9502L127.893 50.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M123.5 15L124.475 34.5054L132.915 28.4142L124.673 38.4634L125.296 50.9126L132.914 45.4142L125.474 54.4854L126 65H121L121.402 56.9679L114.163 48.1422L121.575 53.4918L122.237 40.2595L114.163 30.4142L122.431 36.3818L123.5 15Z"
        fill="url(#paint0_linear_3877_80894)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M322.5 0C322.5 0 315.5 7.92487 315.5 14C315.5 20.0751 318.5 22 322.5 22V0Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M322.5 22C326.5 22 329.5 20.0751 329.5 14C329.5 7.92487 322.5 0 322.5 0V22Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M301.95 19.2221C301.95 19.2221 302.604 29.7756 306.899 34.0714C311.195 38.3671 314.678 37.6069 317.506 34.7785L301.95 19.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M317.506 34.778C320.334 31.9496 321.095 28.4672 316.799 24.1714C312.503 19.8757 301.95 19.2217 301.95 19.2217L317.506 34.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M301.95 36.9497C301.95 36.9497 302.604 47.5031 306.899 51.7989C311.195 56.0947 314.678 55.3344 317.506 52.506L301.95 36.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M317.506 52.5056C320.334 49.6771 321.095 46.1947 316.799 41.899C312.503 37.6032 301.95 36.9492 301.95 36.9492L317.506 52.5056Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M342.45 17.9497C342.45 17.9497 331.896 18.6037 327.601 22.8995C323.305 27.1953 324.065 30.6777 326.893 33.5061L342.45 17.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M326.893 33.5065C329.722 36.335 333.204 37.0952 337.5 32.7994C341.796 28.5037 342.45 17.9502 342.45 17.9502L326.893 33.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M342.45 34.9497C342.45 34.9497 331.896 35.6037 327.601 39.8995C323.305 44.1953 324.065 47.6777 326.893 50.5061L342.45 34.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M326.893 50.5065C329.722 53.335 333.204 54.0952 337.5 49.7994C341.796 45.5037 342.45 34.9502 342.45 34.9502L326.893 50.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M322.5 15L323.475 34.5054L331.915 28.4142L323.673 38.4634L324.296 50.9126L331.914 45.4142L324.474 54.4854L325 65H320L320.402 56.9679L313.163 48.1422L320.575 53.4918L321.237 40.2595L313.163 30.4142L321.431 36.3818L322.5 15Z"
        fill="url(#paint1_linear_3877_80894)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M634.5 0C634.5 0 627.5 7.92487 627.5 14C627.5 20.0751 630.5 22 634.5 22V0Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M634.5 22C638.5 22 641.5 20.0751 641.5 14C641.5 7.92487 634.5 0 634.5 0V22Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M613.95 19.2221C613.95 19.2221 614.604 29.7756 618.899 34.0714C623.195 38.3671 626.678 37.6069 629.506 34.7785L613.95 19.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M629.506 34.778C632.334 31.9496 633.095 28.4672 628.799 24.1714C624.503 19.8757 613.95 19.2217 613.95 19.2217L629.506 34.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M613.95 36.9497C613.95 36.9497 614.604 47.5031 618.899 51.7989C623.195 56.0947 626.678 55.3344 629.506 52.506L613.95 36.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M629.506 52.5056C632.334 49.6771 633.095 46.1947 628.799 41.899C624.503 37.6032 613.95 36.9492 613.95 36.9492L629.506 52.5056Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M654.45 17.9497C654.45 17.9497 643.896 18.6037 639.601 22.8995C635.305 27.1953 636.065 30.6777 638.893 33.5061L654.45 17.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M638.893 33.5065C641.722 36.335 645.204 37.0952 649.5 32.7994C653.796 28.5037 654.45 17.9502 654.45 17.9502L638.893 33.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M654.45 34.9497C654.45 34.9497 643.896 35.6037 639.601 39.8995C635.305 44.1953 636.065 47.6777 638.893 50.5061L654.45 34.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M638.893 50.5065C641.722 53.335 645.204 54.0952 649.5 49.7994C653.796 45.5037 654.45 34.9502 654.45 34.9502L638.893 50.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M634.5 15L635.475 34.5054L643.915 28.4142L635.673 38.4634L636.296 50.9126L643.914 45.4142L636.474 54.4854L637 65H632L632.402 56.9679L625.163 48.1422L632.575 53.4918L633.237 40.2595L625.163 30.4142L633.431 36.3818L634.5 15Z"
        fill="url(#paint2_linear_3877_80894)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M684.5 0C684.5 0 677.5 7.92487 677.5 14C677.5 20.0751 680.5 22 684.5 22V0Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M684.5 22C688.5 22 691.5 20.0751 691.5 14C691.5 7.92487 684.5 0 684.5 0V22Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M663.95 19.2221C663.95 19.2221 664.604 29.7756 668.899 34.0714C673.195 38.3671 676.678 37.6069 679.506 34.7785L663.95 19.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M679.506 34.778C682.334 31.9496 683.095 28.4672 678.799 24.1714C674.503 19.8757 663.95 19.2217 663.95 19.2217L679.506 34.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M663.95 36.9497C663.95 36.9497 664.604 47.5031 668.899 51.7989C673.195 56.0947 676.678 55.3344 679.506 52.506L663.95 36.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M679.506 52.5056C682.334 49.6771 683.095 46.1947 678.799 41.899C674.503 37.6032 663.95 36.9492 663.95 36.9492L679.506 52.5056Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M704.45 17.9497C704.45 17.9497 693.896 18.6037 689.601 22.8995C685.305 27.1953 686.065 30.6777 688.893 33.5061L704.45 17.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M688.893 33.5065C691.722 36.335 695.204 37.0952 699.5 32.7994C703.796 28.5037 704.45 17.9502 704.45 17.9502L688.893 33.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M704.45 34.9497C704.45 34.9497 693.896 35.6037 689.601 39.8995C685.305 44.1953 686.065 47.6777 688.893 50.5061L704.45 34.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M688.893 50.5065C691.722 53.335 695.204 54.0952 699.5 49.7994C703.796 45.5037 704.45 34.9502 704.45 34.9502L688.893 50.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M684.5 15L685.475 34.5054L693.915 28.4142L685.673 38.4634L686.296 50.9126L693.914 45.4142L686.474 54.4854L687 65H682L682.402 56.9679L675.163 48.1422L682.575 53.4918L683.237 40.2595L675.163 30.4142L683.431 36.3818L684.5 15Z"
        fill="url(#paint3_linear_3877_80894)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M450.5 0C450.5 0 443.5 7.92487 443.5 14C443.5 20.0751 446.5 22 450.5 22V0Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M450.5 22C454.5 22 457.5 20.0751 457.5 14C457.5 7.92487 450.5 0 450.5 0V22Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M429.95 19.2221C429.95 19.2221 430.604 29.7756 434.899 34.0714C439.195 38.3671 442.678 37.6069 445.506 34.7785L429.95 19.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M445.506 34.778C448.334 31.9496 449.095 28.4672 444.799 24.1714C440.503 19.8757 429.95 19.2217 429.95 19.2217L445.506 34.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M429.95 36.9497C429.95 36.9497 430.604 47.5031 434.899 51.7989C439.195 56.0947 442.678 55.3344 445.506 52.506L429.95 36.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M445.506 52.5056C448.334 49.6771 449.095 46.1947 444.799 41.899C440.503 37.6032 429.95 36.9492 429.95 36.9492L445.506 52.5056Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M470.45 17.9497C470.45 17.9497 459.896 18.6037 455.601 22.8995C451.305 27.1953 452.065 30.6777 454.893 33.5061L470.45 17.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M454.893 33.5065C457.722 36.335 461.204 37.0952 465.5 32.7994C469.796 28.5037 470.45 17.9502 470.45 17.9502L454.893 33.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M470.45 34.9497C470.45 34.9497 459.896 35.6037 455.601 39.8995C451.305 44.1953 452.065 47.6777 454.893 50.5061L470.45 34.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M454.893 50.5065C457.722 53.335 461.204 54.0952 465.5 49.7994C469.796 45.5037 470.45 34.9502 470.45 34.9502L454.893 50.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M450.5 15L451.475 34.5054L459.915 28.4142L451.673 38.4634L452.296 50.9126L459.914 45.4142L452.474 54.4854L453 65H448L448.402 56.9679L441.163 48.1422L448.575 53.4918L449.237 40.2595L441.163 30.4142L449.431 36.3818L450.5 15Z"
        fill="url(#paint4_linear_3877_80894)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M416.5 10C416.5 10 409.5 17.9249 409.5 24C409.5 30.0751 412.5 32 416.5 32V10Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M416.5 32C420.5 32 423.5 30.0751 423.5 24C423.5 17.9249 416.5 10 416.5 10V32Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M395.95 26.2221C395.95 26.2221 396.604 36.7756 400.899 41.0714C405.195 45.3671 408.678 44.6069 411.506 41.7785L395.95 26.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M411.506 41.778C414.334 38.9496 415.095 35.4672 410.799 31.1714C406.503 26.8757 395.95 26.2217 395.95 26.2217L411.506 41.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M436.45 26.9497C436.45 26.9497 425.896 27.6037 421.601 31.8995C417.305 36.1953 418.065 39.6777 420.893 42.5061L436.45 26.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M420.893 42.5065C423.722 45.335 427.204 46.0952 431.5 41.7994C435.796 37.5037 436.45 26.9502 436.45 26.9502L420.893 42.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M416.5 25L417.427 43.5398L425.914 37.4142L417.626 47.5202L418.5 65H414.5L415.379 47.4316L407.163 37.4142L415.576 43.4861L416.5 25Z"
        fill="#BDA35E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M450.5 0C450.5 0 443.5 7.92487 443.5 14C443.5 20.0751 446.5 22 450.5 22V0Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M450.5 22C454.5 22 457.5 20.0751 457.5 14C457.5 7.92487 450.5 0 450.5 0V22Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M429.95 19.2221C429.95 19.2221 430.604 29.7756 434.899 34.0714C439.195 38.3671 442.678 37.6069 445.506 34.7785L429.95 19.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M445.506 34.778C448.334 31.9496 449.095 28.4672 444.799 24.1714C440.503 19.8757 429.95 19.2217 429.95 19.2217L445.506 34.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M429.95 36.9497C429.95 36.9497 430.604 47.5031 434.899 51.7989C439.195 56.0947 442.678 55.3344 445.506 52.506L429.95 36.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M445.506 52.5056C448.334 49.6771 449.095 46.1947 444.799 41.899C440.503 37.6032 429.95 36.9492 429.95 36.9492L445.506 52.5056Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M470.45 17.9497C470.45 17.9497 459.896 18.6037 455.601 22.8995C451.305 27.1953 452.065 30.6777 454.893 33.5061L470.45 17.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M454.893 33.5065C457.722 36.335 461.204 37.0952 465.5 32.7994C469.796 28.5037 470.45 17.9502 470.45 17.9502L454.893 33.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M470.45 34.9497C470.45 34.9497 459.896 35.6037 455.601 39.8995C451.305 44.1953 452.065 47.6777 454.893 50.5061L470.45 34.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M454.893 50.5065C457.722 53.335 461.204 54.0952 465.5 49.7994C469.796 45.5037 470.45 34.9502 470.45 34.9502L454.893 50.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M450.5 15L451.475 34.5054L459.915 28.4142L451.673 38.4634L452.296 50.9126L459.914 45.4142L452.474 54.4854L453 65H448L448.402 56.9679L441.163 48.1422L448.575 53.4918L449.237 40.2595L441.163 30.4142L449.431 36.3818L450.5 15Z"
        fill="url(#paint5_linear_3877_80894)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M546.5 0C546.5 0 539.5 7.92487 539.5 14C539.5 20.0751 542.5 22 546.5 22V0Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M546.5 22C550.5 22 553.5 20.0751 553.5 14C553.5 7.92487 546.5 0 546.5 0V22Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M525.95 19.2221C525.95 19.2221 526.604 29.7756 530.899 34.0714C535.195 38.3671 538.678 37.6069 541.506 34.7785L525.95 19.2221Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M541.506 34.778C544.334 31.9496 545.095 28.4672 540.799 24.1714C536.503 19.8757 525.95 19.2217 525.95 19.2217L541.506 34.778Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M525.95 36.9497C525.95 36.9497 526.604 47.5031 530.899 51.7989C535.195 56.0947 538.678 55.3344 541.506 52.506L525.95 36.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M541.506 52.5056C544.334 49.6771 545.095 46.1947 540.799 41.899C536.503 37.6032 525.95 36.9492 525.95 36.9492L541.506 52.5056Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M566.45 17.9497C566.45 17.9497 555.896 18.6037 551.601 22.8995C547.305 27.1953 548.065 30.6777 550.893 33.5061L566.45 17.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M550.893 33.5065C553.722 36.335 557.204 37.0952 561.5 32.7994C565.796 28.5037 566.45 17.9502 566.45 17.9502L550.893 33.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M566.45 34.9497C566.45 34.9497 555.896 35.6037 551.601 39.8995C547.305 44.1953 548.065 47.6777 550.893 50.5061L566.45 34.9497Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M550.893 50.5065C553.722 53.335 557.204 54.0952 561.5 49.7994C565.796 45.5037 566.45 34.9502 566.45 34.9502L550.893 50.5065Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M546.5 15L547.475 34.5054L555.915 28.4142L547.673 38.4634L548.296 50.9126L555.914 45.4142L548.474 54.4854L549 65H544L544.402 56.9679L537.163 48.1422L544.575 53.4918L545.237 40.2595L537.163 30.4142L545.431 36.3818L546.5 15Z"
        fill="url(#paint6_linear_3877_80894)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M78.6536 36.8154C78.6713 36.7987 78.689 36.782 78.7068 36.7654C78.7246 36.7488 78.7425 36.7323 78.7604 36.7158L78.6536 36.8154ZM74.0649 45.2924C74.1213 45.2833 79.5156 44.4073 82.8946 41.2563C86.2736 38.1053 87.5239 32.7853 87.5369 32.7296L74.0649 45.2924ZM87.537 32.7293L87.5369 32.7292C87.5369 32.7292 87.537 32.7292 87.537 32.7292C87.537 32.7292 87.537 32.7292 87.537 32.7293Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.0643 45.2926C74.0641 45.2926 74.064 45.2927 74.064 45.2927C74.064 45.2927 75.3095 39.9332 78.7064 36.7656C82.1032 33.598 87.5365 32.7293 87.5365 32.7293C87.5365 32.7293 87.5365 32.7295 87.5364 32.7297L74.0643 45.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M87.1286 44.1108L87.1287 44.1108L74.0647 56.2931C74.0647 56.2932 74.0647 56.2932 74.0647 56.2932C74.0647 56.2932 79.3333 55.4509 82.6271 52.3793C85.921 49.3077 87.1288 44.1107 87.1288 44.1107C87.1288 44.1107 87.1287 44.1107 87.1286 44.1108Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.0646 56.2926C74.0643 56.2926 74.0642 56.2927 74.0642 56.2927C74.0642 56.2927 75.272 51.0956 78.5658 48.0241C81.8597 44.9525 87.1283 44.1102 87.1283 44.1102C87.1283 44.1102 87.1283 44.1103 87.1282 44.1105L74.0646 56.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.8408 38.1094V14.1094C73.8408 14.1094 77.8408 20.0583 77.8408 26.1094C77.8408 32.1605 73.8408 38.1094 73.8408 38.1094Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.8408 38.1094C73.8408 38.1094 69.8408 32.1605 69.8408 26.1094C69.8408 20.0583 73.8408 14.1094 73.8408 14.1094V38.1094Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60.1003 29.69C60.114 29.7483 61.3684 35.0834 64.7574 38.2437C68.1463 41.404 73.556 42.2832 73.6151 42.2927L60.1003 29.69ZM69.0141 33.7909C68.9956 33.7734 68.9771 33.756 68.9584 33.7386C68.9398 33.7212 68.9211 33.7039 68.9024 33.6867L69.0141 33.7909Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.6157 42.2935C73.6159 42.2935 73.616 42.2935 73.616 42.2935C73.616 42.2935 72.3665 36.917 68.9589 33.7393C65.5513 30.5617 60.1007 29.6903 60.1007 29.6903C60.1007 29.6903 60.1007 29.6904 60.1008 29.6906L73.6157 42.2935Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.3995 44.5145C69.3869 44.5026 69.3743 44.4908 69.3617 44.4791C69.3491 44.4673 69.3365 44.4556 69.3238 44.4439L69.3995 44.5145ZM73.6159 52.2935C73.5758 52.2871 68.6243 51.4857 65.5239 48.5946C62.4236 45.7035 61.2788 40.8199 61.2695 40.7804L73.6159 52.2935ZM61.2695 40.7802L61.2696 40.7801C61.2695 40.7801 61.2695 40.7801 61.2695 40.7801C61.2695 40.7801 61.2695 40.7802 61.2695 40.7802Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M73.616 52.2935C73.6162 52.2936 73.6163 52.2936 73.6163 52.2936C73.6163 52.2936 72.4748 47.382 69.3619 44.4791C66.2489 41.5762 61.2697 40.7801 61.2697 40.7801C61.2697 40.7801 61.2697 40.7802 61.2697 40.7805L73.616 52.2935Z"
        fill="#183128"
      />
      <path d="M73.8447 25L71.8447 65H75.8447L73.8447 25Z" fill="#BDA35E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M163.654 36.8154C163.671 36.7987 163.689 36.782 163.707 36.7654C163.725 36.7488 163.742 36.7323 163.76 36.7158L163.654 36.8154ZM159.065 45.2924C159.121 45.2833 164.516 44.4073 167.895 41.2563C171.274 38.1053 172.524 32.7853 172.537 32.7296L159.065 45.2924ZM172.537 32.7293L172.537 32.7292C172.537 32.7292 172.537 32.7292 172.537 32.7292C172.537 32.7292 172.537 32.7292 172.537 32.7293Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M159.064 45.2926C159.064 45.2926 159.064 45.2927 159.064 45.2927C159.064 45.2927 160.31 39.9332 163.706 36.7656C167.103 33.598 172.536 32.7293 172.536 32.7293C172.536 32.7293 172.536 32.7295 172.536 32.7297L159.064 45.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M172.129 44.1108L172.129 44.1108L159.065 56.2931C159.065 56.2932 159.065 56.2932 159.065 56.2932C159.065 56.2932 164.333 55.4509 167.627 52.3793C170.921 49.3077 172.129 44.1107 172.129 44.1107C172.129 44.1107 172.129 44.1107 172.129 44.1108Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M159.065 56.2926C159.064 56.2926 159.064 56.2927 159.064 56.2927C159.064 56.2927 160.272 51.0956 163.566 48.0241C166.86 44.9525 172.128 44.1102 172.128 44.1102C172.128 44.1102 172.128 44.1103 172.128 44.1105L159.065 56.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M158.841 38.1094V14.1094C158.841 14.1094 162.841 20.0583 162.841 26.1094C162.841 32.1605 158.841 38.1094 158.841 38.1094Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M158.841 38.1094C158.841 38.1094 154.841 32.1605 154.841 26.1094C154.841 20.0583 158.841 14.1094 158.841 14.1094V38.1094Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M145.1 29.69C145.114 29.7483 146.368 35.0834 149.757 38.2437C153.146 41.404 158.556 42.2832 158.615 42.2927L145.1 29.69ZM154.014 33.7909C153.996 33.7734 153.977 33.756 153.958 33.7386C153.94 33.7212 153.921 33.7039 153.902 33.6867L154.014 33.7909Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M158.616 42.2935C158.616 42.2935 158.616 42.2935 158.616 42.2935C158.616 42.2935 157.367 36.917 153.959 33.7393C150.551 30.5617 145.101 29.6903 145.101 29.6903C145.101 29.6903 145.101 29.6904 145.101 29.6906L158.616 42.2935Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M154.399 44.5145C154.387 44.5026 154.374 44.4908 154.362 44.4791C154.349 44.4673 154.336 44.4556 154.324 44.4439L154.399 44.5145ZM158.616 52.2935C158.576 52.2871 153.624 51.4857 150.524 48.5946C147.424 45.7035 146.279 40.8199 146.27 40.7804L158.616 52.2935ZM146.27 40.7802L146.27 40.7801C146.27 40.7801 146.269 40.7801 146.269 40.7801C146.269 40.7801 146.269 40.7802 146.27 40.7802Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M158.616 52.2935C158.616 52.2936 158.616 52.2936 158.616 52.2936C158.616 52.2936 157.475 47.382 154.362 44.4791C151.249 41.5762 146.27 40.7801 146.27 40.7801C146.27 40.7801 146.27 40.7802 146.27 40.7805L158.616 52.2935Z"
        fill="#183128"
      />
      <path d="M158.845 25L156.845 65H160.845L158.845 25Z" fill="#BDA35E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M378.654 36.8154C378.671 36.7987 378.689 36.782 378.707 36.7654C378.725 36.7488 378.742 36.7323 378.76 36.7158L378.654 36.8154ZM374.065 45.2924C374.121 45.2833 379.516 44.4073 382.895 41.2563C386.274 38.1053 387.524 32.7853 387.537 32.7296L374.065 45.2924ZM387.537 32.7293L387.537 32.7292C387.537 32.7292 387.537 32.7292 387.537 32.7292C387.537 32.7292 387.537 32.7292 387.537 32.7293Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M374.064 45.2926C374.064 45.2926 374.064 45.2927 374.064 45.2927C374.064 45.2927 375.31 39.9332 378.706 36.7656C382.103 33.598 387.536 32.7293 387.536 32.7293C387.536 32.7293 387.536 32.7295 387.536 32.7297L374.064 45.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M387.129 44.1108L387.129 44.1108L374.065 56.2931C374.065 56.2932 374.065 56.2932 374.065 56.2932C374.065 56.2932 379.333 55.4509 382.627 52.3793C385.921 49.3077 387.129 44.1107 387.129 44.1107C387.129 44.1107 387.129 44.1107 387.129 44.1108Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M374.065 56.2926C374.064 56.2926 374.064 56.2927 374.064 56.2927C374.064 56.2927 375.272 51.0956 378.566 48.0241C381.86 44.9525 387.128 44.1102 387.128 44.1102C387.128 44.1102 387.128 44.1103 387.128 44.1105L374.065 56.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M373.841 38.1094V14.1094C373.841 14.1094 377.841 20.0583 377.841 26.1094C377.841 32.1605 373.841 38.1094 373.841 38.1094Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M373.841 38.1094C373.841 38.1094 369.841 32.1605 369.841 26.1094C369.841 20.0583 373.841 14.1094 373.841 14.1094V38.1094Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M360.1 29.69C360.114 29.7483 361.368 35.0834 364.757 38.2437C368.146 41.404 373.556 42.2832 373.615 42.2927L360.1 29.69ZM369.014 33.7909C368.996 33.7734 368.977 33.756 368.958 33.7386C368.94 33.7212 368.921 33.7039 368.902 33.6867L369.014 33.7909Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M373.616 42.2935C373.616 42.2935 373.616 42.2935 373.616 42.2935C373.616 42.2935 372.367 36.917 368.959 33.7393C365.551 30.5617 360.101 29.6903 360.101 29.6903C360.101 29.6903 360.101 29.6904 360.101 29.6906L373.616 42.2935Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M369.399 44.5145C369.387 44.5026 369.374 44.4908 369.362 44.4791C369.349 44.4673 369.336 44.4556 369.324 44.4439L369.399 44.5145ZM373.616 52.2935C373.576 52.2871 368.624 51.4857 365.524 48.5946C362.424 45.7035 361.279 40.8199 361.27 40.7804L373.616 52.2935ZM361.27 40.7802L361.27 40.7801C361.27 40.7801 361.269 40.7801 361.269 40.7801C361.269 40.7801 361.269 40.7802 361.27 40.7802Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M373.616 52.2935C373.616 52.2936 373.616 52.2936 373.616 52.2936C373.616 52.2936 372.475 47.382 369.362 44.4791C366.249 41.5762 361.27 40.7801 361.27 40.7801C361.27 40.7801 361.27 40.7802 361.27 40.7805L373.616 52.2935Z"
        fill="#183128"
      />
      <path d="M373.845 25L371.845 65H375.845L373.845 25Z" fill="#BDA35E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M596.654 36.8154C596.671 36.7987 596.689 36.782 596.707 36.7654C596.725 36.7488 596.742 36.7323 596.76 36.7158L596.654 36.8154ZM592.065 45.2924C592.121 45.2833 597.516 44.4073 600.895 41.2563C604.274 38.1053 605.524 32.7853 605.537 32.7296L592.065 45.2924ZM605.537 32.7293L605.537 32.7292C605.537 32.7292 605.537 32.7292 605.537 32.7292C605.537 32.7292 605.537 32.7292 605.537 32.7293Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M592.064 45.2926C592.064 45.2926 592.064 45.2927 592.064 45.2927C592.064 45.2927 593.31 39.9332 596.706 36.7656C600.103 33.598 605.536 32.7293 605.536 32.7293C605.536 32.7293 605.536 32.7295 605.536 32.7297L592.064 45.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M605.129 44.1108L605.129 44.1108L592.065 56.2931C592.065 56.2932 592.065 56.2932 592.065 56.2932C592.065 56.2932 597.333 55.4509 600.627 52.3793C603.921 49.3077 605.129 44.1107 605.129 44.1107C605.129 44.1107 605.129 44.1107 605.129 44.1108Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M592.065 56.2926C592.064 56.2926 592.064 56.2927 592.064 56.2927C592.064 56.2927 593.272 51.0956 596.566 48.0241C599.86 44.9525 605.128 44.1102 605.128 44.1102C605.128 44.1102 605.128 44.1103 605.128 44.1105L592.065 56.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M591.841 38.1094V14.1094C591.841 14.1094 595.841 20.0583 595.841 26.1094C595.841 32.1605 591.841 38.1094 591.841 38.1094Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M591.841 38.1094C591.841 38.1094 587.841 32.1605 587.841 26.1094C587.841 20.0583 591.841 14.1094 591.841 14.1094V38.1094Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M578.1 29.69C578.114 29.7483 579.368 35.0834 582.757 38.2437C586.146 41.404 591.556 42.2832 591.615 42.2927L578.1 29.69ZM587.014 33.7909C586.996 33.7734 586.977 33.756 586.958 33.7386C586.94 33.7212 586.921 33.7039 586.902 33.6867L587.014 33.7909Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M591.616 42.2935C591.616 42.2935 591.616 42.2935 591.616 42.2935C591.616 42.2935 590.367 36.917 586.959 33.7393C583.551 30.5617 578.101 29.6903 578.101 29.6903C578.101 29.6903 578.101 29.6904 578.101 29.6906L591.616 42.2935Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M587.399 44.5145C587.387 44.5026 587.374 44.4908 587.362 44.4791C587.349 44.4673 587.336 44.4556 587.324 44.4439L587.399 44.5145ZM591.616 52.2935C591.576 52.2871 586.624 51.4857 583.524 48.5946C580.424 45.7035 579.279 40.8199 579.27 40.7804L591.616 52.2935ZM579.27 40.7802L579.27 40.7801C579.27 40.7801 579.269 40.7801 579.269 40.7801C579.269 40.7801 579.269 40.7802 579.27 40.7802Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M591.616 52.2935C591.616 52.2936 591.616 52.2936 591.616 52.2936C591.616 52.2936 590.475 47.382 587.362 44.4791C584.249 41.5762 579.27 40.7801 579.27 40.7801C579.27 40.7801 579.27 40.7802 579.27 40.7805L591.616 52.2935Z"
        fill="#183128"
      />
      <path d="M591.845 25L589.845 65H593.845L591.845 25Z" fill="#BDA35E" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M236.654 36.8154C236.671 36.7987 236.689 36.782 236.707 36.7654C236.725 36.7488 236.742 36.7323 236.76 36.7158L236.654 36.8154ZM232.065 45.2924C232.121 45.2833 237.516 44.4073 240.895 41.2563C244.274 38.1053 245.524 32.7853 245.537 32.7296L232.065 45.2924ZM245.537 32.7293L245.537 32.7292C245.537 32.7292 245.537 32.7292 245.537 32.7292C245.537 32.7292 245.537 32.7292 245.537 32.7293Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M232.064 45.2926C232.064 45.2926 232.064 45.2927 232.064 45.2927C232.064 45.2927 233.31 39.9332 236.706 36.7656C240.103 33.598 245.536 32.7293 245.536 32.7293C245.536 32.7293 245.536 32.7295 245.536 32.7297L232.064 45.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M245.129 44.1108L245.129 44.1108L232.065 56.2931C232.065 56.2932 232.065 56.2932 232.065 56.2932C232.065 56.2932 237.333 55.4509 240.627 52.3793C243.921 49.3077 245.129 44.1107 245.129 44.1107C245.129 44.1107 245.129 44.1107 245.129 44.1108Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M232.065 56.2926C232.064 56.2926 232.064 56.2927 232.064 56.2927C232.064 56.2927 233.272 51.0956 236.566 48.0241C239.86 44.9525 245.128 44.1102 245.128 44.1102C245.128 44.1102 245.128 44.1103 245.128 44.1105L232.065 56.2926Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M231.841 38.1094V14.1094C231.841 14.1094 235.841 20.0583 235.841 26.1094C235.841 32.1605 231.841 38.1094 231.841 38.1094Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M231.841 38.1094C231.841 38.1094 227.841 32.1605 227.841 26.1094C227.841 20.0583 231.841 14.1094 231.841 14.1094V38.1094Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M218.1 29.69C218.114 29.7483 219.368 35.0834 222.757 38.2437C226.146 41.404 231.556 42.2832 231.615 42.2927L218.1 29.69ZM227.014 33.7909C226.996 33.7734 226.977 33.756 226.958 33.7386C226.94 33.7212 226.921 33.7039 226.902 33.6867L227.014 33.7909Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M231.616 42.2935C231.616 42.2935 231.616 42.2935 231.616 42.2935C231.616 42.2935 230.367 36.917 226.959 33.7393C223.551 30.5617 218.101 29.6903 218.101 29.6903C218.101 29.6903 218.101 29.6904 218.101 29.6906L231.616 42.2935Z"
        fill="#183128"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M227.399 44.5145C227.387 44.5026 227.374 44.4908 227.362 44.4791C227.349 44.4673 227.336 44.4556 227.324 44.4439L227.399 44.5145ZM231.616 52.2935C231.576 52.2871 226.624 51.4857 223.524 48.5946C220.424 45.7035 219.279 40.8199 219.27 40.7804L231.616 52.2935ZM219.27 40.7802L219.27 40.7801C219.27 40.7801 219.269 40.7801 219.269 40.7801C219.269 40.7801 219.269 40.7802 219.27 40.7802Z"
        fill="#1A7D1E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M231.616 52.2935C231.616 52.2936 231.616 52.2936 231.616 52.2936C231.616 52.2936 230.475 47.382 227.362 44.4791C224.249 41.5762 219.27 40.7801 219.27 40.7801C219.27 40.7801 219.27 40.7802 219.27 40.7805L231.616 52.2935Z"
        fill="#183128"
      />
      <path d="M231.845 25L229.845 65H233.845L231.845 25Z" fill="#BDA35E" />
      <defs>
        <linearGradient
          id="paint0_linear_3877_80894"
          x1="123.539"
          y1="15"
          x2="123.539"
          y2="55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A6966D" />
          <stop offset="1" stop-color="#88784F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3877_80894"
          x1="322.539"
          y1="15"
          x2="322.539"
          y2="55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A6966D" />
          <stop offset="1" stop-color="#88784F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3877_80894"
          x1="634.539"
          y1="15"
          x2="634.539"
          y2="55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A6966D" />
          <stop offset="1" stop-color="#88784F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3877_80894"
          x1="684.539"
          y1="15"
          x2="684.539"
          y2="55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A6966D" />
          <stop offset="1" stop-color="#88784F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3877_80894"
          x1="450.539"
          y1="15"
          x2="450.539"
          y2="55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A6966D" />
          <stop offset="1" stop-color="#88784F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3877_80894"
          x1="450.539"
          y1="15"
          x2="450.539"
          y2="55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A6966D" />
          <stop offset="1" stop-color="#88784F" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3877_80894"
          x1="546.539"
          y1="15"
          x2="546.539"
          y2="55"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A6966D" />
          <stop offset="1" stop-color="#88784F" />
        </linearGradient>
      </defs>
    </svg>
  );
}
