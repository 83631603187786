import { SupportOption, SupportType } from './services/apiService';
export const routes = {
  LoginUrl: '/',
  HomeUrl: '/home',
  ForgotPasswordUrl: '/forgot-password',
  EnergyChargeUrl: '/payment',
  InviteUserUrl: '/invite-user',
  UserProfileUrl: '/user-profile',
  NewFacilityUrl: '/new-facility',
  FacilityDetailUrl: '/facility-detail/:id',
  FacilityListUrl: '/facility_list',
  NewActivityReportUrl: '/facility/:id/new-activity-report',
  ThanksMessageUrl: '/facility/:id/thanks-message',
  MonthlyTargetEnergyUrl: '/facility/:id/monthly-target-energy',
  SupportStatusUrl: '/facility/:id/support-status',
  ActivityReportUrl: '/facility/:id/activity-report',
  BasicInformationUrl: '/facility/:id/basic-information',
  MembersListUrl: '/members_list',
  MemberDetailsUrl: '/members_details',
  InquiryListUrl: '/inquiry_list',
  InquiryMessageUrl: '/inquiry_message/:id',
  ProfileUrl: '/setting/profile',
  EditActivityReport: '/edit_activity_report/:id',
  PrivacyPolicyUrl: '/privacy-policy',
  CommercialLawUrl: '/commercial-law',
  TermsAndServiceUrl: '/terms-and-service',
  PersonalInformationUrl: '/personal-info',
  PhoneNumberUrl: '/phone-number',
  UpdateEmailAddressUrl: '/update-email-address',
  SetLanguageUrl: '/set-language',
  PasswordSettingsUrl: '/password-setting',
  RankingUrl: '/ranking',
  SupportHistoryUrl: '/support-history',
  SearchUrl: '/search',
  UserPostDetailsUrl: '/posts/:id',
  UserPostInformationUrl: '/user-post-information',
  TimeLineUrl: '/time-line',
  UnsubscribeUrl: '/unsubscribe',
  PlanUrl: '/plan',
  CompanyProfileUrl: '/company-profile',
  SupportProductUrl: '/facility/:id/support-product',
  CreditCardInfoUrl: '/credit-card-info',
};

export const dummyUserProfile =
  'https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp';

export const supportData: SupportOption[] = [
  { id: 1, energy: 2, type: SupportType.sowSeeds },
  { id: 2, energy: 6, type: SupportType.giveWater },
  { id: 3, energy: 10, type: SupportType.pourLight },
];

export const supportTypeText = (supportType: SupportType) => {
  switch (supportType) {
    case SupportType.sowSeeds:
      return 'supportAmount.sowSeed';
    case SupportType.giveWater:
      return 'supportAmount.giveWater';
    default:
      return 'supportAmount.pourLight';
  }
};

export const enableFeature = {
  planPage: false,
  unsubscribePage: false,
  timeLinePage: false,
  searchUserTab: false,
  supportDestinationTab: false,
  postLikeFeature: false,
  postTimeContent: false,
  postOtherContent: false,
  postCommentContent: false,
  userPostInfoLevel: false,
  userPostInfoFollower: false,
  inviteUserFollower: false,
  inviteUserLevel: false,
  heartLikeContent: false,
  searchBarContent: false,
  userPostHeader: false,
  savedCard: false,
};
