export function Tree40(props: any) {
  return (
    <svg
      width={676}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M243.062 22.533v14" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M260.533 13.884s-4.224 9.694-9.73 12.261c-5.506 2.568-8.518.662-10.208-2.963l19.938-9.298Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.594 23.182c-1.69-3.625-1.213-7.157 4.293-9.725 5.506-2.567 15.646.428 15.646.428l-19.939 9.297Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.491 5.107s-.268 10.57 3.637 15.224c3.905 4.654 7.44 4.2 10.505 1.629L229.491 5.107Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M243.633 21.96c3.064-2.572 4.125-5.975.22-10.628-3.905-4.654-14.362-6.226-14.362-6.226l14.142 16.853Z"
        fill="#4CAF50"
      />
      <path d="M67.43 22.533v14" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.959 13.884s4.224 9.694 9.73 12.261c5.506 2.568 8.518.662 10.209-2.963l-19.94-9.298Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.898 23.182c1.69-3.625 1.214-7.157-4.292-9.725-5.506-2.567-15.647.428-15.647.428l19.939 9.297Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.001 5.107s.268 10.57-3.637 15.224c-3.905 4.654-7.44 4.2-10.504 1.629L81 5.107Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.86 21.96c-3.065-2.572-4.125-5.975-.22-10.628C70.545 6.678 81 5.106 81 5.106L66.86 21.96Z"
        fill="#4CAF50"
      />
      <path d="M389.933 22.533v14" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M407.404 13.884s-4.224 9.694-9.73 12.261c-5.505 2.568-8.518.662-10.208-2.963l19.938-9.298Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M387.466 23.182c-1.691-3.625-1.214-7.157 4.292-9.725 5.506-2.567 15.646.428 15.646.428l-19.938 9.297Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M376.362 5.107s-.268 10.57 3.637 15.224c3.905 4.654 7.44 4.2 10.505 1.629L376.362 5.107Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M390.504 21.96c3.064-2.572 4.125-5.975.22-10.628-3.905-4.654-14.362-6.226-14.362-6.226l14.142 16.853Z"
        fill="#4CAF50"
      />
      <path d="M559.933 22.533v14" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M577.404 13.884s-4.224 9.694-9.73 12.261c-5.505 2.568-8.518.662-10.208-2.963l19.938-9.298Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M557.466 23.182c-1.691-3.625-1.214-7.157 4.292-9.725 5.506-2.567 15.646.428 15.646.428l-19.938 9.297Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M546.362 5.107s-.268 10.57 3.637 15.224c3.905 4.654 7.44 4.2 10.505 1.629L546.362 5.107Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M560.504 21.96c3.064-2.572 4.125-5.975.22-10.628-3.905-4.654-14.362-6.226-14.362-6.226l14.142 16.853Z"
        fill="#4CAF50"
      />
      <path d="M116.526 22.53v14" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.5 33.03s10.363 2.1 15.624-.938c5.262-3.038 5.429-6.598 3.429-10.062l-19.053 11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.553 22.03c-2-3.465-5.167-5.1-10.429-2.063C99.864 23.005 96.5 33.03 96.5 33.03l19.053-11Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.454 8.734s-1.572 10.457-6.225 14.362c-4.654 3.905-8.057 2.844-10.628-.22l16.853-14.142Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.601 22.876c-2.572-3.065-3.025-6.6 1.628-10.505 4.654-3.905 15.225-3.637 15.225-3.637l-16.853 14.142Z"
        fill="#4CAF50"
      />
      <path d="M283.427 22.53v14" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M303.453 33.03s-10.363 2.1-15.624-.938c-5.261-3.038-5.428-6.598-3.428-10.062l19.052 11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M284.401 22.03c2-3.465 5.167-5.1 10.428-2.063 5.261 3.038 8.624 13.062 8.624 13.062l-19.052-11Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M268.5 8.734s1.571 10.457 6.225 14.362c4.653 3.905 8.056 2.844 10.627-.22L268.5 8.734Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M285.352 22.876c2.572-3.065 3.025-6.6-1.628-10.505C279.07 8.466 268.5 8.734 268.5 8.734l16.852 14.142Z"
        fill="#4CAF50"
      />
      <path d="M656.526 22.53v14" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M636.5 33.03s10.363 2.1 15.624-.938c5.262-3.038 5.429-6.598 3.429-10.062l-19.053 11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M655.553 22.03c-2-3.465-5.167-5.1-10.429-2.063-5.261 3.038-8.624 13.062-8.624 13.062l19.053-11Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M671.454 8.734s-1.572 10.457-6.225 14.362c-4.654 3.905-8.057 2.844-10.628-.22l16.853-14.142Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M654.601 22.876c-2.572-3.065-3.025-6.6 1.628-10.505 4.654-3.905 15.225-3.637 15.225-3.637l-16.853 14.142Z"
        fill="#4CAF50"
      />
      <path d="M502.427 22.53v14" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M522.453 33.03s-10.363 2.1-15.624-.938c-5.261-3.038-5.428-6.598-3.428-10.062l19.052 11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M503.401 22.03c2-3.465 5.167-5.1 10.428-2.063 5.261 3.038 8.624 13.062 8.624 13.062l-19.052-11Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M487.5 8.734s1.571 10.457 6.225 14.362c4.653 3.905 8.056 2.844 10.627-.22L487.5 8.734Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M504.352 22.876c2.572-3.065 3.025-6.6-1.628-10.505C498.07 8.466 487.5 8.734 487.5 8.734l16.852 14.142Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 12.159s3.363 10.024 8.624 13.062c5.262 3.037 8.429 1.402 10.429-2.063l-19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.553 23.158c2-3.464 1.833-7.024-3.429-10.062-5.26-3.037-15.624-.938-15.624-.938l19.053 11Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.553 12.159S38.19 22.182 32.928 25.22c-5.26 3.037-8.428 1.402-10.428-2.063l19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 23.158c-2-3.464-1.833-7.024 3.428-10.062 5.262-3.037 15.625-.938 15.625-.938l-19.053 11Z"
        fill="#4CAF50"
      />
      <path d="M22.526 23.66v13" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M179.5 12.159s3.363 10.024 8.624 13.062c5.262 3.037 8.429 1.402 10.429-2.063l-19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.553 23.158c2-3.464 1.833-7.024-3.429-10.062-5.261-3.037-15.624-.938-15.624-.938l19.053 11Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M217.553 12.159s-3.363 10.024-8.625 13.062c-5.261 3.037-8.428 1.402-10.428-2.063l19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M198.5 23.158c-2-3.464-1.833-7.024 3.428-10.062 5.262-3.037 15.625-.938 15.625-.938l-19.053 11Z"
        fill="#4CAF50"
      />
      <path d="M198.526 23.66v13" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M580.5 12.159s3.363 10.024 8.624 13.062c5.262 3.037 8.429 1.402 10.429-2.063l-19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M599.553 23.158c2-3.464 1.833-7.024-3.429-10.062-5.261-3.037-15.624-.938-15.624-.938l19.053 11Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M618.553 12.159s-3.363 10.024-8.625 13.062c-5.261 3.037-8.428 1.402-10.428-2.063l19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M599.5 23.158c-2-3.464-1.833-7.024 3.428-10.062 5.262-3.037 15.625-.938 15.625-.938l-19.053 11Z"
        fill="#4CAF50"
      />
      <path d="M599.526 23.66v13" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M326.5 12.159s3.363 10.024 8.624 13.062c5.262 3.037 8.429 1.402 10.429-2.063l-19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M345.553 23.158c2-3.464 1.833-7.024-3.429-10.062-5.261-3.037-15.624-.938-15.624-.938l19.053 11Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M364.553 12.159s-3.363 10.024-8.625 13.062c-5.261 3.037-8.428 1.402-10.428-2.063l19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M345.5 23.158c-2-3.464-1.833-7.024 3.428-10.062 5.262-3.037 15.625-.938 15.625-.938l-19.053 11Z"
        fill="#4CAF50"
      />
      <path d="M345.526 23.66v13" stroke="#142E24" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M415.5 12.159s3.363 10.024 8.624 13.062c5.262 3.037 8.429 1.402 10.429-2.063l-19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M434.553 23.158c2-3.464 1.833-7.024-3.429-10.062-5.261-3.037-15.624-.938-15.624-.938l19.053 11Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M453.553 12.159s-3.363 10.024-8.625 13.062c-5.261 3.037-8.428 1.402-10.428-2.063l19.053-11Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M434.5 23.158c-2-3.464-1.833-7.024 3.428-10.062 5.262-3.037 15.625-.938 15.625-.938l-19.053 11Z"
        fill="#4CAF50"
      />
      <path d="M434.526 23.66v13" stroke="#142E24" strokeLinecap="round" />
    </svg>
  );
}
