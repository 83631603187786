/* eslint-disable no-empty-pattern */
import { useState } from 'react';
import { Card, Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import { MoreOutlined } from '@ant-design/icons';

import { Avatar } from '../Avatar/Avatar';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import { SupportProductType } from '../../services/apiService';

import styles from './index.module.scss';

export interface SupportProductCardProps {
  onEdit: (item: SupportProductType) => void;
  onDelete: (id: number) => void;
  item: SupportProductType;
}
export const SupportProductCard = ({
  onEdit,
  item,
  onDelete,
}: SupportProductCardProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const onEditClick = () => {
    setIsVisible(false);
    onEdit(item);
  };
  const onDeleteClick = () => {
    setIsVisible(false);
    onDelete(item.id);
  };

  const handleVisibleChange = () => {
    setIsVisible(true);
  };
  const content = () => {
    return (
      <div>
        <div className={styles.menuItem} onClick={onEditClick}>
          <FormattedMessage id="supportProduct.edit" />
        </div>
        <div className={styles.menuItem} onClick={onDeleteClick}>
          <FormattedMessage id="supportProduct.delete" />
        </div>
      </div>
    );
  };
  return (
    <Card className={styles.mainCard}>
      <Avatar urlId={item.supportproductImage?.id} shape="square" size={300} />
      <div className={styles.detailContent}>
        <h3>{item.title}</h3>
        <div className={styles.menuBtn}>
          <Popover
            content={content}
            visible={isVisible}
            trigger="click"
            onVisibleChange={handleVisibleChange}
          >
            <MoreOutlined />
          </Popover>
        </div>
      </div>
      <h3>11,230 /20,000 circle</h3>
      <ProgressBar percent={60} />
    </Card>
  );
};
