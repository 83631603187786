import { Progress } from 'antd';

import styles from './index.module.scss';

export interface ProgressBarProps {
  percent: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ percent }) => {
  return (
    <div>
      <Progress
        percent={percent}
        className={styles.progressBar}
        showInfo={false}
      />
      <span className={styles.progressBarPer}>{percent} %</span>
    </div>
  );
};
