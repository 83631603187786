/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { CaretDownOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Dropdown, Menu, notification } from 'antd';

import { withTabs } from '../withTabs';
import {
  SupportProductType,
  useDeleteSupportProductMutation,
  useGetSupportProductQuery,
} from '../../../../services/apiService';
import { Button } from '../../../../stories/Button/Button';
import { Loader } from '../../../../stories/Loader/Loader';
import { SupportProductCard } from '../../../../stories/SupportProductCard/SupportProductCard';
import { AddSupportProductModal } from '../../../../stories/AddSupportProductModal/AddSupportProductModal';

import styles from './index.module.scss';

const { Title } = Typography;

const SupportProduct = () => {
  const { id: facilityId } = useParams<{ id: string }>();
  const productListArr = [...Array(10)].map((_, i) => {
    return {
      productId: i + 1,
    };
  });
  const [paginateInfo, setPaginateInfo] = useState({
    page: 1,
    limit: 10,
    facilityId: parseInt(facilityId),
  });
  const { data: productList, isLoading } =
    useGetSupportProductQuery(paginateInfo);

  const intl = useIntl();
  const [isVisible, setIsVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [type1, setType1] = useState('all');
  const [type2, setType2] = useState('Date of addition');

  const [
    deleteSupportProduct,
    { isSuccess: isDeleteSuccess, error: isDeleteError },
  ] = useDeleteSupportProductMutation();

  const handleModal = () => {
    setIsVisible(!isVisible);
    setEditData({});
  };

  const onEdit = (item: SupportProductType) => {
    setIsEdit(true);
    setIsVisible(true);
    setEditData(item);
  };

  const onDelete = (id: number) => {
    deleteSupportProduct(id);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      notification.success({
        message: intl.formatMessage({
          id: 'supportProduct.productDeleteSuccess',
        }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (isDeleteError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.FailedToUpdate' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isDeleteError]);

  const menu = (
    <Menu>
      <Menu.Item onClick={() => setType1('all')}>
        <FormattedMessage id="supportProduct.all " />
      </Menu.Item>
      <Menu.Item onClick={() => setType1('Achieved')}>
        <FormattedMessage id="supportProduct.achieved" />
      </Menu.Item>
      <Menu.Item onClick={() => setType1('unacommpolished')}>
        <FormattedMessage id="supportProduct.unacommpolished" />
      </Menu.Item>
    </Menu>
  );

  const menu2 = (
    <Menu>
      <Menu.Item onClick={() => setType2('Date of addition')}>
        <FormattedMessage id="supportProduct.dateOfAddition" />
      </Menu.Item>
      <Menu.Item onClick={() => setType2('Total amount of support')}>
        <FormattedMessage id="supportProduct.totalAmountOfSupport" />
      </Menu.Item>
      <Menu.Item onClick={() => setType2('By name')}>
        <FormattedMessage id="supportProduct.byName" />
      </Menu.Item>
    </Menu>
  );

  if (isLoading) return <Loader />;

  return (
    <Row>
      <Col span={24}>
        <div className={styles.tableHeadingMain}>
          <Title level={2}>Support Products</Title>
          <div className={styles.tableNewButton}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              label="New Support"
              onClick={handleModal}
            />
          </div>
        </div>
        <div className={styles.menuContent}>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className={styles.dropdownContent}>
              <p>{type1}</p>
              <CaretDownOutlined />
            </div>
          </Dropdown>
          <Dropdown overlay={menu2} trigger={['click']}>
            <div className={styles.dropdownContent2}>
              <p> {type2} </p>
              <CaretDownOutlined />
            </div>
          </Dropdown>
        </div>
        <Row>
          {productList?.data.map((data: SupportProductType, index: number) => (
            <Col key={index} span={8}>
              <SupportProductCard
                onEdit={onEdit}
                item={data}
                onDelete={onDelete}
              />
            </Col>
          ))}
        </Row>
      </Col>
      <AddSupportProductModal
        open={isVisible}
        handleCancel={handleModal}
        isEdit={isEdit}
        editData={editData}
      />
    </Row>
  );
};

export default withTabs(SupportProduct);
