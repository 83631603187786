export function Tree20(props: any) {
  return (
    <svg
      width={658}
      height={34}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M8 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.062 4.081S7.038 7.444 4 12.705c-3.038 5.262-1.402 8.429 2.062 10.429l11-19.053Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.062 23.134c3.464 2 7.025 1.833 10.062-3.429 3.038-5.26.938-15.624.938-15.624l-11 19.053Z"
        fill="#1A7D1E"
      />
      <path d="M104 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.062 4.081S103.038 7.444 100 12.705c-3.038 5.262-1.402 8.429 2.062 10.429l11-19.053Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.062 23.134c3.464 2 7.025 1.833 10.062-3.429 3.038-5.26.938-15.624.938-15.624l-11 19.053Z"
        fill="#1A7D1E"
      />
      <path d="M235.725 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M244.788 4.081s-10.025 3.363-13.062 8.624c-3.038 5.262-1.402 8.429 2.062 10.429l11-19.053Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.788 23.134c3.464 2 7.024 1.833 10.062-3.429 3.037-5.26.938-15.624.938-15.624l-11 19.053Z"
        fill="#1A7D1E"
      />
      <path d="M301 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M310.062 4.081S300.038 7.444 297 12.705c-3.038 5.262-1.402 8.429 2.062 10.429l11-19.053Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.062 23.134c3.464 2 7.025 1.833 10.062-3.429 3.038-5.26.938-15.624.938-15.624l-11 19.053Z"
        fill="#1A7D1E"
      />
      <path d="M412 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M421.062 4.081S411.038 7.444 408 12.705c-3.038 5.262-1.402 8.429 2.062 10.429l11-19.053Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M410.062 23.134c3.464 2 7.025 1.833 10.062-3.429 3.038-5.26.938-15.624.938-15.624l-11 19.053Z"
        fill="#1A7D1E"
      />
      <path d="M501.125 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M492.062 4.081s10.024 3.363 13.062 8.624c3.038 5.262 1.402 8.429-2.062 10.429l-11-19.053Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M503.062 23.134c-3.464 2-7.025 1.833-10.062-3.429-3.038-5.26-.938-15.624-.938-15.624l11 19.053Z"
        fill="#1A7D1E"
      />
      <path d="M538 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M547.062 4.081S537.038 7.444 534 12.705c-3.038 5.262-1.402 8.429 2.062 10.429l11-19.053Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M536.062 23.134c3.464 2 7.025 1.833 10.062-3.429 3.038-5.26.938-15.624.938-15.624l-11 19.053Z"
        fill="#1A7D1E"
      />
      <path d="M634.125 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M625.062 4.081s10.024 3.363 13.062 8.624c3.038 5.262 1.402 8.429-2.062 10.429l-11-19.053Z"
        fill="#4CAF50"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M636.062 23.134c-3.464 2-7.025 1.833-10.062-3.429-3.038-5.26-.938-15.624-.938-15.624l11 19.053Z"
        fill="#1A7D1E"
      />
      <path d="M34 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.967 25.882 26.76 3.329s-1.724 6.958.345 12.645c2.07 5.686 7.863 9.908 7.863 9.908Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.967 25.882s1.724-6.959-.345-12.645c-2.07-5.686-7.863-9.908-7.863-9.908l8.208 22.553Z"
        fill="#4CAF50"
      />
      <path d="M165 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m165.967 25.882-8.208-22.553s-1.724 6.958.345 12.645c2.07 5.686 7.863 9.908 7.863 9.908Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.967 25.882s1.724-6.959-.345-12.645c-2.07-5.686-7.863-9.908-7.863-9.908l8.208 22.553Z"
        fill="#4CAF50"
      />
      <path d="M196.725 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m195.758 25.882 8.209-22.553s1.724 6.958-.346 12.645c-2.069 5.686-7.863 9.908-7.863 9.908Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M195.758 25.882s-1.724-6.959.346-12.645c2.069-5.686 7.863-9.908 7.863-9.908l-8.209 22.553Z"
        fill="#4CAF50"
      />
      <path d="M352 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m352.967 25.882-8.208-22.553s-1.724 6.958.345 12.645c2.07 5.686 7.863 9.908 7.863 9.908Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M352.967 25.882s1.724-6.959-.345-12.645c-2.07-5.686-7.863-9.908-7.863-9.908l8.208 22.553Z"
        fill="#4CAF50"
      />
      <path d="M431.725 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m430.758 25.882 8.209-22.553s1.724 6.958-.346 12.645c-2.069 5.686-7.863 9.908-7.863 9.908Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M430.758 25.882s-1.724-6.959.346-12.645c2.069-5.686 7.863-9.908 7.863-9.908l-8.209 22.553Z"
        fill="#4CAF50"
      />
      <path d="M564 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m564.967 25.882-8.208-22.553s-1.724 6.958.345 12.645c2.07 5.686 7.863 9.908 7.863 9.908Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M564.967 25.882s1.724-6.959-.345-12.645c-2.07-5.686-7.863-9.908-7.863-9.908l8.208 22.553Z"
        fill="#4CAF50"
      />
      <path d="M653 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m653.967 25.882-8.208-22.553s-1.724 6.958.345 12.645c2.07 5.686 7.863 9.908 7.863 9.908Z"
        fill="#1A7D1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M653.967 25.882s1.724-6.959-.345-12.645c-2.07-5.686-7.863-9.908-7.863-9.908l8.208 22.553Z"
        fill="#4CAF50"
      />
      <path d="M58 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <circle cx={58} cy={17.607} r={6} fill="#4CAF50" />
      <circle cx={58} cy={19.607} r={4} fill="#1A7D1E" />
      <path d="M125 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <circle cx={125} cy={17.607} r={6} fill="#4CAF50" />
      <circle cx={125} cy={19.607} r={4} fill="#1A7D1E" />
      <path d="M270 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <circle cx={270} cy={17.607} r={6} fill="#4CAF50" />
      <circle cx={270} cy={19.607} r={4} fill="#1A7D1E" />
      <path d="M376 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <circle cx={376} cy={17.607} r={6} fill="#4CAF50" />
      <circle cx={376} cy={19.607} r={4} fill="#1A7D1E" />
      <path d="M462 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <circle cx={462} cy={17.607} r={6} fill="#4CAF50" />
      <circle cx={462} cy={19.607} r={4} fill="#1A7D1E" />
      <path d="M588 23.607v9" stroke="#142E24" strokeLinecap="square" />
      <circle cx={588} cy={17.607} r={6} fill="#4CAF50" />
      <circle cx={588} cy={19.607} r={4} fill="#1A7D1E" />
    </svg>
  );
}
