/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useState, useEffect, useCallback } from 'react';
import { Row, Col, Form, Divider, Button, notification, Checkbox } from 'antd';

import { Input } from '../Input/Input';
import { enableFeature } from '../../constant';

import styles from './index.module.scss';
import './credit-card-info-custom.scss';

interface creditCardProps {
  step: number;
  setIsSaved: Function;
  closeModal: Function;
  handleAddCard: Function;
  donateApiLoading: boolean;
  setStripePaymentMethodId: Function;
}
export const CreditCardInfo: React.FC<creditCardProps> = ({
  step,
  closeModal,
  setIsSaved,
  handleAddCard,
  donateApiLoading,
  setStripePaymentMethodId,
}) => {
  const intl = useIntl();

  const stripe = useStripe();
  const [form] = Form.useForm();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const clearForm = useCallback(() => {
    if (elements && step === 1) {
      elements.getElement(CardNumberElement)!.clear();
      elements.getElement(CardExpiryElement)!.clear();
      elements.getElement(CardCvcElement)!.clear();
      setTimeout(() => {
        form.resetFields();
      }, 50);
    }
  }, [form, elements, step]);

  useEffect(() => {
    if (elements && step === 1) {
      clearForm();
    }
  }, [step, form, elements, clearForm]);

  const handleCancel = () => {
    clearForm();
    closeModal(false);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setIsSaved(e.target.checked);
  };

  const handleSubmit = async ({ name }: { name: string }) => {
    setLoading(true);
    const paymentMethodObj = {
      type: 'card' as any,
      card: elements?.getElement(CardNumberElement)!,
      billing_details: {
        name,
        CardCvcElement,
        CardNumberElement,
        CardExpiryElement,
      },
    };

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod(
      paymentMethodObj
    );

    setLoading(false);

    if (error) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.somethingWrong' }),
        placement: 'topRight',
        duration: 15,
      });
    } else {
      setStripePaymentMethodId(paymentMethod?.id);
      handleAddCard(paymentMethod?.id);
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#212529',
        lineHeight: '27px',
        fontSize: '1.1rem',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
    },
  };
  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Form.Item
              shouldUpdate
              className={styles.formItem}
              label={<FormattedMessage id="creditCardInfo.cardNumber" />}
            >
              {() => (
                <>
                  <div
                    className={
                      form.getFieldError('card').length > 0
                        ? styles.errorInputField
                        : styles.inputField
                    }
                  >
                    <CardNumberElement
                      id="creditCardNumber"
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={async (event) => {
                        if (event.empty) {
                          form.setFields([
                            {
                              name: 'card',
                              value: '',
                              errors: ['Card number is required'],
                            },
                          ]);
                        }

                        if (event.error) {
                          form.setFields([
                            {
                              name: 'card',
                              value: 'garbage value',
                              errors: [event.error.message],
                            },
                          ]);
                        }

                        if (event.complete) {
                          form.setFields([
                            {
                              name: 'card',
                              value: 'garbage value',
                              errors: [],
                            },
                          ]);
                        }
                      }}
                    />
                  </div>
                  <div className="hidden-inputs">
                    <Input
                      type="text"
                      name="card"
                      rules={[
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="creditCardInfo.cardNumberRequired" />
                          ),
                        },
                      ]}
                    />
                  </div>
                </>
              )}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Input
              type="text"
              name="name"
              label={<FormattedMessage id="creditCardInfo.cardName" />}
              placeholder={'TAROU TANAKA'}
              rules={[{ required: true, message: 'Name number is required' }]}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              shouldUpdate
              className={styles.formItem}
              label={<FormattedMessage id="creditCardInfo.expDate" />}
            >
              {() => (
                <>
                  <div
                    className={
                      form.getFieldError('card').length > 0
                        ? styles.errorInputField
                        : styles.inputField
                    }
                  >
                    <CardExpiryElement
                      id="expiry"
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={(event) => {
                        if (event.empty) {
                          form.setFields([
                            {
                              name: 'cardExpiry',
                              value: '',
                              errors: ['Card number is required'],
                            },
                          ]);
                        }

                        if (event.error) {
                          form.setFields([
                            {
                              name: 'cardExpiry',
                              value: 'garbage value',
                              errors: [event.error.message],
                            },
                          ]);
                        }

                        if (event.complete) {
                          form.setFields([
                            {
                              name: 'cardExpiry',
                              value: 'garbage value',
                              errors: [],
                            },
                          ]);
                        }
                      }}
                    />
                  </div>

                  <div className="hidden-inputs">
                    <Input
                      type="text"
                      name="cardExpiry"
                      rules={[
                        {
                          required: true,
                          message: 'Expiry date is required',
                        },
                      ]}
                    />
                  </div>
                </>
              )}
            </Form.Item>
          </Col>
          {step === 1 && (
            <Col span={12}>
              <Form.Item
                shouldUpdate
                label={<FormattedMessage id="creditCardInfo.securityCode" />}
                className={styles.formItem}
              >
                {() => (
                  <>
                    <div
                      className={
                        form.getFieldError('card').length > 0
                          ? styles.errorInputField
                          : styles.inputField
                      }
                    >
                      <CardCvcElement
                        id="cardCvc"
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={(event) => {
                          if (event.empty) {
                            form.setFields([
                              {
                                name: 'cardCvc',
                                value: '',
                                errors: ['Card CVC is required'],
                              },
                            ]);
                          }
                          if (event.complete) {
                            form.setFields([
                              {
                                name: 'cardCvc',
                                value: 'garbage value',
                                errors: [],
                              },
                            ]);
                          }
                        }}
                      />
                    </div>
                    <div className="hidden-inputs">
                      <Input
                        type="text"
                        name="cardCvc"
                        rules={[
                          { required: true, message: 'Card CVC is required' },
                        ]}
                      />
                    </div>
                  </>
                )}
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Checkbox onChange={onChange} id="saveCard">
              <FormattedMessage id="creditCardInfo.SaveCard" />
            </Checkbox>
          </Col>
        </Row>
        <Divider />
        <Row justify="space-between">
          <Col>
            <Button
              onClick={handleCancel}
              disabled={loading || donateApiLoading}
            >
              <FormattedMessage id="creditCardInfo.cancel" />
            </Button>
          </Col>
          <Col>
            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type="primary"
                  id="submitBtn"
                  htmlType="submit"
                  loading={loading || donateApiLoading}
                  disabled={
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length > 0
                  }
                >
                  <FormattedMessage id="creditCardInfo.submit" />
                </Button>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
