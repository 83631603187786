/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Line,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  LineChart,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Card, Progress, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { withTabs } from '../withTabs';
import {
  SupportHistory,
  useGetDonateEnergesQuery,
} from '../../../../services/apiService';
import { RootState } from '../../../../store';
import { SorterResult } from 'antd/lib/table/interface';
import {
  FacilityUser,
  setFacilityUserList,
} from '../../../../features/facility/facilityUserSlice';
import Facility1 from '../../../../assets/images/astra1.png';
import GreenUpArrow from '../../../../stories/Icons/GreenUpArrow/GreenUpArrow';
import RedDownArrow from '../../../../stories/Icons/RedDownArrow/RedDownArrow';

import styles from './index.module.scss';

const SupportStatus = () => {
  const dispatch = useDispatch();
  const [paginateInfo, setPaginateInfo] = useState({
    page: 1,
    search: '',
    orderDirection: 'ASC',
    orderField: 'createdAt',
  });
  const { facilityUsers } = useSelector(
    (state: RootState) => state.facilityUser
  );

  const [donateEnergy] = useState({
    page: 1,
    limit: 10,
    orderDirection: 'ASC',
    orderField: 'createdAt',
  });

  const userData = {
    data: [...Array(10)].map((_, i) => {
      return {
        id: i,
        image: Facility1,
        username: '松原 浩太郎',
        userId: 'Tanaka_Taro',
        supportAmount: 100,
        supportDateTime: '2021-06-17T16:12:35.329Z',
      };
    }),
  };

  const { data: donateEnergies } = useGetDonateEnergesQuery(donateEnergy);

  useEffect(() => {
    getFacilityData(userData.data);
  }, []);

  const getFacilityData = (facilityUsers: FacilityUser[]) => {
    dispatch(setFacilityUserList({ facilityUsers }));
  };

  const handleTableChange = (current: any, field: any, orderBy: any) => {
    setPaginateInfo({
      ...paginateInfo,
      page: current,
      orderField: field || 'createdAt',
      orderDirection: orderBy === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const columns = [
    {
      title: 'ユーザーネーム',
      dataIndex: 'username',
      render: (text: string, record: any) => (
        <div className={styles.tableColumn}>
          <div className={styles.usrImg}>
            <img src={record.image} alt="img" width={50} height={50} />
          </div>
          <div className={styles.tableColumnTitle}>
            <p>{text}</p>
          </div>
        </div>
      ),
      width: 610,
    },
    {
      title: 'ユーザーID',
      dataIndex: 'userId',
      sorter: (a: any, b: any) => a.userId - b.userId,
      render: (text: string) => (
        <div className={styles.tableColumn}>
          <p>@{text} </p>
        </div>
      ),
      width: 200,
    },
    {
      title: '支援額',
      dataIndex: 'supportAmount',
      sorter: (a: any, b: any) => a.supportAmount - b.supportAmount,
      render: (text: string) => (
        <div className={styles.tableColumn}>
          <p>円</p>
        </div>
      ),
      width: 200,
    },
    {
      title: '支援額',
      dataIndex: 'supportDateTime',
      sorter: (a: any, b: any) => a.supportDateTime - b.supportDateTime,
      render: (props: SupportHistory) => (
        <div className={styles.tableColumn}>
          <p>{moment(props?.createdAt).format('YYYY/MM/DD hh:mm')}</p>
        </div>
      ),
      width: 350,
    },
  ];

  return (
    <>
      <Row>
        <Col span={6}>
          <div className={styles.cardStyle}>
            <Card>
              <div className={styles.cardStyleTitle}>
                <FormattedMessage id="support.facilityTitle" />
              </div>
              <div className={styles.cardStyleDetail}>
                <div className={styles.cardStyleSupporter}>113</div>
                <div className={styles.cardStyleSupportNumber}>
                  <div className={styles.cardStyleUpArrow}>
                    <GreenUpArrow />
                  </div>
                  <div className={styles.cardStylePoints}>+13人</div>
                </div>
              </div>
            </Card>
          </div>
        </Col>
        <Col span={6}>
          <div className={styles.cardStyle}>
            <Card>
              <div className={styles.cardStyleTitle}>
                <FormattedMessage id="support.support" />
              </div>
              <div className={styles.cardStyleDetail}>
                <div className={styles.cardStyleSupporter}>113</div>
                <div className={styles.cardStyleSupportNumber}>
                  <div className={styles.cardStyleUpArrow}>
                    <RedDownArrow />
                  </div>
                  <div className={styles.cardStylePoints}>+42回</div>
                </div>
              </div>
            </Card>
          </div>
        </Col>
        <Col span={12}>
          <div className={styles.cardSupportAmountStyle}>
            <Card>
              <div className={styles.cardSupportAmount}>
                <Col className={styles.cardSupportAmountIcon} span={4}>
                  <Progress type="circle" percent={50} width={80} />
                </Col>
                <Col className={styles.cardSupportAmountText} span={20}>
                  <div className={styles.cardSupportAmountTitle}>
                    <FormattedMessage id="support.cardSupportAmountTitle" />
                  </div>
                  <div className={styles.cardSupportAmountDetail}>
                    <div className={styles.cardSupportAmountSupporter}>
                      1,115,250 / 2,500,000
                    </div>
                    <div className={styles.cardSupportAmountSupportNumber}>
                      <div className={styles.cardSupportAmountUpArrow}>
                        <RedDownArrow />
                      </div>
                      <div className={styles.cardSupportAmountPoints}>
                        +1,200円
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={styles.chart}>
            <Card>
              {donateEnergies && (
                <ResponsiveContainer width="100%" maxHeight={320} aspect={3}>
                  <LineChart data={donateEnergies.data}>
                    <XAxis interval={'preserveStartEnd'} />
                    <YAxis dataKey="energy" />
                    <YAxis />
                    <Legend />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="energy"
                      stroke="#6C7D8C"
                      activeDot={{ r: 8 }}
                    />
                    <Line
                      type="monotone"
                      dataKey="energy"
                      stroke="#A99C8B"
                      activeDot={{ r: 8 }}
                      strokeDasharray="5 3"
                      dot={{
                        fill: '#A99C8B',
                        strokeWidth: 1,
                        r: 4,
                        strokeDasharray: '',
                      }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              )}
            </Card>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div className={styles.tableSupportStatus}>
            <Table
              rowKey="id"
              columns={columns}
              dataSource={donateEnergies?.data}
              onChange={(pagination, filters, sorter) => {
                const { field, order } = sorter as SorterResult<any>;
                handleTableChange(pagination.current, field, order);
              }}
              pagination={{
                total: 0,
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default withTabs(SupportStatus);
