import { EnergyChargePlanType } from './../stories/Payment/Payment';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getAccessToken } from '../getAccessToken';

export enum SupportType {
  sowSeeds = 'Sow Seed',
  giveWater = 'Give Water',
  pourLight = 'Pour Light',
}
export type UserRole = '' | 'Donor' | 'FacilityAdmin' | 'AstraAdmin';
export type PlanType = 'Gold' | 'Platinum' | 'Black';

export interface SupportOption {
  id: number;
  energy: number;
  type: SupportType;
}

export interface MeType {
  id?: number;
  dob: string;
  email: string;
  role: UserRole;
  gender: string;
  energy: number;
  fullName: string;
  postalCode: string;
  phoneNumber: string;
  annualIncome: string;
  streetAddress: string;
  totalBoughtEnergy: number;
  totalDonatedEnergy: number;
  maxInvitationLimit: number;
  isProfileCompleted: boolean;
  ownedFacility?: { id: string };
  intro?: string;
  area?: string;
  link?: string;
  title?: string;
  profileImage?: { id: number | undefined };
}

export interface updateProfile {
  id: number;
  dob: string;
  email: string;
  gender: string;
  fullName: string;
  postalCode: string;
  annualIncome: string;
  streetAddress: string;
}

export interface FacilityType {
  id: number;
  name: string;
  area: string;
  story: string;
  adminId: number;
  mission: string;
  children: number;
  streetAddress: string;
  representativeName: string;
  representativeProfile: string;
  representativeImageId: number;
  missionImageId: number;
  coverImageId: number;
  storyImageId: number;
}

export interface FacilityListResponse {
  totalCount: number;
  page: number;
  limit: number;
  data: FacilityResponse[];
}

export interface ThanksMessageListResponse {
  totalCount: number;
  page: number;
  limit: number;
  data: ThanksMessageResponse[];
}
export interface ThanksMessageResponse {
  id: number;
  emails: string;
  title: string;
  message: string;
  sent: boolean;
  scheduledAt: string;
  createdAt: string;
  updatedAt: string;
  facilityId: number;
}
export interface FacilityResponse {
  id: number;
  name: string;
  area: string;
  story: string;
  adminId: number;
  mission: string;
  children: number;
  totalDonors?: number;
  totalDonatedEnergy?: number;
  streetAddress: string;
  representativeName: string;
  representativeProfile: string;
  representativeImage: {
    id: number;
  };
  missionImage: {
    id: number;
  };
  coverImage: {
    id: number;
  };
  storyImage: { id: number };
  size?: string;
}

export interface DonateEnergies {
  data: any[] | undefined;
  id: number;
  date: string;
  energy: number;
  comment: null | string;
  createdAt: string;
  updatedAt: string;
  facilityId: number;
  userId: number;
}

export interface ActivityReportType {
  id: number;
  title: string;
  facilityId: number;
  reportDate?: string;
  description: string;
  createdAt: string;
  coverImageId?: number;
  coverImage?: {
    id: number;
  };
}

export interface ActivityReportsResponse {
  page: number;
  limit: number;
  totalCount: number;
  data: ActivityReportType[];
}

export interface InvitationType {
  id: number;
  status: string;
  createdAt: string;
  invitedToUser: {
    fullName?: string;
    phoneNumber: string;
  };
}

interface createActivity {
  title: string;
  facilityId: string;
  description: string;
  coverImageId: string;
}

interface createPost {
  title: string;
  description: string;
  coverImageId: number;
  tagIds: any;
}
export interface tagObjectType {
  id: number;
  name: string;
  PostTagModel: {
    createdAt: string;
    updatedAt: string;
    tagId: number;
    postId: number;
  };
}
export interface PostType {
  id: number;
  title: string;
  userId: number;
  description: string;
  createdAt: string;
  tags: tagObjectType[];
  coverImage: {
    id: number;
  };
}
export interface PostsResponse {
  totalCount: number;
  page: number;
  limit: number;
  data: PostType[];
}

export interface TagsType {
  id: number;
  name: string;
}

interface GetActivityReportInterface {
  page: number;
  limit: number;
  facilityId: string;
}
export interface InquiryTypeResponse {
  totalCount: number;
  page: number;
  limit: number;
  data: InquiryType[];
}
export interface InquiryType {
  id: number;
  name: string;
  email: string;
  title: string;
  createdAt: string;
  updatedAt: string;
  description: string;
}

export interface InquiryPostRequest {
  name: string;
  email: string;
  title: string;
  description: string;
}
export interface InquiryReplyType {
  id: number;
  createdAt: string;
  updatedAt: string;
  inquiryId: number;
  description: string;
  inquiryReplyImage: {
    id: number;
  };
}
export interface SupporterCommentOption {
  energy: number;
  comment: string;
  supportUnit: {
    type: string;
  };
  user: {
    id: number;
    fullname: string;
    profileImage: { id: number };
  };
}

export interface CommentType {
  id: number;
  userId: number;
  createdAt: string;
  postId: number;
  commentId: number;
  comment: string;
  likes: { id: number }[];
  user: {
    id: number;
    fullName: string;
    profileImage: { id: number | undefined };
  };
}

export interface CommentResponse {
  totalCount: number;
  page: number;
  limit: number;
  data: CommentType[];
}
export interface MonthlyTargetEnergyType {
  id: number;
  facilityId: number;
  yearMonth: string;
  targetEnergy: number;
  totalSupportedEnergy: number | null;
  totalSupportersCount: number;
  createdAt: string;
  updatedAt: string;
}

export interface SupportHistory {
  type: string;
  energy: number;
  userId: number;
  comment: string;
  facilityId: number;
  createdAt: string;
  user: {
    id: number;
    profileImage: {
      id: number;
    };
  };
}
export interface SupportHistoryResponse {
  totalCount: number;
  page: number;
  limit: number;
  data: SupportHistory[]; 
}
export interface SupportProductType {
  id: number;
  userId: number;
  facilityId: number;
  title: string;
  targetAmount: string;
  createdAt: string;
  supportproductImage: {
    id: number;
  };
}
export interface SupportProductResponse {
  totalCount: number;
  page: number;
  limit: number;
  data: SupportProductType[];
}
export interface MonthlyTargetEnergyType {
  id: number;
  facilityId: number;
  yearMonth: string;
  targetEnergy: number;
  totalSupportedEnergy: number | null;
  totalSupportersCount: number;
  createdAt: string;
  updatedAt: string;
}
export interface MonthlyTargetEnergyResponse {
  totalCount: number;
  page: number;
  limit: number;
  data: MonthlyTargetEnergyType[];
}
export interface CreditCardType {
  id: string;
  card: {
    brand: string;
    last4: string;
    exp_year: number;
    exp_month: number;
  };
  billing_details: {
    name: string;
  };
}
const getActivityReportUrl = ({
  page,
  limit,
  facilityId,
}: GetActivityReportInterface) => {
  return `/activity-reports?page=${page}&limit=${limit}&facilityId=${facilityId}`;
};

export const apiService = createApi({
  reducerPath: 'apiService',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: async (headers) => {
      const token = await getAccessToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'Me',
    'Tags',
    'Posts',
    'Inquiry',
    'Facility',
    'PostComment',
    'Credit-Card',
    'Invitations',
    'Inquiry-Reply',
    'Thanks-Message',
    'ActivityReports',
    'SupportComments',
    'Support-Product',
    'Monthly-Target-Energy',
  ],
  keepUnusedDataFor: 0,
  endpoints: (builder) => ({
    getMe: builder.query<MeType, {}>({
      query: () => `/users/me`,
      providesTags: ['Me'],
    }),
    saveMe: builder.mutation<MeType, MeType>({
      query(body) {
        return {
          url: '/users',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Me']),
    }),
    updateMe: builder.mutation<MeType, Partial<MeType>>({
      query({ id, ...body }) {
        return {
          url: '/users/' + id,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Me']),
    }),
    updateProfile: builder.mutation<MeType, Partial<MeType>>({
      query({ id, ...body }) {
        return {
          url: '/users/' + id + '/profile',
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Me']),
    }),
    createImage: builder.mutation<number, FormData>({
      query(body) {
        return {
          url: '/images',
          method: 'POST',
          body,
        };
      },
    }),
    updateImage: builder.mutation<{}, { id: number; imageUri: FormData }>({
      query({ id, imageUri }) {
        return {
          url: '/images/' + id,
          method: 'PATCH',
          body: imageUri,
        };
      },
    }),
    getFacilities: builder.query<
      FacilityListResponse,
      {
        page: number;
        limit: number;
        search: string;
        orderField: string;
        orderDirection: string;
      }
    >({
      query: ({ page, limit, search, orderField, orderDirection }) =>
        `/facilities?page=${page}&search=${search}&limit=${limit}&orderField=${orderField}&orderDirection=${orderDirection}`,
      providesTags: ['Facility'],
    }),
    addFacility: builder.mutation<FacilityResponse, FacilityType>({
      query(body) {
        return {
          url: '/facilities',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Facility']),
    }),
    updateFacility: builder.mutation<
      FacilityResponse,
      { id: string; body: FacilityType }
    >({
      query({ id, body }) {
        return {
          url: '/facilities/' + id,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Facility']),
    }),
    setAdminFacility: builder.mutation<
      {},
      { phoneNumber: string; facilityId: number }
    >({
      query(body) {
        return {
          url: '/facilities/setAdmin',
          method: 'POST',
          body,
        };
      },
    }),
    getFacilityById: builder.query<FacilityResponse, string>({
      query: (id) => `/facilities/${id}`,
      providesTags: ['Facility'],
    }),
    deletePost: builder.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/facilities/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Facility']),
    }),
    getAmountEnergy: builder.query<EnergyChargePlanType[], {}>({
      query: () => `/amount-to-energies`,
    }),
    donateEnergy: builder.mutation<
      {},
      {
        comment: string;
        facilityId: string;
        supportUnitBoost: number;
        supportUnitId: number;
      }
    >({
      query(body) {
        return {
          url: '/energy-transactions/support',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Me', 'Facility', 'SupportComments'],
    }),
    paymentConfirm: builder.mutation<
      { status: string },
      {
        paymentIntentId: string;
      }
    >({
      query(body) {
        return {
          url: '/energy-transactions/payment/confirm',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [],
    }),
    buyEnergy: builder.mutation<
      { status: string; stripePaymentIntentId: string; client_secret: string },
      {
        amount: number;
        paymentMethodId: string;
        buyBoost: number;
        amountToEnergyId: number;
      }
    >({
      query(body) {
        return {
          url: '/energy-transactions/buy',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [],
    }),
    getDonateEnerges: builder.query<
      DonateEnergies,
      {
        page: number;
        limit: number;
        orderField: string;
        orderDirection: string;
      }
    >({
      query: ({ page, limit, orderField, orderDirection }) =>
        `/energy-transactions/of-facility?page=${page}&search=${limit}&orderField=${orderField}&orderDirection=${orderDirection}`,
    }),
    getEnergyTransactions: builder.query<
      DonateEnergies,
      {
        page: number;
        limit: number;
        orderField: string;
        orderDirection: string;
      }
    >({
      query: ({ page, limit, orderField, orderDirection }) =>
        `/energy-transactions/of-donor?page=${page}&search=${limit}&orderField=${orderField}&orderDirection=${orderDirection}`,
    }),
    getActivityReports: builder.query<
      ActivityReportsResponse,
      GetActivityReportInterface
    >({
      query: getActivityReportUrl,
      providesTags: ['ActivityReports'],
    }),
    getActivityReportById: builder.query<ActivityReportType, string>({
      query: (id) => `/activity-reports/${id}`,
      transformResponse: (response: ActivityReportType) => {
        const coverImageId = response.coverImage?.id;
        delete response.coverImage;
        return {
          ...response,
          coverImageId,
        };
      },
    }),
    addActivityReport: builder.mutation<ActivityReportType, createActivity>({
      query(body) {
        return {
          url: '/activity-reports',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, arg) =>
        error ? [] : ['ActivityReports'],
    }),
    editActivityReport: builder.mutation<
      ActivityReportType,
      { id: string; body: ActivityReportType }
    >({
      query({ id, body }) {
        return {
          url: '/activity-reports/' + id,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (result, error, arg) =>
        error ? [] : ['ActivityReports'],
    }),
    deleteActivityReport: builder.mutation<ActivityReportType, number>({
      query(id) {
        return {
          url: `/activity-reports/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) =>
        error ? [] : ['ActivityReports'],
    }),
    inviteDonor: builder.mutation<{ phoneNumber: string }, {}>({
      query(phoneNumber) {
        return {
          url: '/invitations',
          method: 'POST',
          body: { phoneNumber },
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Invitations']),
    }),
    getSentInvitations: builder.query<InvitationType[], {}>({
      query: () => `/invitations`,
      providesTags: ['Invitations'],
    }),
    cancelInvitations: builder.mutation<
      { success: boolean; id: number },
      number
    >({
      query: (id) => {
        return {
          method: 'DELETE',
          url: `/invitations/${id}`,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Invitations']),
    }),
    getFacilitiesSupporterComments: builder.query<
      [SupporterCommentOption],
      {
        id: string;
        page: number;
        limit: number;
        orderField: string;
        orderDirection: string;
      }
    >({
      query: ({ id, page, limit, orderField, orderDirection }) =>
        `/energy-transactions/${id}/support-comments?page=${page}&limit=${limit}&orderField=${orderField}&orderDirection=${orderDirection}`,
      providesTags: ['SupportComments'],
    }),
    getDonationUnits: builder.query<SupportOption[], any>({
      query: () => `/donation-units`,
    }),
    savePost: builder.mutation<PostType, createPost>({
      query(body) {
        return {
          url: '/posts',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Posts']),
    }),
    updatePost: builder.mutation<
      PostType,
      { id: number; body: { title: string; description: string; tagIds: any } }
    >({
      query({ id, body }) {
        return {
          url: '/posts/' + id,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Posts']),
    }),
    deleteUserPost: builder.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/posts/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Posts']),
    }),
    getPosts: builder.query<
      PostsResponse,
      {
        page: number;
        limit: number;
        userId: number | undefined;
      }
    >({
      query: ({ page, limit, userId }) =>
        `/posts?page=${page} &limit=${limit}&userId=${userId}`,
      providesTags: ['Posts'],
    }),
    getPostById: builder.query<PostType, string>({
      query: (id) => `/posts/${id}`,
    }),
    getTags: builder.query<TagsType[], {}>({
      query: () => `/tags`,
      providesTags: ['Tags'],
    }),
    addTags: builder.mutation<TagsType, { name: string }>({
      query(body) {
        return {
          url: '/tags',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Tags']),
    }),
    getThanksMessage: builder.query<
      ThanksMessageListResponse,
      {
        page: number;
        limit: number;
        search: string;
        orderField: string;
        orderDirection: string;
      }
    >({
      query: ({ page, limit, search, orderField, orderDirection }) =>
        `/thanks-messages?page=${page}&search=${search}&limit=${limit}&orderField=${orderField}&orderDirection=${orderDirection}`,
      providesTags: ['Thanks-Message'],
    }),
    deleteThanksMessage: builder.mutation<
      { success: boolean; id: number },
      number
    >({
      query(id) {
        return {
          url: `/thanks-messages/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) =>
        error ? [] : ['Thanks-Message'],
    }),
    postInquiry: builder.mutation<InquiryType, InquiryPostRequest>({
      query(body) {
        return {
          url: '/inquiries',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Inquiry']),
    }),
    getInquiry: builder.query<
      InquiryTypeResponse,
      {
        page: number;
        limit: number;
      }
    >({
      query: ({ page, limit }) => `/inquiries?page=${page}&limit=${limit} `,
      providesTags: ['Inquiry'],
    }),
    getByIdInquiry: builder.query<InquiryType, string>({
      query: (id) => `/inquiries/${id}`,
      providesTags: ['Inquiry'],
    }),
    deleteInquiry: builder.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/inquiries/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Inquiry']),
    }),
    postInquiryReply: builder.mutation<
      {},
      { inquiryId: number; description: string; InquiryReplyId: number }
    >({
      query(body) {
        return {
          url: `/inquiry_replies `,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Inquiry-Reply']),
    }),
    getPostComments: builder.query<
      CommentResponse,
      {
        page: number;
        limit: number;
        postId: number;
        commentId: number;
      }
    >({
      query: ({ limit, page, postId, commentId }) =>
        `/comments?page=${page}&limit=${limit}&postId=${postId}&commentId=${commentId}`,
      providesTags: ['PostComment'],
    }),
    addComment: builder.mutation<
      CommentType,
      { postId: number; commentId: number; comment: string }
    >({
      query(body) {
        return {
          url: '/comments',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['PostComment']),
    }),
    updateComment: builder.mutation<
      CommentType,
      {
        id: number;
        body: { postId: number; commentId: number; comment: string };
      }
    >({
      query({ id, body }) {
        return {
          url: `/comments/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['PostComment']),
    }),
    deleteComment: builder.mutation<{ success: boolean; id: number }, number>({
      query(id) {
        return {
          url: `/comments/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['PostComment']),
    }),
    createCard: builder.mutation<any, { paymentMethodId: string }>({
      query(body) {
        return {
          url: '/cards/create',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Credit-Card']),
    }),
    getCard: builder.query<{ data: CreditCardType[] }, {}>({
      query: () => `/cards/list`,
      providesTags: ['Credit-Card'],
    }),
    getSupportHistory: builder.query<
      SupportHistoryResponse,
      {
        page: number;
        limit: number;
        orderField: string;
        orderDirection: string;
      }
    >({
      query: ({ page, limit, orderField, orderDirection }) =>
        `/energy-transactions/of-donor?page=${page}&search=${limit}&orderField=${orderField}&orderDirection=${orderDirection}`,
     }),

    deleteCard: builder.mutation<any, string>({
      query(paymentMethodId) {
        return {
          url: `/cards/${paymentMethodId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) => (error ? [] : ['Credit-Card']),
    }),
    createMonthlyTargetEnergy: builder.mutation<
      any,
      {
        id: number;
        data: {
          yearMonth: string;
          targetEnergy: number;
        };
      }
    >({
      query({ id, data }) {
        return {
          url: `/monthly-target-energy/${id}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) =>
        error ? [] : ['Monthly-Target-Energy'],
    }),
    getMonthlyTargetEnergy: builder.query<
      MonthlyTargetEnergyResponse,
      {
        page: number;
        limit: number;
        facilityId: number;
      }
    >({
      query: ({ limit, page, facilityId }) =>
        `/monthly-target-energy/${facilityId}?page=${page}&limit=${limit}`,
      providesTags: ['Monthly-Target-Energy'],
    }),
    updateMonthlyTargetEnergy: builder.mutation<
      any,
      {
        id: number;
        data: {
          targetEnergy: number;
        };
      }
    >({
      query({ id, data }) {
        return {
          url: `/monthly-target-energy/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) =>
        error ? [] : ['Monthly-Target-Energy'],
    }),
    addSupportProduct: builder.mutation<
      SupportProductType,
      {
        id: any | null;
        data: {
          title: string;
          targetAmount: string;
          supportproductImageId: number;
        };
      }
    >({
      query({ id, data }) {
        return {
          url: `/support-products/${id}`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) =>
        error ? [] : ['Support-Product'],
    }),
    getSupportProduct: builder.query<
      SupportProductResponse,
      {
        page: number;
        limit: number;
        facilityId: number;
      }
    >({
      query: ({ limit, page, facilityId }) =>
        `/support-products/${facilityId}?page=${page}&limit=${limit}`,
      providesTags: ['Support-Product'],
    }),
    updateSupportProduct: builder.mutation<
      SupportProductType,
      {
        id: number | null;
        data: {
          title: string;
          targetAmount: string;
        };
      }
    >({
      query({ id, data }) {
        return {
          url: `/support-products/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: (result, error, arg) =>
        error ? [] : ['Support-Product'],
    }),
    deleteSupportProduct: builder.mutation<SupportProductType, number>({
      query(id) {
        return {
          url: `/support-products/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, arg) =>
        error ? [] : ['Support-Product'],
    }),
  }),
});

export const {
  useGetMeQuery,
  useGetTagsQuery,
  useGetCardQuery,
  useGetPostsQuery,
  useSaveMeMutation,
  useAddTagsMutation,
  useGetInquiryQuery,
  useSavePostMutation,
  useGetPostByIdQuery,
  useUpdateMeMutation,
  useBuyEnergyMutation,
  useDeletePostMutation,
  useCreateCardMutation,
  useDeleteCardMutation,
  useGetFacilitiesQuery,
  useAddCommentMutation,
  useUpdatePostMutation,
  useAddFacilityMutation,
  useCreateImageMutation,
  useGetByIdInquiryQuery,
  useUpdateImageMutation,
  usePostInquiryMutation,
  useInviteDonorMutation,
  useGetFacilityByIdQuery,
  useGetAmountEnergyQuery,
  useDonateEnergyMutation,
  useGetPostCommentsQuery,
  useGetDonateEnergesQuery,
  useDeleteInquiryMutation,
  useGetThanksMessageQuery,
  useUpdateCommentMutation,
  useUpdateProfileMutation,
  useDeleteCommentMutation,
  useGetDonationUnitsQuery,
  useGetSupportHistoryQuery,
  useDeleteUserPostMutation,
  useGetSupportProductQuery,
  useUpdateFacilityMutation,
  usePaymentConfirmMutation,
  useGetSentInvitationsQuery,
  useGetActivityReportsQuery,
  useSetAdminFacilityMutation,
  usePostInquiryReplyMutation,
  useAddActivityReportMutation,
  useCancelInvitationsMutation,
  useGetActivityReportByIdQuery,
  useGetEnergyTransactionsQuery,
  useAddSupportProductMutation,
  useEditActivityReportMutation,
  useDeleteThanksMessageMutation,
  useGetMonthlyTargetEnergyQuery,
  useUpdateSupportProductMutation,
  useDeleteSupportProductMutation,
  useDeleteActivityReportMutation,
  useCreateMonthlyTargetEnergyMutation,
  useUpdateMonthlyTargetEnergyMutation,
  useGetFacilitiesSupporterCommentsQuery,
} = apiService;
