export function Tree0(props: any) {
  return (
    <svg
      width={'100%'}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M200.397 1.397c-1.376.726-2.374 2.872-2.374 5.406 0 3.13 1.523 5.669 3.401 5.669V4.535l-1.027-3.138Z"
        fill="#9F8645"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M204.826 6.803c0 3.13-1.523 5.669-3.401 5.669V4.534l1.027-3.137c1.376.726 2.374 2.872 2.374 5.406Z"
        fill="#B89A4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M595.969 1.397c-1.376.726-2.374 2.872-2.374 5.406 0 3.13 1.523 5.669 3.401 5.669l.001-5.67V4.535l-.001.001-1.027-3.138Z"
        fill="#9F8645"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M596.997 12.472h-.001V4.534l1.027-3.137c1.377.725 2.375 2.871 2.375 5.406 0 3.13-1.523 5.669-3.401 5.669ZM10.01 10.511c-1.442.588-3.727-.027-5.668-1.656-2.398-2.012-3.364-4.81-2.156-6.25L6.529 6.25l1.737 1.459 1.743 2.803Z"
        fill="#B89A4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.715 3.644C6.317 1.63 3.394 1.167 2.186 2.606l6.08 5.102 3.064 1.23c.329-1.521-.673-3.665-2.615-5.294Z"
        fill="#9F8645"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.755 10.51c-1.441.59-3.726-.026-5.668-1.655-2.398-2.012-3.363-4.81-2.156-6.25l4.343 3.644 1.738 1.46 1.743 2.802Z"
        fill="#B89A4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m112.932 2.606 6.08 5.102 3.064 1.23c.329-1.521-.674-3.665-2.615-5.294-2.399-2.013-5.322-2.477-6.529-1.038Z"
        fill="#9F8645"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M499.049 10.51c-1.441.59-3.726-.026-5.668-1.655-2.398-2.012-3.363-4.81-2.156-6.25l4.343 3.644 1.738 1.46 1.743 2.802Z"
        fill="#B89A4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m491.226 2.606 6.08 5.102 3.064 1.23c.329-1.521-.674-3.665-2.615-5.294-2.399-2.013-5.322-2.477-6.529-1.038Z"
        fill="#9F8645"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M417.787 10.507c1.317.828 3.673.62 5.868-.648 2.711-1.565 4.148-4.153 3.208-5.78l-4.909 2.835-1.965 1.134h.002l-2.204 2.459Z"
        fill="#B89A4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M420.253 3.968c2.712-1.565 5.671-1.515 6.61.111l-6.873 3.969-3.231.68c-.06-1.556 1.299-3.493 3.494-4.76Z"
        fill="#9F8645"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M344.241 3.643c-.328 1.521.674 3.665 2.615 5.293 2.399 2.013 5.322 2.478 6.529 1.039l-4.342-3.644-1.738-1.458-3.064-1.23Z"
        fill="#B89A4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m353.385 9.975-6.08-5.102-1.743-2.804c1.441-.588 3.726.027 5.667 1.656 2.399 2.013 3.364 4.81 2.156 6.25Z"
        fill="#9F8645"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M254.098 3.643c-.329 1.521.673 3.665 2.615 5.293 2.398 2.013 5.321 2.478 6.529 1.039l-4.343-3.644-1.738-1.458h.001l-3.064-1.23Z"
        fill="#B89A4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m263.242 9.975-6.08-5.102-1.744-2.804c1.441-.588 3.726.027 5.668 1.656 2.398 2.013 3.363 4.81 2.156 6.25Z"
        fill="#9F8645"
      />
    </svg>
  );
}
