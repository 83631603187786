import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MailOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Form, Avatar, Dropdown, Drawer, Space } from 'antd';

import { Search } from '../Icons/Search/Search';
import { Input } from '../../stories/Input/Input';
import { getAccessToken } from '../../getAccessToken';
import { MobileMenu } from '../Icons/MobileMenu/MobileMenu';
import { useWindowDimensions } from '../../useIsDeviceMobile';
import astraLogoGreen from './../../assets/images/AstraLogoGreen.png';
import { dummyUserProfile, enableFeature, routes } from '../../constant';
import { EnergySymbol } from './../../stories/Icons/EnergySymbol/EnergySymbol';

import styles from './index.module.scss';

export interface DonorHeaderProps {
  logout: () => void;
  profileImage?: { id: number | undefined };
  energy: number;
}

export const DonorHeader = ({
  logout,
  profileImage,
  energy,
}: DonorHeaderProps) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const isDeviceMobile = useWindowDimensions();
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [token, setToken] = useState<string | undefined>('');

  const links = [
    {
      to: '/home',
      text: <FormattedMessage id="donorHeader.supportDestination" />,
    },
    {
      to: 'https://www.astrakk.com/#concept',
      text: <FormattedMessage id="donorHeader.whatIsHeader" />,
    },
    // {
    //   to: '/ranking',
    //   text: <FormattedMessage id="donorHeader.ranking" />,
    // },
    // {
    //   to: '/time-line',
    //   text: <FormattedMessage id="donorHeader.timeLine" />,
    // },
  ];

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  const handleAccountClick = () => {
    history.push(routes.PersonalInformationUrl);
  };
  const handleUserPostClick = () => {
    history.push(routes.UserPostInformationUrl);
  };

  const showDrawer = () => {
    setIsShowDrawer(true);
  };
  const onClose = () => {
    setIsShowDrawer(false);
  };
  const handleSearch = (e: any) => {
    if (e.target.value) {
      history.push(routes.SearchUrl, { search: e.target.value });
      form.resetFields();
    }
  };
  const handleMenuClick = (to: string) => {
    if (to.includes('https:')) {
      window.open(to, '_blank');
    } else {
      history.push(to);
    }
    setIsShowDrawer(false);
  };
  const getUrl = (imgId: number | undefined) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };
  const menu = (
    <div className={styles.profileDropDown}>
      <div key="accountSettings" onClick={handleAccountClick}>
        <FormattedMessage id="donorHeader.AccountSettings" />
      </div>
      {enableFeature.userPostHeader && (
        <div key="userPost" onClick={handleUserPostClick}>
          <FormattedMessage id="donorHeader.userPost" />
        </div>
      )}
      <div key="logout" onClick={logout} id="log-out">
        <FormattedMessage id="header.Logout" />
      </div>
    </div>
  );

  if (isDeviceMobile) {
    return (
      <Row align="middle" className={styles.mobileHeader}>
        <Col span={3} sm={5}>
          <div className={`${styles.nav}  drawer-content`} onClick={showDrawer}>
            <MobileMenu />
          </div>
        </Col>
        <Col span={15} sm={15}>
          <Link to={routes.HomeUrl}>
            <img
              className={styles.logo}
              src={astraLogoGreen}
              width="100px"
              alt="Astra"
            />
          </Link>
        </Col>
        <Drawer
          title={
            <Col
              xs={4}
              sm={2}
              md={2}
              lg={1}
              xl={1}
              className={styles.profileSection}
            >
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                arrow
                className="avatar-dropdown"
              >
                <Avatar
                  size={32}
                  src={
                    profileImage ? getUrl(profileImage?.id) : dummyUserProfile
                  }
                />
              </Dropdown>
            </Col>
          }
          placement="left"
          onClose={onClose}
          visible={isShowDrawer}
        >
          <Space className={styles.headerMenu} direction={'vertical'}>
            {links.map((link, i) => (
              <p
                key={i}
                onClick={() => handleMenuClick(link.to)}
                className={styles.headerText}
              >
                {link.text}
              </p>
            ))}
          </Space>
          <Link to="/payment" className={styles.energyChargeAmountMobile}>
            <div className={styles.points}>
              <div className={styles.icon}>
                <EnergySymbol />
              </div>
              <div className={styles.count}>{energy}</div>
            </div>
          </Link>
        </Drawer>

        <Col span={2} sm={2} className={styles.searchIcon}>
          {enableFeature.searchBarContent && <Search />}
        </Col>
        <Col span={4} sm={2}>
          <Link to={routes.InviteUserUrl} className={styles.notification}>
            <MailOutlined style={{ fontSize: '30px' }} />
          </Link>
        </Col>
      </Row>
    );
  }

  return (
    <Row align="middle" className={styles.donorHeader}>
      <Col xs={1} sm={4} md={4} lg={2} xl={2}>
        <Link to={routes.HomeUrl}>
          <img
            className={styles.logo}
            src={astraLogoGreen}
            width="100px"
            alt="Astra"
          />
        </Link>
      </Col>
      <Col xs={0} sm={0} md={10} lg={12} xl={14}>
        <Space size="large" className={styles.headerMenuDesktop}>
          <div />
          {/* Extra div added to have left margin with title */}
          {links.map((link, i) => (
            <div
              key={i}
              onClick={() => handleMenuClick(link.to)}
              className={styles.headerText}
            >
              {link.text}
            </div>
          ))}
        </Space>
      </Col>

      <Col xs={0} sm={12} md={7} lg={4} xl={3}>
        {enableFeature.searchBarContent && (
          <Form form={form} className={styles.search}>
            <Input
              name="search"
              type={'search'}
              placeholder={'検索キーワード'}
              onSearchEnter={handleSearch}
            />
          </Form>
        )}
      </Col>
      <Col xs={0} sm={4} md={4} lg={3} xl={3}>
        <Link to="/payment" className={styles.energyChargeAmount}>
          <div className={styles.points}>
            <div className={styles.icon}>
              <EnergySymbol />
            </div>
            <div className={styles.count}>{energy}</div>
          </div>
        </Link>
      </Col>
      <Col xs={4} sm={12} md={1} lg={2} xl={1}>
        <Link to={routes.InviteUserUrl} className={styles.notification}>
          <MailOutlined style={{ fontSize: '30px' }} />
        </Link>
      </Col>
      <Col
        xs={4}
        sm={2}
        md={2}
        lg={1}
        xl={1}
        className={styles.profileSection}
        id="avtarContent"
      >
        <Dropdown
          overlay={menu}
          placement="bottomRight"
          arrow
          trigger={['click']}
        >
          <Avatar
            size={32}
            src={profileImage ? getUrl(profileImage?.id) : dummyUserProfile}
          />
        </Dropdown>
      </Col>
    </Row>
  );
};
