/* eslint-disable @typescript-eslint/no-unused-vars  */
/* eslint-disable  react-hooks/exhaustive-deps */
import {
  Col,
  Row,
  Spin,
  Form,
  Card,
  Space,
  Button,
  Divider,
  Typography,
  notification,
} from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { HeartOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  CommentType,
  useGetPostByIdQuery,
  useAddCommentMutation,
  useUpdateCommentMutation,
  useDeleteCommentMutation,
} from '../../../../services/apiService';
import { RootState } from '../../../../store';
import { enableFeature } from '../../../../constant';
import { useFetchComments } from '../../../../utils';
import { Avatar } from '../../../../stories/Avatar/Avatar';
import { TagBox } from '../../../../stories/TagBox/TagBox';
import { getAccessToken } from '../../../../getAccessToken';
import { Square } from '../../../../stories/Icons/Square/Square';
import { DonorFooter } from '../../../../stories/DonorFooter/DonorFooter';
import { CommentForm } from '../../../../stories/CommentForm/CommentForm';
import CommentIcon from '../../../../stories/Icons/CommentIcon/CommentIcon';
import { CommentInputBox } from '../../../../stories/CommentInputBox/CommentInputBox';

import styles from './index.module.scss';

const { Title } = Typography;

const UserPostDetails = () => {
  const intl = useIntl();
  const location = useLocation();
  const commentRef = useRef<any>();
  const [form] = Form.useForm();
  const { id: postId } = useParams<{ id: string }>();
  const [counter, setCounter] = useState(0);

  const [token, setToken] = useState<string | undefined>('');
  const [comment, setComment] = useState<string | undefined>('');

  const user = useSelector((state: RootState) => state.me);

  const postCommentData = useFetchComments(Number(postId), 0);
  const [
    updateComment,
    { isError: isUpdateError, isLoading: isUpdateCommentLoading },
  ] = useUpdateCommentMutation();
  const [addComment, { isLoading: isPostCommentLoading, isSuccess, isError }] =
    useAddCommentMutation();

  const [deleteComment, { isError: isDeleteError }] =
    useDeleteCommentMutation();

  const { data: postData, isLoading: isPostDetail } =
    useGetPostByIdQuery(postId);

  useEffect(() => {
    setCounter(counter + 1);
  }, [postCommentData]);

  const onFinish = ({ comment }: { comment: string }) => {
    if (comment !== '') {
      addComment({ postId: Number(postId), commentId: 0, comment: comment });
      setComment('');
    }
  };

  const onReplyClick = (commentText: string, itemData: CommentType) => {
    addComment({
      postId: Number(postId),
      commentId: itemData.id,
      comment: commentText,
    });
  };

  const onEditReplyClick = (commentText: string, item: CommentType) => {
    updateComment({
      id: item.id,
      body: {
        postId: Number(postId),
        commentId: item.commentId,
        comment: commentText,
      },
    });
  };

  const onDeleteItem = (id: number) => {
    deleteComment(id);
  };

  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
    const state = location?.state as { focus: boolean };
    if (state?.focus) {
      commentRef?.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, []);

  useEffect(() => {
    if (isError || isUpdateError || isDeleteError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.FailedToUpdate' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isError, isUpdateError, isDeleteError]);

  if (isPostDetail) {
    <>
      <div className="space-align-container">
        <Spin size="large" />
      </div>
      <DonorFooter />
    </>;
  }
  return (
    <div>
      <Row justify="center">
        <Col sm={24} xs={24} lg={18} md={18} xl={18} xxl={13}>
          <div className={styles.image}>
            <img
              alt="city images"
              src={
                postData?.coverImage?.id ? getUrl(postData.coverImage.id) : ''
              }
            />
          </div>
          <Card className={styles.subContent}>
            <div className={styles.subMainContent}>
              <Title level={4}>{postData?.title}</Title>
              <div className={styles.tagLikeContent}>
                <div className={styles.tagMain}>
                  {postData?.tags?.map((i: any, index: number) => (
                    <TagBox name={i.name} key={index} />
                  ))}
                </div>
                {enableFeature.postLikeFeature && (
                  <div className={styles.likeContent}>
                    <HeartOutlined />
                    <p>10</p>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.userDetailsContent}>
              <div>
                <Avatar urlId={user.profileImage?.id} />
              </div>
              <div className={styles.titleContent}>
                <div>
                  <div>
                    <Title level={4}>{user?.fullName}</Title>
                    <Square />
                  </div>
                  {enableFeature.postTimeContent && <p>1 hour ago</p>}
                </div>
                {enableFeature.postLikeFeature && (
                  <div className={styles.titleSubLikeContent}>
                    <HeartOutlined />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.mobileUserContent}>
              <div className={styles.tagLikeContent}>
                <div className={styles.tagMain}>
                  <TagBox name="support" />
                  <TagBox name="Help people" />
                </div>
              </div>
              <Title level={4} className={styles.mobileTitle}>
                {postData?.title}
              </Title>
              <div className={styles.likeContent}>
                <HeartOutlined />

                <p>10</p>
              </div>
            </div>
            <div className={styles.descriptionContent}>
              <p>{postData?.description}</p>
            </div>
            {enableFeature.postOtherContent && (
              <>
                <Divider className={styles.dividerContent} />
                <div>
                  <div className={styles.userDetailsContent}>
                    <div>
                      <Avatar urlId={user.profileImage?.id} size={56} />
                    </div>

                    <div className={styles.midSubtitleContent}>
                      <div>
                        <div>
                          <div>
                            <Title level={4}>Astra Team</Title>
                            <Square />
                          </div>
                          <p>1 hour ago</p>
                        </div>
                        <div className={styles.followBtn}>
                          <Button type="default" shape="round" size="large">
                            <Space size="large">
                              <FormattedMessage id="search.follow" />
                            </Space>
                          </Button>
                        </div>
                      </div>

                      <div className={styles.midSubDescriptionContent}>
                        <p>
                          Maximum 150 characters Contains self-introduction text
                          Contains self-introduction text Contains
                          self-introduction text Contains self-introduction text
                          Contains self-introduction text Contains
                          self-introduction text Up to.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            <>
              <Divider className={styles.dividerContent} />
              <CommentInputBox
                onFinish={onFinish}
                initialValue={{ comment: comment }}
                onChange={(e) => setComment(e.target.value)}
                disable={comment?.length ? false : true}
                user={{
                  id: Number(user.id),
                  fullName: user.fullName,
                  profileImage: { id: Number(user.profileImage?.id) },
                }}
                isLoading={isPostCommentLoading || isUpdateCommentLoading}
              />
              <Divider className={styles.dividerContent} />

              <div ref={commentRef}>
                <div className={styles.commentContent}>
                  <CommentIcon />
                  <p>{postCommentData?.data?.length} COMMENT</p>
                </div>
                <div key={counter}>
                  {postCommentData?.data?.map((item: any, index: number) => (
                    <CommentForm
                      postId={Number(postId)}
                      user={user}
                      key={index}
                      index={index}
                      comment={item}
                      handleReply={onReplyClick}
                      handleEdit={onEditReplyClick}
                      handleDelete={onDeleteItem}
                      isLoading={isPostCommentLoading || isUpdateCommentLoading}
                      onLikeComment={() => {}}
                    />
                  ))}
                </div>
              </div>
            </>
          </Card>
        </Col>
      </Row>
      <DonorFooter />
    </div>
  );
};

export default UserPostDetails;
