import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import { useGetSupportHistoryQuery } from '../../services/apiService';
import { SupportHistoryList } from '../../stories/SupportHistoryList/SupportHistoryList';

const SupportHistory = () => {
  const { energy } = useSelector((state: RootState) => state.me);

  const { data: supportHistoryData } = useGetSupportHistoryQuery({
    page: 1,
    limit: 10,
    orderDirection: 'ASC',
    orderField: 'createdAt',
  });
  return <SupportHistoryList data={supportHistoryData?.data} energy={energy} />;
};
export default SupportHistory;
