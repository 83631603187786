/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, notification, Popover, Row, Table } from 'antd';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { EllipsisOutlined } from '@ant-design/icons';

import { withTabs } from '../withTabs';
import {
  MonthlyTargetEnergyType,
  useGetMonthlyTargetEnergyQuery,
  useCreateMonthlyTargetEnergyMutation,
  useUpdateMonthlyTargetEnergyMutation,
} from '../../../../services/apiService';
import { Loader } from '../../../../stories/Loader/Loader';
import { MonthlyTargetFormModal } from './MonthlyTargetFormModal';

import styles from './index.module.scss';

const MonthlyTargetEnergy = () => {
  const intl = useIntl();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editData, setEditData] =
    useState<MonthlyTargetEnergyType | null>(null);
  const { id: facilityId } = useParams<{ id: string }>();

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    facilityId: Number(facilityId),
  });
  const { data, isLoading } = useGetMonthlyTargetEnergyQuery(pagination);

  const [
    createMonthlyTargetEnergy,
    { isSuccess, isLoading: isPostLoading, isError, error },
  ] = useCreateMonthlyTargetEnergyMutation();

  const [
    updateMonthlyTargetEnergy,
    {
      isSuccess: isUpdateSuccess,
      isLoading: isUpdateLoading,
      isError: isUpdateError,
    },
  ] = useUpdateMonthlyTargetEnergyMutation();

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'inquiry.successMessage' }),
        placement: 'topRight',
        duration: 5,
      });
      setEditData(null);
      handleModal();
    }

    if (isError) {
      notification.error({
        message:
          //@ts-ignore
          error?.data?.message?.yearMonth[0] ||
          intl.formatMessage({ id: 'notification.requestFailed' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isSuccess, isError]);
  useEffect(() => {
    if (isUpdateSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'inquiry.successMessage' }),
        placement: 'topRight',
        duration: 5,
      });
      setEditData(null);
      handleModal();
    }

    if (isUpdateError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.requestFailed' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isUpdateError, isUpdateSuccess]);

  const handleEdit = (record: MonthlyTargetEnergyType) => {
    setEditData(record);
    setIsModalVisible(true);
  };

  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
    setEditData(null);
  };
  const onFinish = async (values: any) => {
    if (editData) {
      updateMonthlyTargetEnergy({
        id: editData.id,
        data: {
          targetEnergy: values.targetEnergy,
        },
      });
    } else {
      const yearMonth = moment(values.month).format('YYYY-MM');
      createMonthlyTargetEnergy({
        id: Number(facilityId),
        data: {
          yearMonth: yearMonth,
          targetEnergy: values.targetEnergy,
        },
      });
    }
  };

  const columns = [
    {
      title: 'Year Month',
      dataIndex: 'yearMonth',
    },
    {
      title: 'Target Energy',
      dataIndex: 'targetEnergy',
    },
    {
      title: 'Total Supporters Count',
      dataIndex: 'totalSupportersCount',
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => (
        <Popover
          content={
            <div
              className={styles.popoverButton}
              onClick={() => handleEdit(record)}
            >
              Edit
            </div>
          }
          trigger="click"
        >
          <EllipsisOutlined />
        </Popover>
      ),
    },
  ];
  if (isLoading) return <Loader />;
  return (
    <Row>
      <Col className={styles.message}>
        <div className={styles.btnContent}>
          <Button
            type="primary"
            shape="round"
            className={styles.addPostBtn}
            onClick={handleModal}
          >
            Add
          </Button>
        </div>
        <div className={styles.table}>
          <Table
            columns={columns}
            rowKey={(record: any) => record.id}
            dataSource={data?.data}
            pagination={{
              total: 0,
            }}
          />
        </div>
      </Col>

      <MonthlyTargetFormModal
        isVisible={isModalVisible}
        onFinish={onFinish}
        isLoading={isPostLoading || isUpdateLoading}
        isUpdateSuccess={isUpdateSuccess}
        isSuccess={isSuccess}
        handleCancel={handleModal}
        editedData={editData}
      />
    </Row>
  );
};

export default withTabs(MonthlyTargetEnergy);
