/* eslint-disable react-hooks/exhaustive-deps */

import { Row, Col, Form, Modal, Button, Typography, DatePicker } from 'antd';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { Input } from '../../../../stories/Input/Input';

import styles from './index.module.scss';
import { MonthlyTargetEnergyType } from '../../../../services/apiService';
import moment from 'moment';

const { Title } = Typography;

export interface MonthlyTargetFormProps {
  onFinish: any;
  isLoading: boolean;
  isSuccess: boolean;
  isUpdateSuccess: boolean;
  isVisible: boolean;
  handleCancel: () => void;
  editedData?: MonthlyTargetEnergyType | null;
}

export const MonthlyTargetFormModal = ({
  onFinish,
  isLoading,
  isSuccess,
  isVisible,
  isUpdateSuccess,
  handleCancel,
  editedData,
}: MonthlyTargetFormProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      form.resetFields();
    }
  }, [isSuccess || isUpdateSuccess]);

  useEffect(() => {
    if (editedData) {
      form.setFieldsValue({
        month: moment(editedData?.yearMonth),
        targetEnergy: editedData?.targetEnergy,
      });
    }
  }, [editedData]);

  const handleCancelClick = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <Modal visible={isVisible} footer={null} closable={false}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        initialValues={{}}
        wrapperCol={{ span: 24 }}
      >
        <Row>
          <Col span={24}>
            <Title level={4}>
              <FormattedMessage id="monthlyTargetEnergy.title" />
            </Title>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name={'month'}
                  label={<FormattedMessage id="monthlyTargetEnergy.month" />}
                  className={styles.dateContent}
                  rules={[{ required: true }]}
                >
                  <DatePicker picker="month" placeholder="Select year month" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Input
                  name="targetEnergy"
                  type="number"
                  min={0}
                  label={
                    <FormattedMessage id="monthlyTargetEnergy.targetEnergy" />
                  }
                  placeholder=""
                  rules={[{ required: true }]}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row align="middle" justify="end">
          <Col span="5">
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              <FormattedMessage id="creditCardInfo.submit" />
            </Button>
          </Col>
          <Col span="1"></Col>
          <Col span="5">
            <Button
              type="default"
              size="large"
              block
              onClick={handleCancelClick}
            >
              <FormattedMessage id="facilityForm.Cancel" />
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
