/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Row,
  Col,
  Form,
  Modal,
  Button,
  Progress,
  Divider,
  notification,
} from 'antd';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  SupportProductType,
  useAddSupportProductMutation,
  useCreateImageMutation,
  useUpdateImageMutation,
  useUpdateSupportProductMutation,
} from '../../services/apiService';
import { getUrl } from '../../utils';
import { Input } from '../Input/Input';
import { getAccessToken } from '../../getAccessToken';
import { ImageUploader } from '../ImageUploader/ImageUploader';

import styles from './index.module.scss';

export interface ProfileSettingProps {
  open: boolean;
  handleCancel: () => void;
  isEdit: boolean;
  editData: SupportProductType;
}

export function AddSupportProductModal({
  open,
  handleCancel,
  isEdit,
  editData,
}: ProfileSettingProps) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { id: facilityId } = useParams<{ id: string }>();

  const [productImageId, setProductImageId] = useState<number>(0);
  const [token, setToken] = useState<string | undefined>('');

  const [
    addSupportProduct,
    {
      isLoading: isSupportLoading,
      isSuccess: isSupportSuccess,
      error: isSupportError,
    },
  ] = useAddSupportProductMutation();
  const [
    updateSupportProduct,
    {
      isLoading: isSupportUpdateLoading,
      isSuccess: isSupportUpdateSuccess,
      error: isSupportUpdateError,
    },
  ] = useUpdateSupportProductMutation();
  const [postImage, { isSuccess: imagePostSuccess, error: imagePostError }] =
    useCreateImageMutation();
  const [patchImage, { isSuccess: imagePatchSuccess, error: imagePatchError }] =
    useUpdateImageMutation();
  const onClose = () => {
    form.resetFields();
    handleCancel();
    setProductImageId(0);
  };

  useEffect(() => {
    if (editData && editData?.supportproductImage) {
      form.setFieldsValue({
        title: editData.title,
        targetAmount: editData.targetAmount,
      });
      setProductImageId(editData.supportproductImage.id);
    }
  }, [editData]);

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  useEffect(() => {
    if (imagePostSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.photoUploadSuccess' }),
        placement: 'topRight',
        duration: 5,
      });
    }
    if (imagePostError) {
      notification.error({
        message: imagePatchError || imagePostError,
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [imagePostError, imagePostSuccess]);

  useEffect(() => {
    if (imagePatchSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.photoUploadSuccess' }),
        placement: 'topRight',
        duration: 5,
      });
    }
    if (imagePatchError) {
      notification.error({
        message: imagePatchError || imagePostError,
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [imagePatchError, imagePatchSuccess]);

  useEffect(() => {
    if (isSupportSuccess) {
      onClose();
      notification.success({
        message: intl.formatMessage({
          id: 'supportProduct.productAddedSuccess',
        }),
        placement: 'topRight',
        duration: 5,
      });
    }
    if (isSupportError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.FailedToUpdate' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isSupportError, isSupportSuccess]);

  useEffect(() => {
    if (isSupportUpdateSuccess) {
      onClose();
      notification.success({
        message: intl.formatMessage({
          id: 'supportProduct.productUpdateSuccess',
        }),
        placement: 'topRight',
        duration: 5,
      });
    }
    if (isSupportUpdateError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.FailedToUpdate' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isSupportUpdateSuccess, isSupportUpdateError]);

  const uploadImage = async (
    imageUri: string,
    subType: string,
    imageId: number | undefined
  ) => {
    const data = new FormData();
    data.append('file', imageUri);
    data.append('subType', subType);
    data.append('imageableType', 'SupportProduct');

    if (imageId) await patchImage({ id: imageId!, imageUri: data });
    else {
      postImage(data).then((d: any) => {
        const responseImageId = d.data;
        setProductImageId(responseImageId);
      });
    }
  };

  const onFormFinish = (values: {
    title: string;
    targetAmount: string;
    supportproductImageId: number;
  }) => {
    if (editData.id) {
      updateSupportProduct({
        id: editData.id,
        data: { title: values.title, targetAmount: values.targetAmount },
      });
    } else {
      addSupportProduct({
        id: parseInt(facilityId),
        data: {
          title: values.title,
          targetAmount: values.targetAmount,
          supportproductImageId: productImageId,
        },
      });
    }
  };

  return (
    <Modal
      centered
      footer={null}
      visible={open}
      closable={false}
      className={styles.modalContent}
    >
      <Title level={3} className={styles.titleContent}>
        {isEdit ? (
          <FormattedMessage id="supportProductModal.editingOfSupport" />
        ) : (
          <FormattedMessage id="supportProductModal.newSupport" />
        )}
      </Title>
      <Row className={styles.imageUploadContent}>
        <ImageUploader
          boxShape="rect"
          name="supportproductImageId"
          label={<FormattedMessage id="supportProduct.uploadPhoto" />}
          imageUrl={productImageId ? getUrl(productImageId, token) : ''}
          upload={(imgUri: string) =>
            uploadImage(imgUri, 'supportproductImage', productImageId)
          }
          required
        />
      </Row>
      <Form
        form={form}
        onFinish={onFormFinish}
        className={styles.form}
        wrapperCol={{ span: 24 }}
      >
        <Row>
          <Col span={24}>
            <Input
              type="text"
              name="title"
              placeholder={intl.formatMessage({
                id: 'supportProduct.placeHolderTitle',
              })}
              rules={[{ required: true }]}
            />
            <Input
              type="number"
              name="targetAmount"
              placeholder={intl.formatMessage({
                id: 'supportProduct.placeHolderTargetAmount',
              })}
              rules={[{ required: true }]}
            />
          </Col>
        </Row>
        {isEdit && (
          <div className={styles.progressContent}>
            <div>
              <Progress
                percent={60}
                className={styles.progressBar}
                showInfo={false}
              />
              <span className={styles.progressPer}>{60} %</span>
            </div>
            <p>
              <b>11,230/20,000</b> circle
            </p>
          </div>
        )}
        <Row justify="end" gutter={16}>
          <Divider />
          <Col>
            <Button type="ghost" onClick={onClose}>
              <FormattedMessage id="supportCommentModel.cancel" />
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              loading={isSupportLoading || isSupportUpdateLoading}
            >
              {isEdit ? (
                <FormattedMessage id="personalInformation.keep" />
              ) : (
                <FormattedMessage id="supportProduct.addition" />
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
