import { Col, Row } from 'antd';
import { ComponentType } from 'react';
import Title from 'antd/lib/typography/Title';
import { FormattedMessage } from 'react-intl';

import { routes } from '../../../constant';
import { useHistory, useLocation } from 'react-router-dom';

import styles from './index.module.scss';

export function withSettingLayout<T>(Component: ComponentType<T>) {
  return (hocProps: T) => {
    const history = useHistory();
    const location = useLocation();

    const tabDetails: { [key: string]: string } = {
      'donorAccountSetting.personalInformation': routes.PersonalInformationUrl,
      'donorAccountSetting.telephoneNumber': routes.PhoneNumberUrl,
      'donorAccountSetting.emailAddress': routes.UpdateEmailAddressUrl,
      'donorAccountSetting.creditCardInformation': routes.CreditCardInfoUrl,
      'donorAccountSetting.language': routes.SetLanguageUrl,
      'donorAccountSetting.passwordSetting': routes.PasswordSettingsUrl,
      // 'donorAccountSetting.unsubscribed': routes.UnsubscribeUrl,
      // 'donorAccountSetting.plan': routes.PlanUrl,
    };

    const handleClick = (url: string) => {
      history.push(url);
    };
    return (
      <div className={styles.accountSetting}>
        <Row justify="center">
          <Col lg={22} xxl={16}>
            <div className={styles.Accountheading}>
              <Title level={2}>
                <FormattedMessage id="donorAccountSetting.AccountSettings" />
              </Title>
            </div>
          </Col>
        </Row>
        <Row justify="center">
          <Col lg={5} xl={5} xxl={3} className={styles.accountTabMain}>
            {Object.keys(tabDetails).map((name, i) => {
              const url = tabDetails[name];
              return (
                <p
                  key={i}
                  onClick={() => handleClick(url)}
                  className={`${styles.accountSettingTabs} ${
                    url === location.pathname && styles.active
                  }`}
                >
                  <FormattedMessage id={name} />
                </p>
              );
            })}
          </Col>
          <Col xs={24} sm={24} md={17} lg={17} xxl={13}>
            <Component {...(hocProps as T)} />
          </Col>
        </Row>
      </div>
    );
  };
}
