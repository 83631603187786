/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Empty, notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  CreditCardType,
  useGetCardQuery,
  useDeleteCardMutation,
} from '../../../../services/apiService';
import { withSettingLayout } from '../withSettingLayout';
import { Loader } from '../../../../stories/Loader/Loader';
import { CreditCard } from '../../../../stories/CreditCard/CreditCard';
import { AccountSettingTab } from '../../../../stories/AccountSettingTab/AccountSettingTab';

const CreditCardInfo = () => {
  const intl = useIntl();
  const { data: creditCardList, isLoading } = useGetCardQuery({});
  const [
    deleteCard,
    {
      data: deleteData,
      isError: isDeleteError,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteCardMutation();

  const handleDelete = (paymentMethodId: string) => {
    deleteCard(paymentMethodId);
  };

  useEffect(() => {
    if (isDeleteError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.requestFailed' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isDeleteError]);

  useEffect(() => {
    if (isDeleteSuccess) {
      notification.success({
        message: intl.formatMessage({
          id: 'notification.cardDeleteSuccessfully',
        }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isDeleteSuccess]);

  if (isDeleteLoading || isLoading)
    return (
      <AccountSettingTab
        title={
          <FormattedMessage id="donorAccountSetting.creditCardInformation" />
        }
      >
        <Loader />
      </AccountSettingTab>
    );

  if (creditCardList?.data.length)
    return (
      <AccountSettingTab
        title={
          <FormattedMessage id="donorAccountSetting.creditCardInformation" />
        }
      >
        {creditCardList.data.map((data: CreditCardType) => (
          <div>
            {deleteData?.id !== data.id ? (
              <CreditCard cardDetail={data} handleDelete={handleDelete} />
            ) : null}
          </div>
        ))}
      </AccountSettingTab>
    );

  return (
    <AccountSettingTab
      title={
        <FormattedMessage id="donorAccountSetting.creditCardInformation" />
      }
    >
      <Empty />
    </AccountSettingTab>
  );
};

export default withSettingLayout(CreditCardInfo);
