import { Tree0 } from '../Icons/Tree0/Tree0';
import { Tree20 } from '../Icons/Tree20/Tree20';
import { Tree40 } from '../Icons/Tree40/Tree40';
import { Tree60 } from '../Icons/Tree60/Tree60';
import { Tree80 } from '../Icons/Tree80/Tree80';
import { Tree100 } from '../Icons/Tree100/Tree100';
import { ProgressBar } from '../ProgressBar/ProgressBar';

import styles from './index.module.scss';

export interface ProgressTreeProps {
  progress: number;
}

export const ProgressTree: React.FC<ProgressTreeProps> = ({ progress }) => {
  const Tree = () => {
    if (progress >= 1 && progress <= 20) return <Tree20 />;
    if (progress >= 20 && progress <= 40) return <Tree40 />;
    if (progress >= 40 && progress <= 60) return <Tree60 />;
    if (progress >= 60 && progress <= 80) return <Tree80 />;
    if (progress >= 80 && progress <= 100) return <Tree100 />;
    return <Tree0 />;
  };

  return (
    <div className={styles.mainContent}>
      <div>
        <Tree />
      </div>
      <ProgressBar percent={progress} />
    </div>
  );
};
