import { useState } from 'react';
import { Row, Col, List, Pagination } from 'antd';

import { FacilityBox } from '../FacilityBox/FacilityBox';

import styles from './index.module.scss';

export interface FacilityListBoxProps {
  facilities: any[];
}

export const FacilityListBox = (facilities: any) => {
  facilities = facilities.facilities;

  const numEachPage = 9;
  const [offset, setOffset] = useState(0);

  const handlePagination = (value: number) => {
    setOffset((value - 1) * numEachPage);
  };

  return (
    <div className={styles.serviceContainer}>
      <div className={styles.justify}>
        {facilities?.data !== undefined ? (
          <Row>
            <List
              className={styles.list}
              size="large"
              itemLayout="horizontal"
              dataSource={facilities?.data.slice(offset, offset + numEachPage)}
              renderItem={(d: any, i) => {
                return (
                  <Col
                    xs={24}
                    sm={24}
                    lg={11}
                    md={18}
                    xl={8}
                    xxl={8}
                    className={styles.facilitiesList}
                    id={`facilityBox${i}`}
                  >
                    <FacilityBox data={d} index={i} />
                  </Col>
                );
              }}
            />
          </Row>
        ) : null}
      </div>

      {facilities?.data?.length > 9 ? (
        <Col span={24} className={styles.facilityPagination}>
          <div className={styles.blogPagination}>
            <Pagination
              defaultCurrent={1}
              defaultPageSize={numEachPage}
              onChange={handlePagination}
              total={facilities?.data.length}
            />
          </div>
        </Col>
      ) : null}
    </div>
  );
};
