import { Menu as AntdMenu } from 'antd';
import { useHistory } from 'react-router-dom';

import { Mail } from '../Icons/Mail/Mail';
import { Person } from '../Icons/Person/Person';
import { MenuBook } from '../Icons/MenuBook/MenuBook';
import { Equalizer } from '../Icons/Equalizer/Equalizer';
import { SupportProduct } from '../Icons/SupportProduct/SupportProduct';

import styles from './index.module.scss';

export const FacilityAdminHeader = ({ facilityId }: { facilityId: string }) => {
  const history = useHistory();
  const url = window.location.href;

  const handleClick = (key: string) => {
    history.push(`/facility/${facilityId}/${key}`);
  };

  const facilityDetailMenu = [
    { id: 1, image: Equalizer, title: 'Support status', key: 'support-status' },
    { id: 2, image: Person, title: 'Profile', key: 'basic-information' },
    {
      id: 3,
      image: MenuBook,
      key: 'activity-report',
      title: 'Activity report',
    },
    {
      id: 4,
      image: SupportProduct,
      key: 'support-product',
      title: 'Support Product',
    },
    {
      id: 5,
      image: Mail,
      key: 'thanks-message',
      title: 'Thanks message',
    },
    {
      id: 5,
      image: Mail,
      key: 'monthly-target-energy',
      title: 'Monthly Target Energy',
    },
  ];

  const activeTab = url.substring(url.lastIndexOf('/') + 1);

  return (
    <AntdMenu
      multiple={true}
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={['1']}
    >
      {facilityDetailMenu.map((d, i) => {
        const IconsSvg = d.image;
        const active = activeTab === d.key ? styles.activeTab : '';
        return (
          <AntdMenu.Item key={d.id}>
            <div
              className={styles.menuItem}
              onClick={() => {
                handleClick(d.key);
              }}
            >
              <span className={active}></span>
              <IconsSvg />
              <span className={styles.menuTitle}>{d.title}</span>
            </div>
          </AntdMenu.Item>
        );
      })}
    </AntdMenu>
  );
};
